import {Injectable} from '@angular/core';
import {LocationApiService} from './api/location.api.service';
import {LocationGroupsActionsCreator} from '../redux/actions/locations/location-groups.action';
import {LocationsActionsCreator} from '../redux/actions/locations/locations.action';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../redux/store';
import * as _ from 'lodash';
import {IApiSetLocationGroup, LocationGroupId} from '../../../../common/interfaces/locationGroup';
import {AbstractCrudApiService} from './api/abstract-crud.api.service';
import {HttpClient} from '@angular/common/http';
import {AuthApiService} from './api/auth.api.service';
import {ApiService} from './api/api.service';
import {full} from '../../../../common/routes';
import {LocationsController} from '../redux/actions/locations/locations.controller';

@Injectable()
export class LocationGroupsService extends AbstractCrudApiService {
  protected apiUrl: string = full.location;
  private newLocationGroupName: string;
  private locationGroupNameForBookings: string;

  constructor(
    http: HttpClient,
    authApi: AuthApiService,
    api: ApiService,
    private locationsActionFactory: LocationsActionsCreator,
    private locationGroupsActionFactory: LocationGroupsActionsCreator,
    private locationApiService: LocationApiService,
    private redux: NgRedux<IAppState>,
    private locationsController: LocationsController,
  ) {
    super(http, authApi, api);
  }

  public setLocationGroupForBookings(value) {
    this.locationGroupNameForBookings = value;
  }

  public getLocationGroupForBookings() {
    return this.locationGroupNameForBookings;
  }

  public setNewLocationGroup(value) {
    this.newLocationGroupName = value;
  }

  public getNewLocationGroup() {
    return this.newLocationGroupName;
  }

  public getLocationGroups(): Promise<any[]> {
    return this.api.get(full.locationGroupAll, {responseType: 'json'});
  }

  public deleteLocationGroupWithId(locationGroupId: string): Promise<any[]> {
    return this.api.delete(
      this.insertId(full.locationGroupWithId, locationGroupId),
    );
  }

  public addLocationGroup(newLocationGroup: object): Promise<any[]> {
    return this.api.post(full.locationGroup, newLocationGroup);
  }

  public updateLocationGroupWithId(
    locationGroupId: string,
    objectToUpdate: object,
  ): Promise<any[]> {
    return this.api.put(
      this.insertId(full.locationGroupWithId, locationGroupId),
      objectToUpdate,
    );
  }

  async initLocationGroupList() {
    const locationGroupList = await this.getLocationGroups();
    this.redux.dispatch(this.locationGroupsActionFactory.resetChecked());
    this.redux.dispatch(
      this.locationGroupsActionFactory.set(locationGroupList),
    );
  }

  async setTheCheckedStatusOFTheGroup(
    groupId: LocationGroupId,
    groupLocations: number[],
  ) {
    await this.redux.dispatch(
      this.locationGroupsActionFactory.toggleChecked(groupId),
    );
    _.each(groupLocations, async currentId =>
      await this.redux.dispatch(
        this.locationsActionFactory.setChecked(currentId, true),
      ),
    );
  }

  async setLocationsOfTheGroup(locations: any) {
    _.each(locations, async (currentLocation) => {
      await this.redux.dispatch(this.locationsActionFactory.setChecked(currentLocation.id, true));
    });
  }

  async deleteLocationGroup(locationGroupId: LocationGroupId) {
    await this.deleteLocationGroupWithId(locationGroupId.toString());
    this.redux.dispatch(
      this.locationGroupsActionFactory.deleteData([locationGroupId], true),
    );
  }

  async updateLocationGroup(
    locationGroupId: LocationGroupId,
    updatedGroup: IApiSetLocationGroup,
  ) {
    await this.updateLocationGroupWithId(
      locationGroupId.toString(),
      updatedGroup,
    );
    this.redux.dispatch(this.locationGroupsActionFactory.update(updatedGroup));
  }

  removeSelectedWithReset(locationGroupId: LocationGroupId, groupLocations: number[]) {
    this.redux.dispatch(
      this.locationGroupsActionFactory.setChecked(locationGroupId, false),
    );
    _.each(groupLocations, currentId =>
      this.redux.dispatch(
        this.locationsActionFactory.setChecked(currentId, false),
      ),
    );
  }

  removeSelectedWithoutReset(locationGroupId: LocationGroupId, groupLocations: number[]) {
    this.redux.dispatch(
      this.locationGroupsActionFactory.setChecked(locationGroupId, false),
    );
  }

  async updateCurrentLocations() {
    await this.redux.dispatch(this.locationsController.updateActive());
  }

  removeSelectedLocationsOfTheGroup(groupLocations: number[]) {
    _.each(groupLocations, currentId =>
      this.redux.dispatch(
        this.locationsActionFactory.setChecked(currentId, false),
      ),
    );
  }
}
