import * as _ from 'lodash';
import {AbstractReducer, IMetaState, IStateDataType} from './abstract.reducer';
import {IName} from '../../../../../common/interfaces/util';
import {IRolesAction, RolesActionsCreator} from '../actions/roles/roles.action';
import {IRole} from '../../../../../common/interfaces/role';
import {ROLES} from '../../../../../common/constants';

export type IRolesState = IMetaState<IRole>;

export interface IStateRole extends IRole, IStateDataType {
}

export interface IRolesFilter extends IName {
  description: string;
}

export class RolesReducer extends AbstractReducer<IStateRole, IRolesAction> {
  private constructor() {
    super(ROLES, RolesActionsCreator.getInstance());
  }

  private static instance: RolesReducer;

  public DEFAULT_STATE: IRolesState = _.assign({}, AbstractReducer.DEFAULT_STATE, {
    filters: {name: '', description: ''} as IRolesFilter,
    sortings: {name: 'ASC'},
  });

  public static getInstance() {
    if (!RolesReducer.instance) {
      RolesReducer.instance = new RolesReducer();
    }

    return RolesReducer.instance;
  }
}

export const RolesSelector = RolesReducer.getInstance().getSelectors();
