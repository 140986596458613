import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { IApiGetMedias } from '../../../../../../common/interfaces/medias';
import { MediasController } from '../../../redux/actions/medias/medias.controller';
import { full } from '../../../../../../common/routes';
import { AuthApiService } from 'src/app/services/api/auth.api.service';

interface IModel {
  order: string;
  type: {
    image: boolean;
    video: boolean;
    pdf: boolean;
  };
  orientation: {
    landscape: boolean;
    portrait: boolean;
    square: boolean;
  };
}

interface IFiltersEmitter {
  model: IModel;
  changedKey: string;
  changedValue: string | [];
}

interface IOrderEmitter {
  order: string;
}

@Component({
  selector: 'ba-medias-sidebar',
  templateUrl: './medias-sidebar.component.html',
  styleUrls: ['./medias-sidebar.component.scss'],
})
export class MediasSidebarComponent implements OnInit, OnDestroy {

  @Input() model: IModel;
  @Input() orderItems: [];
  @Input() mediasList$: Observable<IApiGetMedias>;
  @Input() crudController: MediasController;
  @Output() orderChange = new EventEmitter<IOrderEmitter>();
  @Output() filtersChange = new EventEmitter<IFiltersEmitter>();
  @Output() displayMediasLibrary = new EventEmitter<boolean>();
  @Output() mediaPick = new EventEmitter<any>();
  @Output() sidebarInit = new EventEmitter<any>();

  public fileApiRoute = full.files;
  public listLayout = 'thumbs';

  constructor(
    public authApiService: AuthApiService,
  ) {}

  ngOnInit() {
    this.sidebarInit.emit(true);
  }

  ngOnDestroy() {
    this.sidebarInit.emit(false);
  }

  public hideMedias() {
    this.displayMediasLibrary.emit(false);
  }

  public onOrderChange(orderValue: string) {
    this.orderChange.emit({ order: orderValue });
  }

  public toggleFilter(type) {
    const [filterObject, filterItem] = type.split('.');
    let model = { ...this.model };

    if (filterItem) {
      model = {
        ...this.model,
        [filterObject]: {
          ...this.model[filterObject],
          [filterItem]: !this.model[filterObject][filterItem],
        },
      };
    } else {
      model = {
        ...this.model,
        [filterObject]: !this.model[filterObject],
      };
    }

    let filtersValue = model[filterObject];
    if (typeof filtersValue === 'object') {
      filtersValue = Object.entries(filtersValue)
        .map(([key, value]) => {
          if (value) {
            return key;
          }

          return null;
        })
        .filter(i => i);
    }

    this.filtersChange.emit({
      model,
      changedKey: filterObject,
      changedValue: filtersValue,
    });
  }

  onMediaPick(mediaFile) {
    this.mediaPick.emit(mediaFile);
  }

  changeLayout(layout) {
    this.listLayout = layout;
  }

  public trackByFn(index, data) {
    if (!data) {
      return null;
    }

    return data.id;
  }
}
