import {ICode, IName} from './interfaces/util';


export interface ITimezone extends ICode, IName {
  description: string;
}

const timezones: ITimezone[] = [
  // {code: 'GMT', description: 'Greenwich Mean Time', name: 'GMT'},
  {code: 'UTC', description: 'Universal Coordinated Time', name: 'GMT'},
  {code: 'ECT', description: 'European Central Time', name: 'GMT+1:00'},
  {code: 'EET', description: 'Eastern European Time', name: 'GMT+2:00'},
  {code: 'EAT', description: 'Eastern African Time', name: 'GMT+3:00'},
  {code: 'MET', description: 'Middle East Time', name: 'GMT+3:30'},
  {code: 'NET', description: 'Near East Time', name: 'GMT+4:00'},
  {code: 'PLT', description: 'Pakistan Lahore Time', name: 'GMT+5:00'},
  {code: 'IST', description: 'India Standard Time', name: 'GMT+5:30'},
  {code: 'BST', description: 'Bangladesh Standard Time', name: 'GMT+6:00'},
  {code: 'VST', description: 'Vietnam Standard Time', name: 'GMT+7:00'},
  {code: 'CTT', description: 'China Taiwan Time', name: 'GMT+8:00'},
  {code: 'JST', description: 'Japan Standard Time', name: 'GMT+9:00'},
  {code: 'ACT', description: 'Australia Central Time', name: 'GMT+9:30'},
  {code: 'AET', description: 'Australia Eastern Time', name: 'GMT+10:00'},
  {code: 'SST', description: 'Solomon Standard Time', name: 'GMT+11:00'},
  {code: 'NST', description: 'New Zealand Standard Time', name: 'GMT+12:00'},
  {code: 'MIT', description: 'Midway Islands Time', name: 'GMT-11:00'},
  {code: 'HST', description: 'Hawaii Standard Time', name: 'GMT-10:00'},
  {code: 'AST', description: 'Alaska Standard Time', name: 'GMT-9:00'},
  {code: 'PST', description: 'Pacific Standard Time', name: 'GMT-8:00'},
  {code: 'PNT', description: 'Phoenix Standard Time', name: 'GMT-7:00'},
  {code: 'CST', description: 'Central Standard Time', name: 'GMT-6:00'},
  {code: 'EST', description: 'Eastern Standard Time', name: 'GMT-5:00'},
  {code: 'PRT', description: 'Puerto Rico and US Virgin Islands Time', name: 'GMT-4:00'},
  {code: 'CNT', description: 'Canada Newfoundland Time', name: 'GMT-3:30'},
  {code: 'AGT', description: 'Argentina Standard Time', name: 'GMT-3:00'},
  {code: 'CAT', description: 'Central African Time', name: 'GMT-1:00'},
];

export default timezones;
