import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import {
  NgRedux,
  select,
} from '@angular-redux/store';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import {
  filter,
  flatMap,
  scan,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import { IAppState } from '../../../../redux/store';
import {
  IStateExternalResource,
  ExternalResourcesSelector,
} from '../../../../redux/reducers/external-resources.reducer';
import { LocationsSelector } from '../../../../redux/reducers/locations.reducer';
import { LocationGroupsSelector } from '../../../../redux/reducers/location-groups.reducer';

import { ExternalResourcesController } from '../../../../redux/actions/external-resources/external-resources.controller';
import { LocationsActionsCreator } from 'src/app/redux/actions/locations/locations.action';

import { ExternalResourceApiService } from '../../../../services/api/external-resource.api.service';
import { ModalService } from '../../../../services/modal.service';

import { AdministrationPageComponent } from '../../administration-page/administration-page.component';
import { ExternalResourcesComponent } from '../external-resources.component';
import { IExternalResourcePayload } from '../../../../../../../common/interfaces/external-resource';
import { Omit } from '../../../../../../../common/interfaces/util';
import { DuplicateTrackingService } from '../../../../services/duplicate-tracking-service';
import { LocationId } from '../../../../../../../common/interfaces/location';
import { IApiGetLocationGroup } from '../../../../../../../common/interfaces/locationGroup';
import {
  EXTERNAL_RESOURCES_API_KEY_ADD_TO,
  EXTERNAL_RESOURCES_AUTH_METHODS,
  EXTERNAL_RESOURCES_PROTOCOLS,
} from '../../../../../../../common/constants';

type Model = Omit<IExternalResourcePayload, 'id'>;
type ExternalResourceForm = Record<keyof Model, any>;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-external-resources-administration',
  templateUrl: './external-resources-administration.component.html',
  styleUrls: ['./external-resources-administration.component.scss'],
})
export class ExternalResourcesAdministrationComponent extends AdministrationPageComponent implements OnInit, OnDestroy, AfterViewChecked {
  public static ROUTE = 'external-resource-administration';
  private ngDestroy$ = new Subject<void>();
  private viewChecked$ = new Subject<void>();
  private isFormProcessing = false;

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected redux: NgRedux<IAppState>,
    private locationsActionsCreator: LocationsActionsCreator,
    private route: ActivatedRoute,
    protected router: Router,
    protected controller: ExternalResourcesController,
    protected apiService: ExternalResourceApiService,
    protected toastrService: ToastrService,
    protected modalService: ModalService,
    protected duplicateTrackingService: DuplicateTrackingService,
    private fb: FormBuilder,
  ) {
    super();
  }

  private authConfig = {
    authMethod: {
      noAuth: null,
      apiKey: [
        'auth_api_key_add_to',
        'auth_api_key_key',
        'auth_api_key_value',
      ],
    },
  };

  protected successMessage = 'Deine Änderungen wurden erfolgreich gespeichert';
  protected onCloseRoute = ExternalResourcesComponent.ROUTE;

  @select(LocationsSelector.getCheckedIds) public selectedLocations$: Observable<Array<LocationId>>;
  @select(LocationGroupsSelector.getAll) locationGroupList$: Observable<Array<IApiGetLocationGroup>>;

  public protocolOptions = [
    ...EXTERNAL_RESOURCES_PROTOCOLS,
  ];

  public authMethodOptions = [
    ...EXTERNAL_RESOURCES_AUTH_METHODS,
  ];

  public apiKeyAddToOptions = [
    ...EXTERNAL_RESOURCES_API_KEY_ADD_TO,
  ];

  public form: FormGroup = this.fb.group(<ExternalResourceForm>{
    name: ['', Validators.required],
    description: [''],
    slug: [''],
    url: ['', Validators.required],
    protocol: ['', Validators.required],
    port: undefined,
    username: undefined,
    password: undefined,
    start_time: ['', Validators.required],
    interval: [null, Validators.required],
    script: ['', Validators.required],
    locations: ['', Validators.required],
    auth_config: this.fb.group({}),
  });

  // @ts-ignore
  public model: Model = {
    name: '',
    description: '',
    url: '',
    protocol: '',
    port: undefined,
    username: undefined,
    password: undefined,
    start_time: '',
    interval: null,
    script: '',
    locations: [],
    auth_config: null,
  };

  private rawModel: Model = {
    name: '',
    description: '',
    url: '',
    slug: '',
    protocol: '',
    port: undefined,
    username: undefined,
    password: undefined,
    start_time: '',
    interval: null,
    script: '',
    locations: [],
    auth_config: null,
  };

  protected validators = [];

  protected isModelValid() {
    return this.form.valid;
  }

  private prepareAuthConfig() {
    const {
      auth_config,
      protocol,
      url,
    } = this.form.value;
    let config = null;
    if (protocol === 'rest-api') {
      const {
        auth_api_key_add_to,
        auth_api_key_key,
        auth_api_key_value,
        auth_method,
      } = auth_config;
      config = {
        baseUrl: url,
      };
      if (auth_method === 'api-key') {
        if (auth_api_key_add_to === 'query-params') {
          return {
            ...config,
            query: {
              [auth_api_key_key]: auth_api_key_value,
            },
          };
        }
      }
    }

    return config;
  }

  private decodeAuthConfig(protocol: string, authConfig: string | null) {
    const modelConfig: Partial<ExternalResourceForm> = {
      auth_config: {},
    };
    if (authConfig) {
      const config = JSON.parse(authConfig);
      if (protocol === 'rest-api') {
        modelConfig.url = config.baseUrl;

        const authConfigControl = this.form.get('auth_config') as FormGroup;
        authConfigControl.addControl('auth_method', new FormControl(null, Validators.required));
        if (config.query) {
          this.authConfig.authMethod.apiKey.forEach(apiKeyKey => authConfigControl.addControl(apiKeyKey, new FormControl(null, Validators.required)));

          modelConfig.auth_config.auth_method = 'api-key';
          modelConfig.auth_config.auth_api_key_add_to = 'query-params';
          Object.entries(config.query).forEach(([key, value]) => {
            modelConfig.auth_config.auth_api_key_key = key;
            modelConfig.auth_config.auth_api_key_value = value;
          });
        } else {
          modelConfig.auth_config.auth_method = 'no-auth';
        }
      }
    }

    return modelConfig;
  }

  protected async prepareModel() {
    const {
      slug,
      auth_method,
      auth_api_key_key,
      auth_api_key_value,
      auth_api_key_add_to,
      ...formValue } = this.form.value;

    const authConfig = this.prepareAuthConfig();

    this.model = {
      ...formValue,
      locations: formValue.locations.split(',').map(Number),
      auth_config: authConfig ? JSON.stringify(authConfig) : null,
    };

    return Promise.resolve(formValue);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.selectedLocations$.pipe(
      tap(this.onLocationsChange.bind(this)),
      takeUntil(this.ngDestroy$),
    ).subscribe();

    this.form.valueChanges.pipe(
      filter(() => !this.isFormProcessing),
      tap(() => this.wasFormModified = true),
      scan((prevState, currentState) => {
        this.isFormProcessing = true;
        const key = 'auth_config';
        const currentAuthMethod = currentState[key].auth_method;
        const prevAuthMethod = prevState[key].auth_method;

        if (currentAuthMethod !== prevAuthMethod) {
          const authConfigControl = this.form.get(key) as FormGroup;

          if (currentAuthMethod === 'api-key') {
            this.authConfig.authMethod.apiKey.forEach(apiKeyKey => authConfigControl.addControl(
              apiKeyKey,
              new FormControl(null, Validators.required),
            ));
          } else {
            this.authConfig.authMethod.apiKey.forEach(apiKeyKey => authConfigControl.removeControl(apiKeyKey));
          }
        }

        this.isFormProcessing = false;

        return currentState;
      }),
      takeUntil(this.ngDestroy$),
    ).subscribe();

    this.form.get('protocol').valueChanges.pipe(
      tap((protocol) => {
        const authConfig = this.form.get('auth_config') as FormGroup;
        if (protocol === 'rest-api') {
            authConfig.addControl('auth_method', new FormControl(null, Validators.required));
        } else {
          authConfig.removeControl('auth_method');
        }
      }),
    ).subscribe();

    this.viewChecked$.pipe(
      take(1),
      tap(() => this.checkSelectedLocations()),
      takeUntil(this.ngDestroy$),
    ).subscribe();
  }

  public ngAfterViewChecked() {
    this.viewChecked$.next();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.redux.dispatch(this.locationsActionsCreator.resetChecked());
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  private checkSelectedLocations() {
    setTimeout(() => {
      this.rawModel.locations.forEach((locationId: LocationId) => {
        this.redux.dispatch(this.locationsActionsCreator.setChecked(locationId, true));
      });
    });
  }

  private onLocationsChange(locationsIds: Array<LocationId>) {
    this.model.locations = [ ...locationsIds ];
    this.form.get('locations').setValue(locationsIds.join(','));
  }

  private initResource(resource?: IStateExternalResource) {
    if (!resource) {
      return;
    }

    const {
      id,
      index,
      isChecked,
      auth_config,
      ...resourceData } = resource;

    const authConfigModel = this.decodeAuthConfig(resourceData.protocol, auth_config);

    this.model = {
      ...resourceData,
      ...authConfigModel,
    };
    this.rawModel = { ...resourceData };
    this.form.patchValue(this.model);
  }

  protected getParamsSub() {
    return this.route.params
      .pipe(flatMap(({id}) => {
        this.id = id;
        return this.redux.select(ExternalResourcesSelector.getById(id));
      }))
      .subscribe(this.initResource.bind(this));
  }
}
