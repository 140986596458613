import {Injectable} from '@angular/core';
import {AbstractActionsCreator, IAction} from '../abstractActionsCreator';
import {IApiGetMedias} from '../../../../../../common/interfaces/medias';

export const namespace = '@MEDIAS/';

export type IMediasAction = IAction<IApiGetMedias>;

@Injectable()
export class MediasActionsCreator extends AbstractActionsCreator<IApiGetMedias> {
  private static instance: MediasActionsCreator;
  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!MediasActionsCreator.instance) {
      MediasActionsCreator.instance = new MediasActionsCreator();
    }

    return MediasActionsCreator.instance;
  }
}
