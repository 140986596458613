import {DOCUMENT} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {ModalService} from '../../../../services/modal.service';
import {NgRedux} from '@angular-redux/store';
import {AbstractModalBox} from '../abstract-modal-box/abstract-modal-box';
import {IAppState} from '../../../../redux/store';
import {IStateLocation, LocationsSelector} from '../../../../redux/reducers/locations.reducer';
import {LocationsController} from '../../../../redux/actions/locations/locations.controller';
import {LocationApiService} from '../../../../services/api/location.api.service';
import {UploadModalBoxComponent} from '../upload-modal-box/upload-modal-box.component';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import defaultRunTimes from '../../../../../../../common/runtimes';
import {LocationsActionsCreator} from '../../../../redux/actions/locations/locations.action';
import {Runtimes} from '../../../util/runtimes';


@Component({
  selector: 'app-location-runtime-modal-box',
  templateUrl: './location-runtime-modal-box.component.html',
  styleUrls: ['../abstract-modal-box/modal-box-style.scss'],
})

export class LocationRuntimeModalBoxComponent extends AbstractModalBox {

  constructor(
    @Inject(DOCUMENT) document: Document,
    modalService: ModalService,
    private toastrService: ToastrService,
    private redux: NgRedux<IAppState>,
    private controller: LocationsController,
    private apiService: LocationApiService,
    private actionFactory: LocationsActionsCreator,
    private runtimesClass: Runtimes,
  ) {
    super(document, modalService);
  }

  public clonedDefaultRuntimes = _.cloneDeep(defaultRunTimes);
  public isSubmitted = false;
  public isInvalidForm = false;

  public validationModel: IStateLocation | any = {
    runtimes: this.runtimesClass.fixRunTimes(this.clonedDefaultRuntimes),
  };

  async submit() {
    if (this.isSubmitted) {
      return;
    }

    this.isSubmitted = true;

    this.isInvalidForm = !this.isModelValid();

    if (this.isInvalidForm) {
      this.isSubmitted = false;
      return;
    }

    this.modalService.close();

    const checkedLocations = LocationsSelector.getChecked(this.redux.getState());

    await this.modalService.open(UploadModalBoxComponent, {
      isMobile: false,
      status: 'uploading',
      title: 'Deine Änderungen werden',
      subTitle: 'gespeichert.',
      isButtonHidden: true,
      isIconHidden: false,
    }, false);

    try {
      await Promise.all(_.map(checkedLocations, (location) => {
        const locationData = this.runtimesClass.getLocationModelData(location, this.validationModel.runtimes);

        return this.apiService.update(location.id, locationData, false);
      }));

      await this.onSuccess();
      if (_.isArray(checkedLocations)) {
        _.each(checkedLocations, (location) => this.redux.dispatch(this.actionFactory.toggleChecked(location.id)));
      }
    } catch (err) {
      this.toastrService.error('Deine Änderungen konnten nicht gespeichert werden. Bitte versuche es nocheinmal.');
      this.modalService.close();
    } finally {
      this.isSubmitted = false;
    }
  }

  private isModelValid() {
    const convertedRuntimesArray = this.runtimesClass.convertRunTimes(this.validationModel.runtimes);
    const emptyRuntimesArray = _.filter(convertedRuntimesArray, (convertedRuntime) => !convertedRuntime.startTime ||
      !convertedRuntime.startDay || !convertedRuntime.endTime || !convertedRuntime.endDay);

    return emptyRuntimesArray.length <= 0;
  }

  public onRuntimeRemove(index: number) {
    _.pullAt(this.validationModel.runtimes, [index]);
  }

  public onRuntimeAdd() {
    this.validationModel.runtimes.push({startTime: '', endTime: '', startDay: '', endDay: ''});
  }

  private async onSuccess() {
    this.modalService.update({
      onSubmit: () => this.redux.dispatch(this.controller.updateCurrent()),
      status: 'uploaded',
      title: 'Deine Änderungen wurden',
      subTitle: 'erfolgreich gespeichert.',
      isButtonHidden: false,
      isIconHidden: true,
      buttonText: 'Schliessen',
    });
  }

}
