import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '../abstract-value-accessor/abstract-value-accessor';
import * as _ from 'lodash';

const COLOR_PICKER_COLORS = 'color-picker-colors';

@Component({
  selector: 'ba-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [MakeProvider(BaColorPickerComponent)],
})
export class BaColorPickerComponent extends AbstractValueAccessor<string> implements OnInit {

  @Input() id: string | number | number[];
  @Input() label: string;
  @Input() isRequired: string;
  @Input() isAddButton: boolean;
  @Input() presetColors: string[];
  @Output() colorPickerChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    super();
    this.value = undefined;
  }

  saveColorChanged(value: string) {
    this.colorPickerChanged.emit(value);
  }

  onDataChange(value: string[]) {
    this.presetColors = value;
    window.localStorage.setItem(COLOR_PICKER_COLORS, JSON.stringify(this.presetColors));
  }

  ngOnInit() {
    this.presetColors = _.chain(this.presetColors).concat(this.getCachedPresetColors()).compact().uniq().value();
  }

  onOpen() {
    this.presetColors = this.getCachedPresetColors();
  }

  private getCachedPresetColors() {
    return JSON.parse(window.localStorage.getItem(COLOR_PICKER_COLORS) || '[]');
  }

}
