import {Component, Injectable} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {UsersSelector} from '../../../redux/reducers/users.reducer';
import {Observable} from 'rxjs';
import {Resolve} from '@angular/router';
import {IAppState} from '../../../redux/store';
import {UsersController} from '../../../redux/actions/users/users.controller';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {

  constructor() {
  }

  public static ROUTE = 'dashboard';

  @select(UsersSelector.getMe) user$: Observable<any>;
}

@Injectable()
export class NavbarDataResolver implements Resolve<any> {
  constructor(private redux: NgRedux<IAppState>, private usersController: UsersController) {
  }

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.usersController.updateMe());
  }
}
