import {Component, Input, OnInit} from '@angular/core';
import {IStateLocation} from '../../../redux/reducers/locations.reducer';
import {PlaylistType} from '../../../../../../common/interfaces/playlist';

@Component({
  selector: 'ba-row-shuffle',
  templateUrl: './row-shuffle.component.html',
  styleUrls: ['./row-shuffle.component.scss'],
})
export class RowShuffleComponent implements OnInit {

  @Input() data: IStateLocation;

  playlistType = PlaylistType;

  constructor() { }

  ngOnInit() {
  }

}
