import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'ba-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']})
export class BaCheckboxComponent {
  name = 'default';
  idSuffix = '';

  @Input() id: string | number | number[];
  @Input() isDisabled: boolean;
  @Input() isChecked: boolean;
  @Input() isRequired: string;
  @Input() isSmall: boolean;
  @Input() whenCheckboxChanged: Function = _.noop;
  @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  toggleCheck() {
    this.whenCheckboxChanged();
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }
}
