import {IApiGetBooking} from '../../../../../common/interfaces/booking';
import {AbstractReducer, IMetaState, IStateDataType} from './abstract.reducer';
import {BookingsActionsCreator, IBookingsAction} from '../actions/bookings/bookings.action';
import * as _ from 'lodash';

export type IBookingsState = IMetaState<IStateBooking>;

export interface IStateBooking extends IApiGetBooking, IStateDataType {
}

export interface IBookingFilters {
  id: string;
  'booking_template.name': string;
  name: string;
  'locations.name': string;
  start_date: string;
  end_date: string;
}

export class BookingsReducer extends AbstractReducer<IStateBooking, IBookingsAction> {
  protected constructor() {
    super('bookings', BookingsActionsCreator.getInstance());
  }

  private static instance: BookingsReducer;

  public DEFAULT_STATE: IMetaState<IStateBooking> = _.assign({}, AbstractReducer.DEFAULT_STATE, {
    filters: {id: '', 'booking_template.name': '', name: '', 'locations.name': '', start_date: '', end_date: ''} as IBookingFilters,
    sortings: {id: 'DESC'},
  });

  public static getInstance() {
    if (!BookingsReducer.instance) {
      BookingsReducer.instance = new BookingsReducer();
    }

    return BookingsReducer.instance;
  }
}

export const BookingsSelector = BookingsReducer.getInstance().getSelectors();
