import * as _ from 'lodash';
import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {AuthApiService} from '../services/api/auth.api.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error) {
    const authApi = this.injector.get(AuthApiService);
    const toastrService = this.injector.get(ToastrService);

    console.warn(error);

    const isAccessDeniedError = _.get(error, 'rejection.status', 0) === 401;
    if (isAccessDeniedError) {
      authApi.reset();
      window.location.href = '/login';
      return;
    }

    return toastrService.error('', 'Es gab ein Problem beim speichern. Bitte versuche es noch einmal.');
  }
}
