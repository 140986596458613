import {BookingTemplatesActionsCreator, IBookingTemplatesAction} from '../actions/booking-templates/booking-templates.action';
import {IApiGetBookingTemplate} from '../../../../../common/interfaces/booking';

import {AbstractReducer, IMetaState, IStateDataType} from './abstract.reducer';
import * as _ from 'lodash';

export type IBookingTemplatesState = IMetaState<IStateBookingTemplate>;

export interface IStateBookingTemplate extends IApiGetBookingTemplate, IStateDataType {
}

export interface IBookingTemplatesFilters {
  name: string;
}

export class BookingTemplatesReducer extends AbstractReducer<IStateBookingTemplate, IBookingTemplatesAction> {
  private constructor() {
    super('bookingTemplates', BookingTemplatesActionsCreator.getInstance());
  }

  private static instance: BookingTemplatesReducer;

  public DEFAULT_STATE: IMetaState<IStateBookingTemplate> = _.assign({}, AbstractReducer.DEFAULT_STATE, {
    filters: {name: ''} as IBookingTemplatesFilters,
    sortings: {id: 'ASC'},
  });

  public static getInstance() {
    if (!BookingTemplatesReducer.instance) {
      BookingTemplatesReducer.instance = new BookingTemplatesReducer();
    }

    return BookingTemplatesReducer.instance;
  }
}

export const BookingTemplatesSelector = BookingTemplatesReducer.getInstance().getSelectors();
