import {Injectable} from '@angular/core';
import {AbstractActionsCreator, IAction} from '../abstractActionsCreator';
import {IApiGetBookingTemplate} from '../../../../../../common/interfaces/booking';

export const namespace = '@BOOKING_TEMPLATES/';

export type IBookingTemplatesAction = IAction<IApiGetBookingTemplate>;

@Injectable()
export class BookingTemplatesActionsCreator extends AbstractActionsCreator<IApiGetBookingTemplate> {
  private static instance: BookingTemplatesActionsCreator;
  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!BookingTemplatesActionsCreator.instance) {
      BookingTemplatesActionsCreator.instance = new BookingTemplatesActionsCreator();
    }

    return BookingTemplatesActionsCreator.instance;
  }

}
