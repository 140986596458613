import {DOCUMENT} from '@angular/common';
import {HostListener, Inject, Input} from '@angular/core';
import {IDeleteTexts, ModalService} from '../../../../services/modal.service';
import * as keycode from 'keycode';
import * as _ from 'lodash';

export abstract class AbstractModalBox {

  protected constructor(@Inject(DOCUMENT) protected document: Document, protected modalService: ModalService) {
  }

  @Input() title = '';
  @Input() isMobile = false;
  @Input() deleteTexts: IDeleteTexts = {
    delete: '',
    reallyDelete: '',
    noKeep: '',
    yesDelete: '',
  };

  @Input() status: String;

  @Input() buttonText: String;
  @Input() isButtonHidden = false;
  @Input() isIconHidden = false;

  @Input() onSubmit = _.noop;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // tslint:disable
    if (event.keyCode === keycode.aliases.escape) {
      this.modalService.close();
    }
  }

  protected async preSaveHook(data?: unknown): Promise<any> {
  }

  public async submit() {
    await this.onSubmit();
    this.modalService.close();
  }

  public close() {
    this.modalService.close();
  }
}
