import * as pdfjs from 'pdfjs-dist';
import {PDFPageProxy} from 'pdfjs-dist';
import * as _ from 'lodash';
import { NAME } from '../../../../../common/constants';

const createImageBlogFromPage = async (page: PDFPageProxy | any, viewport, fileName: string) => {
  const canvas = document.createElement('canvas');

  const context = canvas.getContext('2d');
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  await page.render({canvasContext: context, viewport: viewport}).promise;

  const newFile: Blob = await new Promise<Blob>((resolve) => canvas.toBlob(resolve));

  _.set(newFile, NAME, fileName);
  return newFile as File;
};

export const convertPdfFirstPageToImage = async (file: File) => {
  const doc = await pdfjs.getDocument(URL.createObjectURL(file)).promise;
  if (doc.numPages > 1) {
    this.isMultiPagePdf = true;
    this.isMimeTypeAllowed = false;
    return file;
  }

  const page: pdfjs.PDFPageProxy | any = await doc.getPage(1);

  const [x1, y1, x2, y2] = page.view;

  const width = x2 - x1;
  const height = y2 - y1;

  const maxSideLength = _.min([width, height]);
  const referenceSideLength = 2000;

  // we need to increase the scale factor relative to the already existing pdf size,
  // because otherwise the images might look pixelated on big screens
  const scale = _.max([1, referenceSideLength / maxSideLength]);

  const viewport = page.getViewport(scale);

  return await createImageBlogFromPage(page, viewport, file.name);
};
