import {IComponentToRender, IPlayerState} from './interfaces';
import ComponentManager from './component-manager';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';

/**
 * This class is responsible for handling the player state, e.g. is the player stopped, paused or playing
 */
@Injectable()
export default class PlayerManager {

  constructor(private componentManager: ComponentManager) {
  }

  private playerState = {paused: false, stopped: false} as IPlayerState;
  private stoppable = true;

  public getState() {
    return _.cloneDeep(this.playerState);
  }

  public setStoppedState(stopped: boolean) {
    this.playerState.stopped = !!stopped;
  }

  public setState(playerState: IPlayerState) {
    this.playerState = playerState;
  }

  public setStoppable(stoppable: boolean) {
    this.stoppable = stoppable;
  }

  public getStoppable() {
    return this.stoppable;
  }

  public pauseOrPlay() {
    if (this.playerState.paused || this.playerState.stopped) {
      this.play();
    } else if (!this.playerState.paused) {
      this.pause();
    }
  }

  private pause() {
    console.debug('player - pause');
    if (this.playerState.stopped) {
      return;
    }

    this.playerState.paused = true;
    this.componentManager.pause();
  }

  private play() {
    console.debug('player - play');
    const isStopped = this.playerState.stopped;

    this.playerState.paused = false;
    this.playerState.stopped = false;

    if (isStopped && this.stoppable) {
      this.componentManager.stop();
    }

    this.componentManager.start();
  }

  public stop() {
    console.debug('player - stop');
    this.playerState.paused = false;
    if (this.stoppable) {
      this.playerState.stopped = true;
    }

    this.componentManager.stop();
  }

  public next() {
    console.debug('player - next');

    // prev and next are not supposed to work in stopped mode
    if (this.playerState.stopped) {
      console.debug('player - is already stopped');
      return;
    }

    this.componentManager.stop();
  }

  /**
   * This function calculates the index offset if a user wants to jump to the previous booking.
   * As this might sound easy, it is not, because we can not simply use the previous index,
   * as the booking related to that index might not be visible. So we have to loop backwards over all bookings,
   * until we find the first visible one. This could also mean that we have to go "below" index 0.
   *
   * @param componentsToRender - the list of the components to render
   * @param currentIndex - the index where the player is currently at
   */
  public prev(componentsToRender: IComponentToRender[], currentIndex: number): number {
    console.debug('player - prev');

    // prev and next are not supposed to work in stopped mode
    if (this.playerState.stopped) {
      console.debug('player - is already stopped');
      return 0;
    }

    let newCurrentIndex = currentIndex;

    const componentsCount = componentsToRender.length;
    for (let i = 1; i < componentsCount; ++i) {
      // we have to add componentsCount first, because % operation cant handle negative numbers
      const index = (currentIndex + componentsCount - i) % componentsCount;

      const currentComponentToRender = componentsToRender[index];

      // check if current component is visible. If it is, we have found the new index
      if (currentComponentToRender.isVisible() && currentComponentToRender.componentRef.instance.isVisible()) {
        newCurrentIndex = index;
        break;
      }
    }

    // stop the currently playing component. This will resolve the onDone promise and the renderer loop will jump to the next booking
    this.componentManager.stop();

    // we have to return the offset (and not the index), which is needed to get to the previous component
    // we also have to additionally subtract 1, because the renderer loop will add 1 when it jumps to the next booking
    return (newCurrentIndex % componentsCount) - currentIndex - 1;
  }

}
