import { ComponentRef, Type } from '@angular/core';

export enum DisplayMode {
  Priority = 'PRIORITY',
  Standard = 'STANDARD',
  Fallback = 'FALLBACK',
  Category = 'CATEGORY',
}

export enum LogsLevel {
  SUCCINCT = 'succinct',
  VERBOSE = 'verbose',
}

export enum PlaylistType {
  shuffle = 'shuffle',
  custom = 'custom',
}

export interface ILocationAttribute {
  key: string;
  value: string;
}

export type IWeekday = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export interface IRuntime {
  startDay: IWeekday | string;
  endDay: IWeekday | string;
  startTime: string;
  endTime: string;
}

export interface ISpecialRuntime {
  startDay: string;
  endDay: string;
  startTime: string;
  endTime: string;
}


interface IInput { value: string; type: string; name: string; }

interface IBookingFile {
  encoding?: string;
  fieldname: string;
  id?: number;
  key: string;
  meta?: string;
  mimetype: string;
  name?: string;
  scope?: string;
  thumbnail_url: string;
  screenshot_url?: string;
}

interface IBookingTemplate {
  id: number;
  name: string;
  category: string;
  duration_calculator: string;
  inputs: { id: number; name: string; type: string; }[];
  template: { id: number; name: string; encoding: string; mimetype: string; key: string; fieldname: string; };
  thumbnail: { id: number; name: string; encoding: string; mimetype: string; key: string; fieldname: string; };
  assets: { id: number; name: string; encoding: string; mimetype: string; key: string; fieldname: string; };
}

export interface IBooking {
  id: number;
  name: string;
  category_id?: number;
  display_mode: DisplayMode;
  locations_group_name?: string | null;
  inputs: { [key: number]: IInput } | IInput[];
  start_date: string;
  end_date?: string | null;
  start_time?: string | null;
  end_time?: string | null;
  duration: number;
  booking_template: IBookingTemplate;
  files: Array<IBookingFile>;
  thumbnail_url: string;
  screenshot_url: string;
  week_days: Array<string> | null;
}

export interface ILocation {
  id: number;
  external_id: string;
  name: string;
  active: boolean;
  width: number;
  height: number;
  city: string;
  country: string;
  timezone: string;
  playlist_type: PlaylistType | null;
  zip?: string | null;
  street_and_number?: string | null;
  runtimes: IRuntime[];
  attributes: Array<ILocationAttribute>;
  additional_data: { [key: string]: any };
  comment: string | null;
  ip: string | null;
  last_seen: string | null;
  status: string;
}

export interface IComponentToRender {
  booking: IBooking;
  componentRef: ComponentRef<any>;
  genComponentRef: () => ComponentRef<any>;
  duration: number;
  isVisible: () => boolean;
}

export interface IPlayerState {
  paused: boolean;
  stopped: boolean;
}

export type GetSource = (key: string) => Promise<string | Type<any>>;
export type GetFile = (key: string) => Promise<any>;

export interface ILogItem {
  booking_id: number;
  booking_template_id: number;
  data: string;
  location_id: number;
  timestamp: number;
  type: string;
}

export interface IBookingLogItem {
  type: string;
  timestamp: number;
  bookingId: number;
  bookingDuration: number;
  bookingFiles: IBooking['files'];
  bookingInputs: IBooking['inputs'];
  bookingName: string;
  bookingTemplate: IBookingTemplate;
}

export enum PlaybackTrackType {
  PLAYBACK_START = 'PLAYBACK_START',
  PLAYBACK_STOP = 'PLAYBACK_STOP',
}
