import { Injectable} from '@angular/core';
import {
  AbstractActionsCreator,
  IAction,
  IActionTypes,
} from '../abstractActionsCreator';
import {
  IApiSetCategories,
} from '../../../../../../common/interfaces/category';

export const namespace = '@CATEGORIES/';

export type ICategoriesAction = IAction<IApiSetCategories>;
export interface ICategoriesActionTypes extends IActionTypes {
  SAVE_UPDATE: string;
}

@Injectable()
export class CategoriesActionsCreator extends AbstractActionsCreator<IApiSetCategories> {
  private static instance: CategoriesActionsCreator;
  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!CategoriesActionsCreator.instance) {
      CategoriesActionsCreator.instance = new CategoriesActionsCreator();
    }

    return CategoriesActionsCreator.instance;
  }

  public getActionTypes(): ICategoriesActionTypes {
    return {
      ...super.getActionTypes(),
      SAVE_UPDATE: namespace + 'SAVE_UPDATE',
    };
  }
}
