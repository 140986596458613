import {Dispatch} from 'redux';
import * as _ from 'lodash';

import {AbstractCrudApiService} from '../../services/api/abstract-crud.api.service';
import {IAppState} from '../store';
import {AbstractActionsCreator, IAddAction, IDelete, ISetAction, ISetFilter, IUpdateAction} from './abstractActionsCreator';
import {ISelectors} from '../reducers/abstract.reducer';
import {IApiFilter, IApiSorting} from '../../../../../common/interfaces/api';
import {LIKE} from '../../../../../common/constants';
import {IStringMap} from '../../../../../common/interfaces/util';

export abstract class AbstractCrudController<getDataType = any, setDataType = any> {
  protected abstract selectors: ISelectors<getDataType>;

  private readonly debouncedUpdateFilter: ((dispatch: Dispatch, getState: () => IAppState, filter: string, filterName: string) => Promise<void>);

  protected constructor(protected apiService: AbstractCrudApiService, protected actionFactory: AbstractActionsCreator<setDataType>) {
    this.debouncedUpdateFilter = _.debounce(
      (dispatch: Dispatch, getState: () => IAppState, filter: string, filterName: string) =>
        this.updateFilterInstantly(filter, filterName)(dispatch, getState),
      500,
    );
  }

  public async update(dispatch: Dispatch, getState: () => IAppState, isActive: boolean): Promise<any> {
    const data = await this.apiService.getByFiltersAndSortings(
      this.getApiAndFilters(isActive, getState),
      this.getApiOrFilters(isActive, getState),
      this.getApiSortings(getState),
    );
    const action: ISetAction<Partial<setDataType>> = this.actionFactory.set(data, isActive);
    return dispatch(action);
  }

  public async updateWithCurrentId(id: number, dispatch: Dispatch, getState: () => IAppState, isActive: boolean): Promise<any> {
    let data = await this.apiService.getByFiltersAndSortings(
      this.getApiAndFilters(isActive, getState),
      this.getApiOrFilters(isActive, getState),
      this.getApiSortings(getState),
    ) as any;

    data = _.filter(data, (element) => element.id === id);
    data = _.head(data);

    const action: IUpdateAction<Partial<setDataType>> = this.actionFactory.update(data);
    return dispatch(action);
  }

  public async fetchMore(dispatch: Dispatch, getState: () => IAppState): Promise<any> {
    const data = await this.apiService.getByFiltersAndSortingsWithPagination(
      this.getApiAndFilters(true, getState),
      this.getApiOrFilters(true, getState),
      this.getApiSortings(getState),
      this.getApiPage(getState),
    );
    const action: IAddAction<Partial<setDataType>> = this.actionFactory.add(data);
    return dispatch(action);
  }

  protected getApiSortings(getState: () => IAppState): IApiSorting[] {
    return this.toSortings(this.getSortings(getState));
  }

  protected getApiAndFilters(isActive: boolean, getState: () => IAppState): IApiFilter[] {
    return this.toApiFilters(this.getFilters(getState));
  }

  protected getApiOrFilters(isActive: boolean, getState: () => IAppState): IApiFilter[] {
    return [];
  }

  protected getApiPage(getState: () => IAppState): number {
    return this.getPage(getState);
  }

  protected getSortings(getState: () => IAppState): IStringMap {
    return _.clone(this.selectors.getSortings(getState()));
  }

  protected getFilters(getState: () => IAppState): IStringMap {
    return _.clone(this.selectors.getFilters(getState()));
  }

  protected getPage(getState: () => IAppState): number {
    return _.clone(this.selectors.getPage(getState()));
  }

  protected toSortings(sortings: any = {}): IApiSorting[] {
    return _.chain(sortings)
      .map((sorting, column) => ({column, sorting}))
      .filter(({sorting}) => sorting !== '')
      .value();
  }

  protected toApiFilters(andFilters: any = {}): IApiFilter[] {
    return _.chain(andFilters)
      .map((value, column) => ({column, operator: LIKE, value}))
      .filter(({value}) => value !== '')
      .value();
  }

  private async updateAndResetChecked(dispatch: Dispatch, getState: () => IAppState, isActive: boolean): Promise<any> {
    dispatch(this.actionFactory.resetChecked());
    return await this.update(dispatch, getState, isActive);
  }

  updateCurrent(): any {
    return (dispatch: Dispatch, getState: () => IAppState) => this.update(dispatch, getState, this.selectors.isActive(getState()));
  }

  updateCurrentWithId(id: number): any {
    return (dispatch: Dispatch, getState: () => IAppState) => this.updateWithCurrentId(id, dispatch, getState, this.selectors.isActive(getState()));
  }

  updateActive(): any {
    return (dispatch: Dispatch, getState: () => IAppState) => this.updateAndResetChecked(dispatch, getState, true);
  }

  updateInActive(): any {
    return (dispatch: Dispatch, getState: () => IAppState) => this.updateAndResetChecked(dispatch, getState, false);
  }

  getMore(): any {
    return (dispatch: Dispatch, getState: () => IAppState) => this.fetchMore(dispatch, getState);
  }

  public updateNames(): any {
    return async (dispatch: Dispatch): Promise<any> => {
      const data = await this.apiService.getIdsAndNames();
      const action: ISetAction<Partial<setDataType>> = this.actionFactory.set(data, true);
      dispatch(action);
    };
  }

  updateFilterInstantly(filter: string, filterName?: string): any {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
      const action: ISetFilter = this.actionFactory.setFilter(filter, filterName);
      dispatch(action);

      await this.updateCurrent()(dispatch, getState);
    };
  }

  updateFilter(filter: string, filterName?: string): any {
    return (dispatch: Dispatch, getState: () => IAppState) => this.debouncedUpdateFilter(dispatch, getState, filter, filterName);
  }

  addData(data: any): any {
    return async (dispatch: Dispatch) => {
      const action: IAddAction<Partial<setDataType>> = this.actionFactory.add(data);
      dispatch(action);
    };
  }

  deleteData(id: number): any {
    return async (dispatch: Dispatch) => {
      const action: IDelete = this.actionFactory.deleteData([id], true);
      dispatch(action);

      await this.apiService.deleteData([id]);
    };
  }

  deleteChecked(): any {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
      const ids = this.selectors.getCheckedIds(getState());
      const action: IDelete = this.actionFactory.deleteData(ids, false);
      dispatch(action);

      await this.apiService.deleteData(ids);
    };
  }
}
