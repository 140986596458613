import {Component, Injectable, Input} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {BookingTemplatesSelector} from '../../../../../redux/reducers/booking-templates.reducer';
import {Observable} from 'rxjs';
import {IApiGetBookingTemplate} from '../../../../../../../../common/interfaces/booking';
import {full} from '../../../../../../../../common/routes';
import {Resolve} from '@angular/router';
import {IAppState} from '../../../../../redux/store';
import {BookingTemplatesController} from '../../../../../redux/actions/booking-templates/booking-templates.controller';
import {AuthApiService} from '../../../../../services/api/auth.api.service';
import {TrackByService} from '../../../../../services/track-by.service';
import {BookingTemplatesActionsCreator} from '../../../../../redux/actions/booking-templates/booking-templates.action';
import * as _ from 'lodash';

@Component({
  selector: 'app-booking-templates-list',
  templateUrl: './booking-templates-list.component.html',
  styleUrls: ['./booking-templates-list.component.scss'],
})
export class BookingTemplatesListComponent {

  @Input() selection = false;
  @Input() editable = false;
  @Input() routerLinkPrefix = '';
  @Input() whenCheckboxChanged: Function = _.noop;

  @select(BookingTemplatesSelector.getAll) readonly bookings$: Observable<IApiGetBookingTemplate[]>;
  public fileApiRoute = full.files;
  public getFilterByNameSelector: (name: string) => ($state: IAppState) => any;

  constructor(
    public authApiService: AuthApiService,
    public crudController: BookingTemplatesController,
    public actionFactory: BookingTemplatesActionsCreator,
    public trackByService: TrackByService,
  ) {
    this.getFilterByNameSelector = BookingTemplatesSelector.getFilterByName;
  }
}

@Injectable()
export class BookingTemplatesDataResolver implements Resolve<any> {
  constructor(private redux: NgRedux<IAppState>, private bookingTemplatesController: BookingTemplatesController) {
  }

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.bookingTemplatesController.updateActive());
  }
}
