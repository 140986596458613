import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {full} from '../../../../../common/routes';
import {AbstractCrudApiService} from './abstract-crud.api.service';
import {AuthApiService} from './auth.api.service';
import {ApiService} from './api.service';

@Injectable()
export class OneWeekApiService extends AbstractCrudApiService {

  protected apiUrl: string = full.oneWeek;

  private localCache = {};

  constructor(http: HttpClient, authApi: AuthApiService, api: ApiService) {
    super(http, authApi, api);
  }

  async getObject(key: string, token?: string): Promise<any> {
    if (!this.localCache[key]) {
      this.localCache[key] = await super.get(key + '?' + this.authApi.getAuthTokenQueryString(token), undefined, true);
    }

    return this.localCache[key];
  }

}
