import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AuthApiService} from './auth.api.service';
import * as _ from 'lodash';
import { catchError } from 'rxjs/operators';


@Injectable()
export class ApiService {

  constructor(private http: HttpClient, private authApi: AuthApiService) {
  }

  private async updateAuthTokenInHeaders(options: any) {
    await this.authApi.refreshAuthToken();
    Object.assign(options.headers, this.authApi.getAuthHeader());
  }

  public async get(path: string = '', options: any = this.authApi.getDefaultHttpOptions(), isQueryAuth = false): Promise<any> {
    try {
      return await this.http.get(path, options).toPromise();
    } catch (err) {
      if (err.status === 401 && !isQueryAuth) {
        await this.updateAuthTokenInHeaders(options);
        return await this.http.get(path, options).toPromise();
      } else if (err.status === 401 && isQueryAuth) {
        const oldAuthToken = this.authApi.getAuthToken();
        await this.authApi.refreshAuthToken();

        const newPath = _.replace(path, oldAuthToken, this.authApi.getAuthToken());
        return await this.http.get(newPath, options).toPromise();
      }

      throw err;
    }
  }

  public async post(path: string = '', props: any, options: any = this.authApi.getDefaultHttpOptions()): Promise<any> {
    try {
      return await this.http.post(path, props, options).toPromise();
    } catch (err) {
      if (err.status === 401) {
        await this.updateAuthTokenInHeaders(options);
        return this.http.post(path, props, options).toPromise();
      }

      throw err;
    }
  }

  public async put(path: string = '', props: any, options: any = this.authApi.getDefaultHttpOptions()): Promise<any> {
    try {
      return await this.http.put(path, props, options).toPromise();
    } catch (err) {
      if (err.status === 401) {
        await this.updateAuthTokenInHeaders(options);
        return this.http.put(path, props, options).toPromise();
      }

      throw err;
    }
  }

  public async patch(path: string = '', props: any, options: any = this.authApi.getDefaultHttpOptions()): Promise<any> {
    try {
      return await this.http.patch(path, props, options).toPromise();
    } catch (err) {
      if (err.status === 401) {
        await this.updateAuthTokenInHeaders(options);
        return this.http.patch(path, props, options).toPromise();
      }

      throw err;
    }
  }

  public async delete(path: string = '', options: any = this.authApi.getDefaultHttpOptions()): Promise<any> {
    try {
      return await this.http.delete(path, options).toPromise();
    } catch (err) {
      if (err.status === 401) {
        await this.updateAuthTokenInHeaders(options);
        return this.http.delete(path, options).toPromise();
      }

      throw err;
    }
  }

  public postObservable(path: string = '', props: any, options: any = this.authApi.getDefaultHttpOptions()) {
    return this.http
      .post(path, props, options)
      .pipe(
        catchError(async (err) => {
          if (err.status === 401) {
            await this.updateAuthTokenInHeaders(options);
            return this.http.post(path, props, options).subscribe();
          }

          throw err;
        },
      ));
  }

  public patchObservable(path: string = '', props: any, options: any = this.authApi.getDefaultHttpOptions()) {
    return this.http
      .patch(path, props, options)
      .pipe(
        catchError(async (err) => {
          if (err.status === 401) {
            await this.updateAuthTokenInHeaders(options);
            return this.http.patch(path, props, options).subscribe();
          }

          throw err;
        },
      ));
  }

  private async fetchWithError(url, options) {
    let result = await fetch(url, options);

    if (result.status === 401) {
      await this.updateAuthTokenInHeaders(options);
      result = await fetch(url, options);
    }

    if (result.status >= 400) {
      throw new Error(JSON.stringify(_.pick(result, ['url', 'headers', 'status']), null, 1));
    }

    return result;
  }

  public fetchPatch(path: string = '', options: any): Promise<any> {
    return this.fetchWithError(path, _.assign({headers: this.authApi.getAuthHeader()}, options, {method: 'PATCH'}));
  }

  public fetchPost(path: string = '', options: any): Promise<any> {
    return this.fetchWithError(path, _.assign({headers: this.authApi.getAuthHeader()}, options, {method: 'POST'}));
  }
}
