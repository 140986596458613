import { Injectable } from '@angular/core';
import {
  AbstractActionsCreator,
  IAction,
} from '../abstractActionsCreator';
import { IApiGetBookingTemplate } from '../../../../../../common/interfaces/booking';

export const namespace = '@TEMPLATES/';

export type ITemplatesAction = IAction<IApiGetBookingTemplate>;

@Injectable()
export class TemplatesActionsCreator extends AbstractActionsCreator<IApiGetBookingTemplate> {
  private static instance: TemplatesActionsCreator;
  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!TemplatesActionsCreator.instance) {
      TemplatesActionsCreator.instance = new TemplatesActionsCreator();
    }

    return TemplatesActionsCreator.instance;
  }

}
