import * as _ from 'lodash';
import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthApiService} from '../services/api/auth.api.service';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router/src/router_state';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authApi: AuthApiService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isLoggedIn = this.authApi.isLoggedIn();
    const isLoginRoute = state.url === '/login';

    if (_.startsWith(state.url, '/full-preview')) {
      return true;
    }

    if (isLoggedIn && isLoginRoute) {
      this.router.navigate(['']);
      return false;
    }

    if (!isLoggedIn && !isLoginRoute) {
      this.router.navigate(['login']);
      return false;
    }

    return true;
  }
}
