import * as _ from 'lodash';

import {IApiGetBookingTemplate} from '../../../../../common/interfaces/booking';
import {
  AbstractReducer,
  IMetaState,
  IStateDataType,
} from './abstract.reducer';
import {
  ITemplatesAction,
  TemplatesActionsCreator,
} from '../actions/templates/templates.action';

export type ITemplatesState = IMetaState<IStateTemplate>;

export interface IStateTemplate extends IApiGetBookingTemplate, IStateDataType {
}

export interface ITemplatesFilters {
  name: string;
  category: string;
  'locations.name': string;
}

export class TemplatesReducer extends AbstractReducer<IStateTemplate, ITemplatesAction> {
  private constructor() {
    super('templates', TemplatesActionsCreator.getInstance());
  }

  private static instance: TemplatesReducer;

  public DEFAULT_STATE: IMetaState<IStateTemplate> = _.assign({}, AbstractReducer.DEFAULT_STATE, {
    filters: {
      name: '',
      category: '',
      'locations.name': '',
    } as ITemplatesFilters,
    sortings: {id: 'ASC'},
  });

  public static getInstance() {
    if (!TemplatesReducer.instance) {
      TemplatesReducer.instance = new TemplatesReducer();
    }

    return TemplatesReducer.instance;
  }
}

export const TemplatesSelector = TemplatesReducer.getInstance().getSelectors();
