import { Component } from '@angular/core';
import { MediaUploadService } from 'src/app/services/media-upload.service';
import { IMediaUploadListItem } from '../../../../../../common/interfaces/medias';

@Component({
  selector: 'ba-medias-upload-list',
  templateUrl: './medias-upload-list.component.html',
  styleUrls: ['./medias-upload-list.component.scss'],
})
export class MediasUploadListComponent {

  public showList = true;

  constructor(private uploadService: MediaUploadService) { }

  get uploadList(): Array<IMediaUploadListItem> {
    const list = Object.entries(this.uploadService.filesList)
      .reduce((prev, [key, value]) => {
        return [
          ...prev,
          value,
        ];
      }, []);

    return list;
  }

  get completedFilesNumber(): number {
    return this.uploadList
      .filter(file => file.progress === 100)
      .length;
  }

  public cancelUpload(id) {
    this.uploadService.cancelRequest(id);
  }

  public removeUploaded(id) {
    this.uploadService.removeFinished(id);
  }

  public toggleList() {
    this.showList = !this.showList;
  }

  public closeList() {
    this.uploadService.removeAl();
  }

}
