import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Inject,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
  Type,
} from '@angular/core';
import * as _ from 'lodash';
import {DOCUMENT} from '@angular/common';

const MODAL_OPENED = 'modal-openend';

export interface IDeleteTexts {
  delete: string;
  reallyDelete: string;
  noKeep: string;
  yesDelete: string;
}

export interface IInputs {
  isMobile?: boolean;
  deleteTexts?: IDeleteTexts;
  onSubmit: () => void;
  status: String;
  title: string;
  subTitle: string;
  isButtonHidden?: boolean;
  isIconHidden?: boolean;
  buttonText: String;
}

@Injectable()
export class ModalService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private rendererFactory: RendererFactory2,
    private injector: Injector,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }
  private isCloseByOverlayEnabled = true;
  private renderer: Renderer2;
  public childComponentRef: ComponentRef<any>;
  private elementRef: ElementRef;
  public onOpen: () => void = () => undefined;
  public onClose: () => void = () => undefined;

  setOnOpen(onOpen: () => void) {
    this.onOpen = onOpen;
  }

  setOnClose(onClose: () => void) {
    this.onClose = onClose;
  }

  setElementRef(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  open(component: Type<any>, inputsForComponent: Partial<IInputs>, enableCloseByOverlay = true): void {
    this.isCloseByOverlayEnabled = enableCloseByOverlay;

    this.childComponentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);
    _.assign(this.childComponentRef.instance, inputsForComponent);

    this.applicationRef.attachView(this.childComponentRef.hostView);

    // @ts-ignore
    const childDomElement: HTMLElement = this.childComponentRef.hostView.rootNodes[0];

    this.elementRef.nativeElement.querySelector('#modal').appendChild(childDomElement);

    this.onOpen();

    this.renderer.addClass(this.document.body, MODAL_OPENED);
  }

  update(inputsForComponent: Partial<IInputs>) {
    if (this.childComponentRef && this.childComponentRef.instance) {
      _.assign(this.childComponentRef.instance, inputsForComponent);
    }
  }

  closeByOverlay() {
    if (this.isCloseByOverlayEnabled) {
      return this.close();
    }
  }

  close() {
    this.applicationRef.detachView(this.childComponentRef.hostView);
    this.childComponentRef.destroy();

    this.onClose();

    this.renderer.removeClass(this.document.body, MODAL_OPENED);
  }
}
