import {
  ChangeDetectionStrategy,
  Component,
  Injectable,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Resolve } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import { Subscription, Observable } from 'rxjs';

import { IAppState } from 'src/app/redux/store';
import { BookingTemplatesSelector } from 'src/app/redux/reducers/booking-templates.reducer';
import { BookingTemplatesController } from 'src/app/redux/actions/booking-templates/booking-templates.controller';
import { IColumn } from '../table-page/table/table.component';
import { IApiGetBookingTemplate } from '../../../../../../common/interfaces/booking';
import { TemplatesController } from 'src/app/redux/actions/templates/templates.controller';
import { TemplatesActionsCreator } from 'src/app/redux/actions/templates/templates.action';
import {
  TemplatesSelector,
  IStateTemplate,
} from 'src/app/redux/reducers/templates.reducer';
import { LocationsController } from 'src/app/redux/actions/locations/locations.controller';

@Component({
  selector: 'app-templates',
  template: `
  <app-table-page
    [name]="name"
    [title]="'Standorte mit Vorlagen verknüpfen'"
    [subtitle]="'Hier kannst Du ganz einfach festlegen, welche Standorte beim Buchen einer Vorlage sichtbar sind.'"
    [useToggle]="false"
    [useActiveInactive]="false"
    [useTime]="false"
    [useRunTime]="false"
    [useDelete]="false"
    [route]="route">
    <app-table
      [isToggleSwitched]="false"
      [name]="name"
      [editOrAddLinkGetter]="editOrAddLinkGetter"
      [columns]="columns"
      [getAllSelector]="getAll"
      [getAllIdsSelector]="getAllIds"
      [isAllCheckedSelector]="isAllChecked"
      [getFilterByNameSelector]="getFilterByName"
      [actionFactory]="actionFactory"
      [crudController]="crudController"
    >
    </app-table>
  </app-table-page>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatesComponent implements OnInit, OnDestroy {

  public static ROUTE = 'booking-templates';
  public name = 'templates';
  public route: string;
  public getAll: ($state: IAppState) => Array<IStateTemplate>;
  public getAllIds: ($state: IAppState) => Array<string>;
  public isAllChecked: ($state: IAppState) => boolean;
  public getFilterByName: (name: string) => ($state: IAppState) => any;

  private bookingTemplateIdsAndNames: Array<{id: number, name: string}>;
  private bookingTemplatesSubscription: Subscription;
  @select(BookingTemplatesSelector.getAll) readonly bookingTemplates$: Observable<Array<IApiGetBookingTemplate>>;

  constructor(
    public crudController: TemplatesController,
    public actionFactory: TemplatesActionsCreator,
  ) {
    this.route = TemplatesComponent.ROUTE;

    this.getAll = TemplatesSelector.getAll;
    this.getAllIds = TemplatesSelector.getAllIds;
    this.isAllChecked = TemplatesSelector.isAllChecked;
    this.getFilterByName = TemplatesSelector.getFilterByName;
  }

  public columns: IColumn[] = [
    {
      name: 'Vorlagenbezeichnung',
      filterName: 'id',
      cellClass: 'img',
      placeholder: 'Alle',
      getSearchOptions: () => this.bookingTemplateIdsAndNames,
      dataGetter: (data) => data.thumbnail.key,
    },
    {
      name: 'Name',
      filterName: 'name',
      cellClass: 'large',
      dataGetter: (data) => data.name,
    },
    {
      name: 'Kategorie',
      filterName: 'category',
      cellClass: 'large',
      dataGetter: (data) => data.category,
    },
    {
      name: 'Zugewiesene Standorte',
      filterName: 'locations.name',
      cellClass: 'large',
      multiText: 'Mehrere Standorte',
      dataHeaders: ['Standortnummer', 'Standortbezeichnung'],
      singleDataGetters: [(data) => data.id, (data) => data.name],
      dataGetter: (data) => {
        if (data.locations.length === 0) {
          return '';
        }

        return data.locations.length > 1 ? data.locations : data.locations[0].name;
      },
    },
  ];

  public editOrAddLinkGetter(rowData: any) {
    return `/booking-templates/booking-template-administration/${rowData.id}`;
  }

  public ngOnInit() {
    this.bookingTemplatesSubscription = this.bookingTemplates$.subscribe((bookingTemplates: IApiGetBookingTemplate[]) => {
      this.bookingTemplateIdsAndNames = bookingTemplates.map(({ id, name }) => ({
        id,
        name,
      }));
    });
  }

  public ngOnDestroy(): void {
    this.bookingTemplatesSubscription.unsubscribe();
  }
}

@Injectable()
export class TemplatesDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private templatesController: TemplatesController,
    private bookingTemplatesController: BookingTemplatesController,
    private locationsController: LocationsController,
  ) {}

  async resolve(): Promise<any> {
    await Promise.all([
      this.redux.dispatch(this.locationsController.updateActive()),
      this.redux.dispatch(this.bookingTemplatesController.updateNames()),
      this.redux.dispatch(this.templatesController.updateActive()),
    ]);

    return true;
  }
}
