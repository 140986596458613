import {Component, OnInit, Injectable, OnDestroy} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {Resolve} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import * as _ from 'lodash';

import {IAppState} from '../../../redux/store';
import {MediasController} from '../../../redux/actions/medias/medias.controller';
import {MediasSelector} from '../../../redux/reducers/medias.reducer';
import {IApiGetMedias} from '../../../../../../common/interfaces/medias';
import {AuthApiService} from '../../../services/api/auth.api.service';
import {full} from '../../../../../../common/routes';
import { MediasActionsCreator } from '../../../redux/actions/medias/medias.action';
import { WarningModalBoxComponent } from '../../ui-elements/modal-box/warning-modal-box/warning-modal-box.component';
import { IDeleteTexts, ModalService } from '../../../services/modal.service';
import { UsersSelector } from '../../../redux/reducers/users.reducer';
import { MediaUploadService } from '../../../services/media-upload.service';

@Component({
  selector: 'app-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.scss'],
})
export class MediasComponent implements OnInit, OnDestroy {

  public static ROUTE = 'medias';
  public orderItems = [];
  public model = {
    order: '-id',
    type: {
      image: true,
      video: true,
      pdf: true,
    },
    orientation: {
      landscape: true,
      portrait: true,
      square: true,
    },
  };
  public mediasListLength = 0;
  public checkedMediasCount = 0;
  public fileApiRoute = full.files;
  public deleteSingleTexts: IDeleteTexts = {
    delete: 'Datei aus Mediendatenbank löschen?',
    reallyDelete: 'Das Löschen der Datei kann nicht rückgängig gemacht werden!',
    noKeep: 'Nein, behalten.',
    yesDelete: 'Ja, Datei löschen.',
  };
  public deleteMultipleTexts: IDeleteTexts = {
    ...this.deleteSingleTexts,
    delete: 'Dateien aus Mediendatenbank löschen?',
  };
  public whenCheckboxChanged: Function = _.noop;
  public columns: object[];
  public pageLayout = 'thumbs';

  @select(MediasSelector.getAll) readonly mediasList$: Observable<IApiGetMedias[]>;
  @select(MediasSelector.getCheckedIds) readonly checkedMediasList$: Observable<number[]>;
  @select(MediasSelector.getPage) readonly activePage$: Observable<number>;
  @select(UsersSelector.getMe) user$: Observable<any>;
  private mediasListSubscription: Subscription;
  private checkedMediasListSubscription: Subscription;
  private activePageSubscription: Subscription;
  private mediasWrapper: HTMLElement;
  private canFetch = true;
  private activePage = 1;

  constructor(
    public crudController: MediasController,
    public authApiService: AuthApiService,
    public actionCreator: MediasActionsCreator,
    private redux: NgRedux<IAppState>,
    private modalService: ModalService,
    private uploadService: MediaUploadService,
  ) {
    this.orderItems = [{
      code: '-id',
      name: 'Neuste zuerst',
    }, {
      code: 'name',
      name: 'Alphabetisch',
    }];

    this.columns = [{
        name: 'thumbnail',
        cellClass: 'img',
        dataGetter: (data) => {
          const fileKey = _.get(data, 'file.key');

          return `thumb_${fileKey}.png`;
        },
    }, {
      name: 'name',
      cellClass: 'name',
      dataGetter: (data) => _.get(data, 'name'),
    }];

    this.onScroll = _.debounce(this.onScroll, 200).bind(this);
  }

  public onFiltersChange() {
    this.canFetch = true;
    this.redux.dispatch(this.actionCreator.changePage(1));
    this.redux.dispatch(this.crudController.updateCurrent());
  }

  public onOrderChange() {
    this.redux.dispatch(this.actionCreator.toggleSort(this.model.order));

    this.onFiltersChange();
  }

  public toggleFilter(type) {
    const [filterObject, filterItem] = type.split('.');

    if (filterItem) {
      this.model = {
        ...this.model,
        [filterObject]: {
          ...this.model[filterObject],
          [filterItem]: !this.model[filterObject][filterItem],
        },
      };
    } else {
      this.model = {
        ...this.model,
        [filterObject]: !this.model[filterObject],
      };
    }

    let filtersValue = this.model[filterObject];
    if (typeof filtersValue === 'object') {
      filtersValue = Object.entries(filtersValue)
        .map(([key, value]) => {
          if (value) {
            return key;
          }

          return null;
        })
        .filter(i => i);
    }

    this.redux.dispatch(this.actionCreator.setFilter(filtersValue, filterObject));

    this.onFiltersChange();
  }

  public onMediaRemove(id: number) {
    this.modalService.open(WarningModalBoxComponent, {
      isMobile: false,
      onSubmit: () => this.redux.dispatch(this.crudController.deleteData(id)),
      deleteTexts: this.deleteSingleTexts,
    });
  }

  public onCheckedMediasRemove() {
    const deleteTexts = this.checkedMediasCount > 1 ? this.deleteMultipleTexts : this.deleteSingleTexts;
    this.modalService.open(WarningModalBoxComponent, {
      isMobile: false,
      onSubmit: () => this.redux.dispatch(this.crudController.deleteChecked()),
      deleteTexts,
    });
  }

  public trackByFn(index, data) {
    if (!data) {
      return null;
    }

    return data.id;
  }

  private onScroll(event: Event) {
    const wrapperDimensions = this.mediasWrapper.getBoundingClientRect();
    const wrapperBottom = wrapperDimensions.bottom;
    const browserHeight = window.innerHeight;

    if (wrapperBottom <= browserHeight && this.canFetch) {
      this.canFetch = false;
      this.redux.dispatch(this.actionCreator.changePage(this.activePage + 1));
      this.redux.dispatch(this.crudController.getMore());
    }
  }

  onListElementReplace(data, elementId) {
    if (data.target.files && data.target.files.length) {
      const file = data.target.files[0];

      this.uploadService.updateFile(elementId, file);
    }
  }

  changeLayout(layout) {
    this.pageLayout = layout;
  }

  public ngOnInit() {
    this.mediasWrapper = document.querySelector('.medias-list-component');

    this.mediasListSubscription = this.mediasList$.subscribe((mediasList: IApiGetMedias[]) => {
      if (mediasList.length !== this.mediasListLength) {
        this.canFetch = true;
      }
      this.mediasListLength = mediasList.length;
    });
    this.checkedMediasListSubscription = this.checkedMediasList$.subscribe((checkedMedias: any) => {
      this.checkedMediasCount = checkedMedias.length;
    });

    this.activePageSubscription = this.activePage$.subscribe((activePage: any) => {
      this.activePage = activePage;
    });

    window.addEventListener('scroll', this.onScroll);
  }

  public ngOnDestroy(): void {
    this.mediasListSubscription.unsubscribe();
    this.checkedMediasListSubscription.unsubscribe();
    this.activePageSubscription.unsubscribe();

    window.removeEventListener('scroll', this.onScroll);
  }

}

@Injectable()
export class MediasDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private mediasController: MediasController,
  ) {
  }

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.mediasController.updateActive());
  }
}
