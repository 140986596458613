import { Injectable } from '@angular/core';

export interface UserTrackerConfig {
  options?: {
    trackEvents: boolean;
    trackClicks: boolean;
  };
}

export interface CurrentBooking {
  id: number;
  name: string;
  templateId: number;
}

interface WsCallbackData {
  type: 'log' | 'resetSession';
  appId: string;
  bookingId?: CurrentBooking['id'];
  language?: string;
  eventType?: string;
  contentSection?: string;
  eventValue?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserTrackerService {

  private options: UserTrackerConfig['options'] = {
    trackEvents: true,
    trackClicks: false,
  };
  private currentBooking: CurrentBooking | null = null;
  private wsSendCallback = (data: WsCallbackData) => {};

  public initialize(config: UserTrackerConfig = {}, wsSendCallback: (data: WsCallbackData) => void) {
    console.log('[UserTracker] User tracker has been initialized!');
    this.options = {
      ...this.options,
      ...config.options,
    };
    this.wsSendCallback = wsSendCallback;

    this.implementGlobalMethods();
  }

  public updateBooking(booking: CurrentBooking) {
    this.currentBooking = {
      ...booking,
    };
  }

  private implementGlobalMethods(): void {
    /* tslint:disable:no-string-literal */
    window['peSendEvent'] = this.sendEvent.bind(this);
    window['peResetSession'] = this.resetSession.bind(this);
    /* tslint:enable:no-string-literal */
  }

  private sendEvent(eventType: string, eventValue: string, contentSection?: string): void {
    /* tslint:disable:no-string-literal */
    const appId = this.sanitizeValue(window['__pe_AppId']);
    if (typeof appId === 'undefined') {
      throw new Error('APP ID is not defined in the booking template!');
    }

    if (
      typeof eventType !== 'string' ||
      typeof eventValue !== 'string'
    ) {
      throw new Error('Sent the tracking event has no all mandatory arguments.');
    }

    if (!this.currentBooking) {
      return;
    }

    this.wsSendCallback({
      appId,
      type: 'log',
      bookingId: this.currentBooking.id,
      language: this.sanitizeValue(window['__pe_AppLanguage'] || 'de'),
      eventType: this.sanitizeValue(eventType),
      contentSection: this.sanitizeValue(contentSection),
      eventValue: this.sanitizeValue(eventValue),
    });
    /* tslint:enable:no-string-literal */
  }

  private resetSession(): void {
    /* tslint:disable:no-string-literal */
    const appId = this.sanitizeValue(window['__pe_AppId']);
    /* tslint:enable:no-string-literal */
    if (typeof appId === 'undefined') {
      throw new Error('APP ID is not defined in the booking template!');
    }

    this.wsSendCallback({
      appId,
      type: 'resetSession',
    });
  }

  private sanitizeValue(value?: string): string | undefined {
    if (!value || typeof value !== 'string') {
      return undefined;
    }

    return value
      .replace(/\|/g, '') // remove unallowed pipe character
      .replace(/\;/g, '') // remove unallowed semicolon character
      .replace(/\s{2,}/g, ' ') // remove multiple spaces
      .trim(); // remove spaces at the beggining and at the end
  }
}
