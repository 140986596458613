import {
  Component,
  ChangeDetectionStrategy,
  Injectable,
  ViewEncapsulation,
} from '@angular/core';
import {
  Resolve,
} from '@angular/router';
import {
  NgRedux,
} from '@angular-redux/store';
import * as _ from 'lodash';
import * as moment from 'moment';

import { IAppState } from 'src/app/redux/store';
import { LocationsController } from 'src/app/redux/actions/locations/locations.controller';
import { SpecialRuntimesController } from 'src/app/redux/actions/special-runtimes/special-runtimes.controller';
import { SpecialRuntimesActionsCreator } from 'src/app/redux/actions/special-runtimes/special-runtimes.action';
import { IColumn } from '../table-page/table/table.component';
import {
  IStateSpecialRuntime,
  SpecialRuntimesSelector,
} from 'src/app/redux/reducers/special-runtimes.reducer';
import { IDeleteTexts } from 'src/app/services/modal.service';


@Component({
  selector: 'app-special-runtimes',
  template: `
    <app-table-page
      [name]="name"
      [title]="'Sonderlaufzeiten verwalten'"
      [subtitle]="'Verwalte einfach und übersichtlich die Sonderlaufzeiten Deiner Standorte.'"
      [useToggle]="false"
      [useActiveInactive]="true"
      [activeText]="'Aktuelle Sonderlaufzeiten'"
      [inactiveText]="'Archiv'"
      [isActiveSelector]="isActiveSelector"
      [useTime]="false"
      [useRunTime]="false"
      [addLink]="'/special-runtimes/special-runtime-administration'"
      [route]="route"
      [crudController]="crudController">
      <app-table
        [rowsCheckable]="false"
        [deleteTexts]="deleteTexts"
        [isToggleSwitched]="false"
        [name]="name"
        [editOrAddLinkGetter]="editOrAddLinkGetter"
        [columns]="columns"
        [getAllSelector]="getAll"
        [getAllIdsSelector]="getAllIds"
        [isAllCheckedSelector]="isAllChecked"
        [getFilterByNameSelector]="getFilterByName"
        [actionFactory]="actionFactory"
        [crudController]="crudController"
      >
      </app-table>
    </app-table-page>`,
  styleUrls: ['./special-runtimes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SpecialRuntimesComponent {

  public static ROUTE = 'special-runtimes';
  public name = 'specialRuntimes';
  public route: string;
  public getAll: ($state: IAppState) => IStateSpecialRuntime[];
  public getAllIds: ($state: IAppState) => string[];
  public isAllChecked: ($state: IAppState) => boolean;
  public getFilterByName: (name: string) => ($state: IAppState) => any;
  public isActiveSelector: ($state: IAppState) => boolean;

  constructor(
    public crudController: SpecialRuntimesController,
    public actionFactory: SpecialRuntimesActionsCreator,
  ) {
    this.route = SpecialRuntimesComponent.ROUTE;

    this.getAll = SpecialRuntimesSelector.getAll;
    this.getAllIds = SpecialRuntimesSelector.getAllIds;
    this.isAllChecked = SpecialRuntimesSelector.isAllChecked;
    this.getFilterByName = SpecialRuntimesSelector.getFilterByName;

    this.isActiveSelector = SpecialRuntimesSelector.isActive;
  }

  public deleteTexts: IDeleteTexts = {
    delete: 'Sonderlaufzeit löschen',
    reallyDelete: 'Möchtest Du die Sonderlaufzeit wirklich löschen?',
    noKeep: 'Nein, behalten.',
    yesDelete: 'Ja, löschen.',
  };

  public columns: IColumn[] = [
    {
      name: 'Bezeichnung',
      filterName: 'name',
      cellClass: 'large',
      dataGetter: (data) => data.name,
    },

    {
      name: 'Datum Beginn',
      filterName: 'start_date',
      cellClass: 'medium',
      dataGetter: (data) => data.runtimes.map(runtime => {
        if (data.yearly) {
          return `${moment(runtime.startDay, 'YYYY-MM-DD').format('DD.MM')} jährlich`;
        }

        return moment(runtime.startDay, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }),
      type: 'multiline',
    },
    {
      name: 'Datum Ende',
      filterName: 'end_date',
      cellClass: 'medium',
      dataGetter: (data) => data.runtimes.map(runtime => {
        if (data.yearly) {
          return `${moment(runtime.endDay, 'YYYY-MM-DD').format('DD.MM')} jährlich`;
        }

        return moment(runtime.endDay, 'YYYY-MM-DD').format('DD.MM.YYYY');
      }),
      type: 'multiline',
    },
    {
      name: 'Bildschirm Laufzeit',
      filterName: 'time',
      cellClass: 'medium',
      dataGetter: (data) => data.runtimes.map(runtime => `${runtime.startTime} - ${runtime.endTime} Uhr`),
      type: 'multiline',
    },
    {
      name: 'Standort',
      filterName: 'locations.name',
      cellClass: 'large',
      multiText: 'Mehrere Standorte',
      dataHeaders: ['Standortnummer', 'Standortbezeichnung'],
      singleDataGetters: _.compact([(data) => data.id, (data) => data.name]),
      dataGetter: (data) => {
        if (data.disabled && !_.find(data.locations, (location) => !location.id)) {
          return [...data.locations, {id: null, name: '+ Standorte ohne Zugriff'}];
        }

        return data.locations.length > 1 ? data.locations : data.locations[0].name;
      },
    },
    {
      name: 'Termintyp',
      filterName: 'yearly',
      cellClass: 'small',
      placeholder: 'Alle',
      getSearchOptions: () => [
        {
          id: 'false',
          name: 'einmalig',
        },
        {
          id: 'true',
          name: 'jährlich',
        },
      ],
      dataGetter: (data) =>
        `<span class="runtime-type${data.yearly ? ' runtime-type--yearly' : ''}">${data.yearly ? 'Serientermin' : 'Einmalig'}</span>`,
      type: 'html',
    },
  ];


  public editOrAddLinkGetter(rowData: any) {
    if (!rowData.disabled) {
      return `/${SpecialRuntimesComponent.ROUTE}/special-runtime-administration/${rowData.id}`;
    }
  }
}

@Injectable()
export class SpecialRuntimesDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private specialRuntimesController: SpecialRuntimesController,
    private locationsController: LocationsController,
  ) {}

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.locationsController.updateActive());
    await this.redux.dispatch(this.specialRuntimesController.updateActive());

    return true;
  }
}
