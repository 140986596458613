import {Injectable} from '@angular/core';
import { Dispatch } from 'redux';
import {IMedia, MediasSelector} from '../../reducers/medias.reducer';
import {AbstractCrudController} from '../abstractCrud.controller';
import {ISelectors} from '../../reducers/abstract.reducer';
import {MediasActionsCreator} from './medias.action';
import {MediasApiService} from '../../../services/api/medias.api.service';
import { IApiGetMedias } from '../../../../../../common/interfaces/medias';
import { IAppState } from '../../store';
import { IApiFilter } from '../../../../../../common/interfaces/api';
import { IN } from '../../../../../../common/constants';
import { ISetAction, ISetFilter } from '../abstractActionsCreator';

export interface IOrLocationFilters {
  width: string;
  height: string;
}

@Injectable()
export class MediasController extends AbstractCrudController<IMedia, IApiGetMedias> {

  constructor(apiService: MediasApiService, actionFactory: MediasActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IMedia> = MediasSelector;

  protected getApiAndFilters(isActive: boolean, getState: () => IAppState): IApiFilter[] {
    const andFilters: any = super.getFilters(getState) as any;
    const orientationValues = andFilters.orientation || [];
    const typeValues = andFilters.type || [];
    delete andFilters.orientation;
    delete andFilters.type;

    const apiAndFilters: IApiFilter[] = super.toApiFilters(andFilters);
    apiAndFilters.push({column: 'orientation', operator: IN, value: orientationValues.join(',') });
    apiAndFilters.push({column: 'type', operator: IN, value: typeValues.join(',') });

    return apiAndFilters;
  }

  public async update(dispatch: Dispatch, getState: () => IAppState, isActive: boolean): Promise<any> {
    const data = await this.apiService.getByFiltersAndSortingsWithPagination(
      this.getApiAndFilters(isActive, getState),
      this.getApiOrFilters(isActive, getState),
      this.getApiSortings(getState),
      this.getApiPage(getState),
    );
    const action: ISetAction<Partial<IApiGetMedias>> = this.actionFactory.set(data, isActive);
    return dispatch(action);
  }

  updateActive(): any {
    return (dispatch: Dispatch, getState: () => IAppState) => this.updateAndReset(dispatch, getState, true);
  }

  async updateAndReset(dispatch: Dispatch, getState: () => IAppState, isActive: boolean) {
    dispatch(this.actionFactory.resetChecked());
    dispatch(this.actionFactory.changePage(1));
    return await this.update(dispatch, getState, isActive);
  }

  updateFilterInstantly(filter: string, filterName?: string): any {
    return async (dispatch: Dispatch, getState: () => IAppState) => {
      const action: ISetFilter = this.actionFactory.setFilter(filter, filterName);
      dispatch(action);
      dispatch(this.actionFactory.changePage(1));

      await this.updateCurrent()(dispatch, getState);
    };
  }
}
