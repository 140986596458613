import {Injectable} from '@angular/core';

import {AbstractCrudController} from '../abstractCrud.controller';
import {ISelectors} from '../../reducers/abstract.reducer';
import {IRole} from '../../../../../../common/interfaces/role';
import {RolesSelector} from '../../reducers/roles.reducer';
import {RolesActionsCreator} from './roles.action';
import {RoleApiService} from '../../../services/api/role.api.service';

@Injectable()
export class RolesController extends AbstractCrudController<IRole, IRole> {

  constructor(apiService: RoleApiService, actionFactory: RolesActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IRole> = RolesSelector;

}
