import {Injectable} from '@angular/core';
import {AbstractActionsCreator, IAction} from '../abstractActionsCreator';
import {IApiGetBooking} from '../../../../../../common/interfaces/booking';

export const namespace = '@BOOKINGS/';

export type IBookingsAction = IAction<IApiGetBooking>;

@Injectable()
export class BookingsActionsCreator extends AbstractActionsCreator<IApiGetBooking> {
  private static instance: BookingsActionsCreator;
  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!BookingsActionsCreator.instance) {
      BookingsActionsCreator.instance = new BookingsActionsCreator();
    }

    return BookingsActionsCreator.instance;
  }
}
