import {Component, Input, OnInit} from '@angular/core';
import {IStateLocation} from '../../../redux/reducers/locations.reducer';
import * as _ from 'lodash';

@Component({
  selector: 'ba-playlist-locations',
  templateUrl: './playlist-locations.component.html',
  styleUrls: ['./playlist-locations.component.scss'],
})
export class BaPlaylistLocationsComponent implements OnInit {

  @Input() locations: IStateLocation[] = [];

  public locationsOpened = false;
  public headlineText: string;

  constructor() {
  }

  private isSingleLocation() {
    return this.locations.length <= 1;
  }

  ngOnInit() {
    this.headlineText = this.isSingleLocation()
      ? `${_.get(this.locations, '[0].id', '')} ${_.get(this.locations, '[0].name', '')}`
      : 'Buchung läuft auf mehreren Standorten';
  }

  openLocationList() {
    if (this.isSingleLocation()) {
      return;
    }

    this.locationsOpened = !this.locationsOpened;
  }

}
