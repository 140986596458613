import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'ba-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss'],
})
export class BaRadiobuttonComponent implements OnInit {

  @Input() radioButtonId: string;
  @Input() radioButtonGroup: string;
  @Input() isDisabled: boolean;
  @Input() isSelected: boolean;
  @Output() isRadioChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  radioSelected() {
    this.isRadioChanged.emit(this.isSelected);
  }

  ngOnInit() {
  }

}
