import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {full} from '../../../../../common/routes';
import {AbstractCrudApiService} from './abstract-crud.api.service';
import {AuthApiService} from './auth.api.service';
import {ApiService} from './api.service';

@Injectable()
export class QueueApiService extends AbstractCrudApiService {

  protected apiUrl: string = full.queue;

  constructor(http: HttpClient, authApi: AuthApiService, api: ApiService) {
    super(http, authApi, api);
  }

  public getBookingJob(id: string) {
    return this.api.get(full.queueBooking + id);
  }

}
