import * as _ from 'lodash';
import {AbstractReducer, IMetaState, IStateDataType} from './abstract.reducer';
import {ILocationGroupsAction, LocationGroupsActionsCreator} from '../actions/locations/location-groups.action';
import {IApiGetLocationGroup} from '../../../../../common/interfaces/locationGroup';
import {LOCATION_GROUPS} from '../../../../../common/constants';

export type ILocationGroupsState = IMetaState<IStateLocationGroups>;

export interface IStateLocationGroups extends IApiGetLocationGroup, IStateDataType {
}

export class LocationGroupsReducer extends AbstractReducer<IStateLocationGroups, ILocationGroupsAction> {
  private constructor() {
    super(LOCATION_GROUPS, LocationGroupsActionsCreator.getInstance());
  }

  private static instance: LocationGroupsReducer;

  public DEFAULT_STATE: IMetaState<IStateLocationGroups> = AbstractReducer.DEFAULT_STATE;

  public static getInstance() {
    if (!LocationGroupsReducer.instance) {
      LocationGroupsReducer.instance = new LocationGroupsReducer();
    }

    return LocationGroupsReducer.instance;
  }
}

export const LocationGroupsSelector = LocationGroupsReducer.getInstance().getSelectors();
