import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {
  IComponentToRender,
  DisplayMode,
} from './interfaces';
import Timer from './timer';

/**
 * This class is responsible for managing the currently displayed component
 */
@Injectable()
export default class ComponentManager {

  private currentTimer: Timer;
  private currentDoneCallback: Function = _.noop;
  private currentComponentToRender: IComponentToRender;

  public setComponent(component: IComponentToRender) {
    if (!component) {
      return;
    }

    this.currentComponentToRender = component;

    const booking = this.currentComponentToRender.booking;
    const fileKeys = JSON.stringify(_.map(booking.files, 'key'));

    console.debug(`component-manager - id: ${booking.id}`, `duration: ${booking.duration}`, `files: ${fileKeys}`);
  }

  public getBookingId() {
    return this.currentComponentToRender ? this.currentComponentToRender.booking.id : undefined;
  }

  public getBookingName() {
    return this.currentComponentToRender ? this.currentComponentToRender.booking.name : undefined;
  }

  public getBookingInputs() {
    return this.currentComponentToRender ? this.currentComponentToRender.booking.inputs : undefined;
  }

  public getBookingDuration() {
    return this.currentComponentToRender ? this.currentComponentToRender.booking.duration : undefined;
  }

  public getBookingFiles() {
    return this.currentComponentToRender ? this.currentComponentToRender.booking.files : undefined;
  }

  public getBookingBookingTemplate() {
    return this.currentComponentToRender ? this.currentComponentToRender.booking.booking_template : undefined;
  }

  public getHostView() {
    return this.currentComponentToRender ? this.currentComponentToRender.componentRef.hostView : undefined;
  }

  public getBookingCategoryId() {
    return this.currentComponentToRender ? this.currentComponentToRender.booking.category_id : null;
  }

  public isPriorityBooking() {
    return this.currentComponentToRender && this.currentComponentToRender.booking.display_mode === DisplayMode.Priority;
  }

  public isVisible() {
    return this.isVisibleByDateAndTime() && this.isVisibleByCode();
  }

  public isVisibleByDateAndTime() {
    return this.currentComponentToRender ? this.currentComponentToRender.isVisible() : false;
  }

  private isVisibleByCode() {
    return this.currentComponentToRender ? this.currentComponentToRender.componentRef.instance.isVisible() : false;
  }

  public pause() {
    if (!this.currentComponentToRender) {
      return;
    }

    if (this.currentTimer) {
      this.currentTimer.pause();
    }

    this.currentComponentToRender.componentRef.instance.pause();
  }

  public start() {
    if (!this.currentComponentToRender) {
      return;
    }

    if (this.currentTimer) {
      this.currentTimer.start();
    }

    this.currentComponentToRender.componentRef.instance.start();
  }

  public stop() {
    this.currentDoneCallback();
    this.currentDoneCallback = _.noop;

    if (this.currentTimer) {
      this.currentTimer.cancel();
      this.currentTimer = null;
    }

    if (_.has(this.currentComponentToRender, 'componentRef.instance')) {
      this.currentComponentToRender.componentRef.instance.stop();
    }

  }

  public getDonePromise() {
    if (!this.currentComponentToRender) {
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.currentDoneCallback = resolve;

      if (this.currentComponentToRender.componentRef.instance.onDone) {
        return this.currentComponentToRender.componentRef.instance.onDone(resolve);
      }

      // if the booking does not specify a "onDone" function, we will start a timer, which runs as long as the duration says
      this.currentTimer = new Timer(resolve, this.currentComponentToRender.componentRef.instance.duration);
    });
  }
}
