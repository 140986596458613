import * as _ from 'lodash';

export interface IBookingTemplateTypes {
  [key: string]: {
    defaultValue?: string | number | boolean;
    validate?: (data: any) => boolean;
  };
}

export const bookingTemplateTypes: IBookingTemplateTypes = {
  file: {validate: () => true},
  fileWithDuration: {validate: (data: number) => data >= 1 && data <= 86400, defaultValue: 10},
  text: {validate: (data: string) => data && data.length > 0, defaultValue: 'Beispieltext'},
  textarea: {validate: (data: string) => data && data.length > 0, defaultValue: 'Beispieltext'},
  colorpicker: {validate: (data: string) => data && data.length > 0, defaultValue: '#212121'},
  number: {validate: (data: number) => data > 0},
  checkbox: {defaultValue: false},
  // select:

};
