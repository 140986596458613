import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ALLOWED_TYPES} from '../../../../../../common/constants';
import * as _ from 'lodash';

@Component({
  selector: 'ba-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
})
export class BaDurationComponent implements OnInit, OnChanges {

  @Input() allowedMimeTypes: string[];
  @Input() file: any;
  @Input() isDisabled: boolean;
  @Input() data: any;
  @Input() showAlways: boolean;
  @Input() name: string;
  @Input() isRequired: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Output() dataChange: EventEmitter<any> = new EventEmitter();

  public isFileDuration: number;
  public isVisible: boolean;

  ngOnInit() {
    this.name = this.name || (this.file ? this.file.name : '');
    this.label = this.label || `Anzeigedauer in Sekunden${(this.isRequired) ? '*' : ''}`;
    this.allowedMimeTypes = this.allowedMimeTypes || ALLOWED_TYPES;

    if (this.file) {
      this.isFileDuration = this.data;
    }

    this.setIsVisible();
  }

  ngOnChanges() {
    this.setIsVisible();
  }

  onNgModelChange(data: any) {
    this.dataChange.emit(data);
  }

  private setIsVisible() {
    if (this.showAlways) {
      this.isVisible = true;
    } else if (this.file) {
      this.isVisible = _.includes(this.allowedMimeTypes, this.file.mimetype);
    } else {
      this.isVisible = false;
    }
  }
}
