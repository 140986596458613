import {Component, OnInit, Input, OnChanges} from '@angular/core';
import {AbstractActionsCreator} from '../../../redux/actions/abstractActionsCreator';
import {NONE} from '../../../../../../common/constants';
import * as _ from 'lodash';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent<dataType> implements OnInit, OnChanges {

  public isSelected = false;

  @Input() id = NONE;
  @Input() selection = false;
  @Input() editable = false;
  @Input() isChecked = false;
  @Input() actionFactory: AbstractActionsCreator<dataType>;
  @Input() whenCheckboxChanged: Function = _.noop;
  @Input() fixedHeight = false;

  @Input() name = 'name';
  @Input() category = 'category';
  @Input() imgSrc = 'assets/images/temp-img.jpg';
  @Input() routeLink: string;

  constructor() {
  }

  ngOnInit() {
  }
  ngOnChanges() {
    this.isSelected = this.isChecked;
  }

}
