import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSizeFormat',
})
export class FileSizeFormatPipe implements PipeTransform {
  transform(bytes: number = 0, decimals: number = 2): string {
    if (!bytes) { return '0 Bytes'; }

    const K = 1024;
    const SIZES = ['Bytes', 'KB', 'MB', 'GB'];
    const I = Math.floor(Math.log(bytes) / Math.log(K));

    return `${parseFloat((bytes / Math.pow(K, I)).toFixed(decimals))} ${SIZES[I]}`;
  }
}
