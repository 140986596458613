import {Injectable} from '@angular/core';
import {LocationApiService} from '../../../services/api/location.api.service';
import {IAppState} from '../../store';
import {IStateLocation, ILocationFilters, LocationsSelector} from '../../reducers/locations.reducer';
import {LocationsActionsCreator} from './locations.action';
import {AbstractCrudController} from '../abstractCrud.controller';
import {IApiFilter, IApiSorting} from '../../../../../../common/interfaces/api';
import {ISelectors} from '../../reducers/abstract.reducer';
import {ACTIVE, EQ} from '../../../../../../common/constants';
import {IApiGetLocation} from '../../../../../../common/interfaces/location';

export interface IOrLocationFilters {
  width: string;
  height: string;
}

@Injectable()
export class LocationsController extends AbstractCrudController<IStateLocation, IApiGetLocation> {

  constructor(apiService: LocationApiService, actionFactory: LocationsActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IStateLocation> = LocationsSelector;

  protected getApiOrFilters(isActive: boolean, getState: () => IAppState) {
    const andFilters: ILocationFilters = super.getFilters(getState) as ILocationFilters;
    const orFilters: IOrLocationFilters = {width: andFilters.resolution || '', height: andFilters.resolution || ''};
    return super.toApiFilters(orFilters);
  }

  protected getApiAndFilters(isActive: boolean, getState: () => IAppState): IApiFilter[] {
    const andFilters: ILocationFilters = super.getFilters(getState) as ILocationFilters;
    delete andFilters.resolution;

    const apiAndFilters: IApiFilter[] = super.toApiFilters(andFilters);
    apiAndFilters.push({column: ACTIVE, operator: EQ, value: isActive ? 'true' : 'false'});

    return apiAndFilters;
  }

  protected getApiSortings(getState: () => IAppState): IApiSorting[] {
    const sortings = this.getSortings(getState);

    if (sortings.resolution) {
      sortings.width = sortings.resolution;
      sortings.height = sortings.resolution;
      delete sortings.resolution;
    }

    return this.toSortings(sortings);
  }
}
