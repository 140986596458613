import { Injectable } from '@angular/core';
import {
  AbstractActionsCreator,
  IAction,
} from '../abstractActionsCreator';
import { ISettingsObject } from '../../../../../../common/interfaces/settings';

export const namespace = '@SETTINGS/';
export type ISettingsApisAction = IAction<ISettingsObject>;

@Injectable()
export class SettingsActionsCreator extends AbstractActionsCreator<ISettingsObject> {
  private static instance: SettingsActionsCreator;

  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!SettingsActionsCreator.instance) {
      SettingsActionsCreator.instance = new SettingsActionsCreator();
    }

    return SettingsActionsCreator.instance;
  }
}
