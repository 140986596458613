import * as _ from 'lodash';
import {AbstractReducer, IMetaState, IStateDataType} from './abstract.reducer';
import {IMediasAction, MediasActionsCreator} from '../actions/medias/medias.action';
import {MEDIAS} from '../../../../../common/constants';
import {IApiGetMedias} from '../../../../../common/interfaces/medias';

export type IMediasState = IMetaState<IMedia>;

export interface IMedia extends IApiGetMedias, IStateDataType {
}

export interface IMediasFilters {
  name: string;
  orientation: string[];
  type: string[];
}

export class MediasReducer extends AbstractReducer<IMedia, IMediasAction> {
  private constructor() {
    super(MEDIAS, MediasActionsCreator.getInstance());
  }

  private static instance: MediasReducer;

  public DEFAULT_STATE: IMetaState<IMedia> = _.assign({}, AbstractReducer.DEFAULT_STATE, {
    filters: {
      name: '',
      orientation: [
        'landscape',
        'portrait',
        'square',
      ],
      type: [
        'video',
        'image',
        'pdf',
      ],
    } as IMediasFilters,
    sortings: {id: 'DESC'},
    page: 1,
  });

  public static getInstance() {
    if (!MediasReducer.instance) {
      MediasReducer.instance = new MediasReducer();
    }

    return MediasReducer.instance;
  }
}

export const MediasSelector = MediasReducer.getInstance().getSelectors();
