import {
  AfterViewChecked,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  NgRedux,
  select,
} from '@angular-redux/store';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  Subject,
  Observable,
} from 'rxjs';
import {
  flatMap,
  take,
  tap,
  takeUntil,
} from 'rxjs/operators';

import { AdministrationPageComponent } from '../../administration-page/administration-page.component';
import { IAppState } from 'src/app/redux/store';
import { LocationsActionsCreator } from 'src/app/redux/actions/locations/locations.action';
import { TemplatesController } from 'src/app/redux/actions/templates/templates.controller';
import { BookingTemplateApiService } from 'src/app/services/api/booking-template.api.service';
import { ModalService } from 'src/app/services/modal.service';
import { DuplicateTrackingService } from 'src/app/services/duplicate-tracking-service';
import { TemplatesComponent } from '../templates.component';
import { TemplatesSelector } from 'src/app/redux/reducers/templates.reducer';
import { LocationGroupsSelector } from 'src/app/redux/reducers/location-groups.reducer';
import { IApiGetLocationGroup } from '../../../../../../../common/interfaces/locationGroup';
import { IApiGetBookingTemplate } from '../../../../../../../common/interfaces/booking';
import { LocationsSelector } from 'src/app/redux/reducers/locations.reducer';
import { LocationId } from '../../../../../../../common/interfaces/location';
import { Omit } from '../../../../../../../common/interfaces/util';

type Model = Omit<IApiGetBookingTemplate, 'locations'> & { locations: Array<number>};
type TemplateForm = Record<keyof Model, any>;

@Component({
  selector: 'app-templates-administration',
  templateUrl: './templates-administration.component.html',
  styleUrls: ['./templates-administration.component.scss'],
})
export class TemplatesAdministrationComponent extends AdministrationPageComponent implements OnInit, OnDestroy, AfterViewChecked {

  public static ROUTE = 'booking-template-administration';

  @select(LocationsSelector.getCheckedIds) public selectedLocations$: Observable<Array<LocationId>>;
  @select(LocationGroupsSelector.getAll) locationGroupList$: Observable<Array<IApiGetLocationGroup>>;

  public model: Partial<Model> = {
    name: '',
    category: '',
    locations: [],
  };
  public rawModel: Partial<Model> = { ...this.model };
  public form: FormGroup = this.fb.group(<TemplateForm>{
    name: '',
    category: '',
    locations: ['', Validators.required],
  });

  private ngDestroy$ = new Subject<void>();
  private viewChecked$ = new Subject<void>();

  protected onCloseRoute = TemplatesComponent.ROUTE;
  protected validators = [
    {
      validate: () => LocationsSelector.getCheckedIds(this.redux.getState()).length > 0,
      errorMessage: 'Bitte wählen Sie mindestens 1 Standort aus.',
    },
  ];


  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected redux: NgRedux<IAppState>,
    private locationsActionsCreator: LocationsActionsCreator,
    private route: ActivatedRoute,
    protected router: Router,
    protected controller: TemplatesController,
    protected apiService: BookingTemplateApiService,
    protected toastrService: ToastrService,
    protected modalService: ModalService,
    protected duplicateTrackingService: DuplicateTrackingService,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.selectedLocations$.pipe(
      tap(this.onLocationsChange.bind(this)),
      takeUntil(this.ngDestroy$),
    ).subscribe();

    this.form.valueChanges.pipe(
      tap(() => this.wasFormModified = true),
      takeUntil(this.ngDestroy$),
    ).subscribe();

    this.viewChecked$.pipe(
      take(1),
      tap(() => this.checkSelectedLocations()),
      takeUntil(this.ngDestroy$),
    ).subscribe();
  }

  public ngAfterViewChecked() {
    this.viewChecked$.next();
  }

  ngOnDestroy() {
    super.ngOnDestroy();

    this.redux.dispatch(this.locationsActionsCreator.resetChecked());
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  protected isModelValid() {
    return this.form.valid;
  }

  protected getParamsSub() {
    return this.route.params
      .pipe(
        flatMap(({id}) => {
          this.id = id;

          return this.redux.select(TemplatesSelector.getById(id));
        }),
        tap(this.initTemplate.bind(this)),
      )
      .subscribe();
  }

  private initTemplate(bookingTemplate: IApiGetBookingTemplate) {
    this.model = {
      category: bookingTemplate.category,
      name: bookingTemplate.name,
      locations: bookingTemplate.locations.map(location => location.id),
    };
    this.rawModel = {
      ...this.model,
    };

    this.form.patchValue(this.model);
  }

  private checkSelectedLocations() {
    setTimeout(() => {
      this.rawModel.locations.forEach((locationId) => {
        this.redux.dispatch(this.locationsActionsCreator.setChecked(locationId, true));
      });
    });
  }

  private onLocationsChange(locationsIds: Array<LocationId>) {
    this.model.locations = [ ...locationsIds ];
    this.form.get('locations').setValue(locationsIds.join(','));
  }
}
