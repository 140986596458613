import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { getWeekDaysDe } from '../../util/helpers';

@Component({
  selector: 'ba-playlist-weekdays',
  templateUrl: './playlist-weekdays.component.html',
  styleUrls: ['./playlist-weekdays.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistWeekdaysComponent {
  @Input() weekDays: Array<string> = null;

  public get days(): string {
    if (this.weekDays) {
      const weekDaysDe = getWeekDaysDe(this.weekDays);

      return weekDaysDe.join(', ');
    }

    return 'täglich';
  }
}
