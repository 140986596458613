import {DOCUMENT} from '@angular/common';
import {Component, Inject, Injectable, OnDestroy, OnInit, Renderer2} from '@angular/core';
import * as _ from 'lodash';
import {LocationsSelector} from '../../../../redux/reducers/locations.reducer';
import {NgRedux} from '@angular-redux/store';
import {flatMap} from 'rxjs/operators';
import {IAppState} from '../../../../redux/store';
import {ActivatedRoute, Resolve, Router} from '@angular/router';
import {IStateUser, UsersSelector} from '../../../../redux/reducers/users.reducer';
import {EMAIL_REGEX, ID} from '../../../../../../../common/constants';
import {LocationsActionsCreator} from '../../../../redux/actions/locations/locations.action';
import {BookingTemplatesActionsCreator} from '../../../../redux/actions/booking-templates/booking-templates.action';
import {BookingTemplatesSelector} from '../../../../redux/reducers/booking-templates.reducer';
import {ToastrService} from 'ngx-toastr';
import {UserApiService} from '../../../../services/api/user.api.service';
import {UsersController} from '../../../../redux/actions/users/users.controller';
import {RoleApiService} from '../../../../services/api/role.api.service';
import {ModalService} from '../../../../services/modal.service';
import {AdministrationPageComponent} from '../../administration-page/administration-page.component';
import {UsersComponent} from '../users.component';
import {DuplicateTrackingService} from '../../../../services/duplicate-tracking-service';
import {
  WarningDiscardModalBoxComponent,
} from 'src/app/components/ui-elements/modal-box/warning-discard-modal-box/warning-discard-modal-box.component';

@Component({
  selector: 'app-users-administration',
  templateUrl: './users-administration.component.html',
  styleUrls: ['./users-administration.component.scss'],
})
export class UsersAdministrationComponent extends AdministrationPageComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected redux: NgRedux<IAppState>,
    private route: ActivatedRoute,
    protected router: Router,
    private locationsActionCreator: LocationsActionsCreator,
    private bookingTemplatesActionCreator: BookingTemplatesActionsCreator,
    protected controller: UsersController,
    protected apiService: UserApiService,
    protected toastrService: ToastrService,
    protected modalService: ModalService,
    protected duplicateTrackingService: DuplicateTrackingService,
  ) {
    super();
  }

  public static ROUTE = 'user-administration';

  protected successMessage = 'Der Benutzer wurde erfolgreich angelegt/bearbeitet.';
  protected onCloseRoute = UsersComponent.ROUTE;

  public model = {
    name: '',
    role: '',
    email: '',
    password: '',
    locationIds: [],
    bookingTemplateIds: [],
  };

  protected validators = [
    {
      validate: () => _.includes(this.model.name, ' '),
      errorMessage: 'Bitte geben Sie einen vollständigen Namen ein.',
    },
    {
      validate: () => LocationsSelector.getCheckedIds(this.redux.getState()).length > 0,
      errorMessage: 'Bitte wählen Sie mindestens 1 Standort aus.',
    },
    {
      validate: () => this.model.password === this.passwordRepeat,
      errorMessage: 'Die Wiederholung des Passwords ist nicht korrekt.',
    },
    {
      validate: () => this.model.password ? this.model.password.length >= 8 : true,
      errorMessage: 'Das Passwort muss mindestens 8 Zeichen lang sein.',
    },
  ];

  public roles: any[];

  public passwordRepeat = '';

  public isLocationsHidden = false;

  public toggleHideLocations() {
    this.isLocationsHidden = !this.isLocationsHidden;
  }

  protected isModelValid() {
    return !!(this.model.name && this.model.role && EMAIL_REGEX.test(this.model.email));
  }

  public ngOnInit() {
    this.roles = _.get(this.route, 'snapshot.data.roles', []);

    super.ngOnInit();
  }

  private initUser(user?: IStateUser) {
    if (!user) {
      return;
    }

    this.model.name = user.name;

    _.each(user.locations, ({id}) => this.redux.dispatch(this.locationsActionCreator.setChecked(id, true)));
    _.each(user.booking_templates, ({id}) => this.redux.dispatch(this.bookingTemplatesActionCreator.setChecked(id, true)));

    this.model.email = user.email;
    this.model.role = _.get(_.find(this.roles, {name: user.role} as any), ID, '');
  }

  protected getParamsSub() {
    return this.route.params
      .pipe(flatMap(({id}) => {
        this.id = id;
        return this.redux.select(UsersSelector.getById(id));
      }))
      .subscribe(this.initUser.bind(this));
  }

  protected async prepareModel() {
    this.model.password = this.model.password ? this.model.password : undefined;
    this.model.locationIds = LocationsSelector.getCheckedIds(this.redux.getState());
    this.model.bookingTemplateIds = BookingTemplatesSelector.getCheckedIds(this.redux.getState());
  }

  public async onUserSubmit() {
    this.isInvalidForm = false;

    if (!this.isModelValid()) {
      this.toastrService.error('', 'Felder mit * sind Pflichtfelder.');
      this.isInvalidForm = true;
    }

    _.each(this.validators, (validator) => {
      if (!validator.validate()) {
        this.toastrService.error('', validator.errorMessage);
        this.isInvalidForm = true;
      }
    });

    if (this.isInvalidForm) {
      return;
    }

    if (BookingTemplatesSelector.getCheckedIds(this.redux.getState()).length === 0) {
      this.modalService.open(
        WarningDiscardModalBoxComponent,
        {
          isMobile: false,
          onSubmit: async () => await this.onSubmit(),
          deleteTexts: {
            delete: 'Benutzer ohne Vorlage anlegen?',
            reallyDelete: 'Möchtest Du den Benutzer anlegen, ohne ihm eine Vorlage zuzuweisen?',
            noKeep: 'Abbrechen.',
            yesDelete: 'Ja, ohne Vorlage anlegen.',
          },
        },
      );
    } else {
      await this.onSubmit();
    }
  }
}

@Injectable()
export class UsersAdministrationDataResolver implements Resolve<any> {
  constructor(private roleApiService: RoleApiService) {
  }

  async resolve(): Promise<any> {
    return await this.roleApiService.getAll();
  }
}
