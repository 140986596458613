import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
/****** ROUTING *************************************************************/
import {AppRoutingModule} from './app-routing.module';
import {AuthGuard} from './auth-handling/auth.guard';
import {NgReduxRouter, NgReduxRouterModule} from '@angular-redux/router';
/****** REDUX **************************************************************/
import {DevToolsExtension, NgRedux, NgReduxModule} from '@angular-redux/store';
import thunk from 'redux-thunk';
import {DEFAULT_STATE, IAppState, rootReducer} from './redux/store';
import {LocationsController} from './redux/actions/locations/locations.controller';
import {BookingTemplatesController} from './redux/actions/booking-templates/booking-templates.controller';
import {LocationsActionsCreator} from './redux/actions/locations/locations.action';
import {BookingTemplatesActionsCreator} from './redux/actions/booking-templates/booking-templates.action';
import {MediasActionsCreator} from './redux/actions/medias/medias.action';
import { SettingsActionsCreator } from './redux/actions/settings/settings.action';
import {LocationGroupsActionsCreator} from './redux/actions/locations/location-groups.action';
import { CategoriesActionsCreator } from './redux/actions/categories/categories.action';
import { TemplatesActionsCreator } from './redux/actions/templates/templates.action';
import { SpecialRuntimesActionsCreator } from './redux/actions/special-runtimes/special-runtimes.action';
/****** SERVICES **********************************************************/
import {QueueApiService} from './services/api/queue.api.service';
import {ApiService} from './services/api/api.service';
import {AuthApiService} from './services/api/auth.api.service';
import {DuplicateTrackingService} from './services/duplicate-tracking-service';
import {BookingApiService} from './services/api/booking.api.service';
import {BookingTemplateApiService} from './services/api/booking-template.api.service';
import {FileApiService} from './services/api/file.api.service';
import {GlobalErrorHandler} from './error-handling/global-error.handler';
import {LocationApiService} from './services/api/location.api.service';
import {LocationGroupsService} from './services/location-groups.service';
import {ModalService} from './services/modal.service';
import {RoleApiService} from './services/api/role.api.service';
import {TrackByService} from './services/track-by.service';
import {UserApiService} from './services/api/user.api.service';
import {AuthInterceptor} from './auth-handling/auth.interceptor';
import {MediasApiService} from './services/api/medias.api.service';
import { ExternalResourceApiService } from './services/api/external-resource.api.service';
import { SettingsApiService } from './services/api/settings.api.service';
import { CategoryApiService } from './services/api/category.api.service';
import { SpecialRuntimesApiService } from './services/api/special-runtimes.api.service';
/****** PIPES ***************************************************************/
import {SecurePipe} from './auth-handling/secure.pipe';
import {FileSizeFormatPipe} from './pipes/fileSize.pipe';
import {FilterPipe} from './pipes/filter.pipe';
/****** CONTROLLER **********************************************************/
import {BookingsController} from './redux/actions/bookings/bookings.controller';
import {RolesController} from './redux/actions/roles/roles.controller';
import {UsersController} from './redux/actions/users/users.controller';
import {MediasController} from './redux/actions/medias/medias.controller';
import { ExternalResourcesController } from './redux/actions/external-resources/external-resources.controller';
import { SettingsController } from './redux/actions/settings/settings.controller';
import { CategoriesController } from './redux/actions/categories/categories.controller';
import { TemplatesController } from './redux/actions/templates/templates.controller';
import { SpecialRuntimesController } from './redux/actions/special-runtimes/special-runtimes.controller';
/****** ADD-ONs ************************************************************/
import {AngularSvgIconModule} from 'angular-svg-icon';
import {ColorPickerModule} from 'ngx-color-picker';
import {FileUploadModule} from 'ng2-file-upload';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {ToastrModule} from 'ngx-toastr';
import {ngfModule} from 'angular-file';
import { FlexLayoutModule } from '@angular/flex-layout';
/****** Utilities ************************************************************/
import {Runtimes} from './components/util/runtimes';
/****** UI-COMPONENTS *******************************************************/
import {CheckboxSmartComponent} from './components/ui-elements/checkbox/checkbox.smart.component';
import {BaCheckboxComponent} from './components/ui-elements/checkbox/checkbox.component';
import {BaColorPickerComponent} from './components/ui-elements/color-picker/color-picker.component';
import {BaDateTimePickerComponent} from './components/ui-elements/date-time-picker/date-time-picker.component';
import {BaDurationComponent} from './components/ui-elements/duration/duration.component';
import {BaExtendedCheckboxComponent} from './components/ui-elements/extended-checkbox/extended-checkbox.component';
import {BaFileUploadComponent} from './components/ui-elements/file-upload/file-upload.component';
import {BaTextInputComponent} from './components/ui-elements/text-input/text-input.component';
import {DateAndTimeModalBoxComponent} from './components/ui-elements/modal-box/date-and-time-modal-box/date-and-time-modal-box.component';
import {LocationRuntimeModalBoxComponent} from './components/ui-elements/modal-box/location-runtime-modal-box/location-runtime-modal-box.component';
import {UploadModalBoxComponent} from './components/ui-elements/modal-box/upload-modal-box/upload-modal-box.component';
import {WarningDiscardModalBoxComponent} from './components/ui-elements/modal-box/warning-discard-modal-box/warning-discard-modal-box.component';
import {WarningModalBoxComponent} from './components/ui-elements/modal-box/warning-modal-box/warning-modal-box.component';
import {BaNavbarComponent} from './components/ui-elements/navbar/navbar.component';
import {BaOffCanvasComponent} from './components/ui-elements/off-canvas/off-canvas.component';
import {BaPlaylistLocationsComponent} from './components/ui-elements/playlist-locations/playlist-locations.component';
import {BaRadiobuttonComponent} from './components/ui-elements/radiobutton/radiobutton.component';
import {BaRuntimeComponent} from './components/ui-elements/runtime/runtime.component';
import {BaAddRuntimeComponent} from './components/ui-elements/add-runtime/add-runtime.component';
import {BaSearchComponent} from './components/ui-elements/search/search.smart.component';
import {BaSelectComponent} from './components/ui-elements/select/select.component';
import {BaSwitchToggleComponent} from './components/ui-elements/switch-toggle/switch-toggle.component';
import {BaTextAreaComponent} from './components/ui-elements/text-area/text-area.component';
import {BaMultiselectComponent} from './components/ui-elements/multiselect/multiselect.component';
import {BaSearchFilterComponent} from './components/ui-elements/search-filter/search-filter.component';
import { CategoriesFormComponent } from './components/ui-elements/categories-form/categories-form.component';
import { CategoryInputComponent } from './components/ui-elements/category-input/category-input.component';
import { StatusBadgeComponent } from './components/ui-elements/status-badge/status-badge.component';
/****** COMPONENTs *********************************************************/
import {AppRootComponent} from './components/core/root/root.component';
import {NotFoundComponent} from './components/core/not-found/not-found.component';
import {LoginComponent} from './components/core/login/login.component';
import {DashboardComponent} from './components/core/dashboard/dashboard.component';
import {BookingsComponent} from './components/core/bookings/bookings.component';
import {BookingTemplatesComponent} from './components/core/bookings/booking-templates/booking-templates.component';
import {BookingAdministrationComponent} from './components/core/bookings/booking-administration/booking-administration.component';
import {LocationsComponent} from './components/core/locations/locations.component';
import {LocationsAdministrationComponent} from './components/core/locations/locations-administration/locations-administration.component';
import {PlaylistsComponent} from './components/core/playlists/playlists.component';
import {PlaylistAdministrationComponent} from './components/core/playlists/playlist-administration/playlist-administration.component';
import {PreviewsComponent} from './components/core/previews/previews.component';
import {PreviewLocationComponent} from './components/core/previews/preview-location/preview-location.component';
import {UsersComponent} from './components/core/users/users.component';
import {UsersAdministrationComponent} from './components/core/users/users-administration/users-administration.component';
import {RolesRightComponent} from './components/core/roles-right/roles-right.component';
import {RolesRightAdministrationComponent} from './components/core/roles-right/roles-right-administration/roles-right-administration.component';
import {TablePageComponent} from './components/core/table-page/table-page.component';
import {TableComponent} from './components/core/table-page/table/table.component';
import {RowComponent} from './components/core/table-page/table/row/row.component';
import {RowShuffleComponent} from './components/ui-elements/shuffle-icon/row-shuffle.component';
import {BookingsTableComponent} from './components/core/bookings/bookings-table.component';
import {LocationsTableComponent} from './components/core/locations/locations-table.component';
import {TemplateComponent} from './components/core/template-component/template.component';
import {BookingTemplatesListComponent} from './components/core/bookings/booking-templates/booking-templates-list/booking-templates-list.component';
import BookingRenderer from './renderer/booking-renderer';
import { KeyboardRenderer } from './renderer/keyboard-renderer';
import {BookingsActionsCreator} from './redux/actions/bookings/bookings.action';
import ComponentCreator from './renderer/component-creator';
import {RolesActionsCreator} from './redux/actions/roles/roles.action';
import {UsersActionsCreator} from './redux/actions/users/users.action';
import PlayerManager from './renderer/player-manager';
import ContentContainerManager from './renderer/content-container-manager';
import ComponentManager from './renderer/component-manager';
import {FullPreviewComponent} from './components/core/previews/full-preview/full-preview.component';
import {MediasComponent} from './components/core/medias/medias.component';
import { CategoriesComponent } from './components/core/categories/categories.component';
import {OneWeekApiService} from './services/api/one-week-api.service';
// tslint:disable-next-line:max-line-length
import {PlaylistChangeToRandomModalBoxComponent} from './components/ui-elements/modal-box/playlist-change-to-random-modal-box/playlist-change-to-random-modal-box.component';
// tslint:disable-next-line:max-line-length
import {PlaylistChangeToCustomModalBoxComponent} from './components/ui-elements/modal-box/playlist-change-to-custom-modal-box/playlist-change-to-custom-modal-box.component';
// tslint:disable-next-line:max-line-length
import {LocationGroupAddModalBoxComponent} from './components/ui-elements/modal-box/location-group-add-modal-box/location-group-add-modal-box.component';
// tslint:disable-next-line:max-line-length
import {LocationGroupDeleteModalBoxComponent} from './components/ui-elements/modal-box/location-group-delete-modal-box/location-group-delete-modal-box.component';
import {MediasSidebarComponent} from './components/ui-elements/medias-sidebar/medias-sidebar.component';
import {MediaUploadComponent} from './components/ui-elements/media-upload/media-upload.component';
import { MediasUploadListComponent } from './components/ui-elements/medias-upload-list/medias-upload-list.component';
import { MediaUploadElementComponent } from './components/ui-elements/media-upload-element/media-upload-element.component';
import { ProgressBarComponent } from './components/ui-elements/progress-bar/progress-bar.component';
import { ReplaceMediaComponent } from './components/ui-elements/replace-media/replace-media.component';
import { MediaSidebarItemComponent } from './components/ui-elements/media-sidebar-item/media-sidebar-item.component';
import {BookingsProcessingActionsCreator} from './redux/actions/bookings/bookings-processing.action';
import { ExternalResourcesComponent } from './components/core/external-resources/external-resources.component';
// tslint:disable-next-line:max-line-length
import { ExternalResourcesAdministrationComponent } from './components/core/external-resources/external-resources-administration/external-resources-administration.component';
import { ExternalResourcesActionsCreator } from './redux/actions/external-resources/external-resources.action';
import { SettingsComponent } from './components/core/settings/settings.component';
import { TemplatesComponent } from './components/core/templates/templates.component';
import { TemplatesAdministrationComponent } from './components/core/templates/templates-administration/templates-administration.component';
import { SpecialRuntimesComponent } from './components/core/special-runtimes/special-runtimes.component';
// tslint:disable-next-line:max-line-length
import { SpecialRuntimesAdministrationComponent } from './components/core/special-runtimes/special-runtimes-administration/special-runtimes-administration.component';
import { PlaylistWeekdaysComponent } from './components/ui-elements/playlist-weekdays/playlist-weekdays.component';

import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    SecurePipe,
    AppRootComponent,
    NotFoundComponent,
    CheckboxSmartComponent,
    BaCheckboxComponent,
    BaColorPickerComponent,
    BaDateTimePickerComponent,
    BaDurationComponent,
    BaExtendedCheckboxComponent,
    BaFileUploadComponent,
    DateAndTimeModalBoxComponent,
    LocationRuntimeModalBoxComponent,
    UploadModalBoxComponent,
    WarningDiscardModalBoxComponent,
    WarningModalBoxComponent,
    PlaylistChangeToRandomModalBoxComponent,
    PlaylistChangeToCustomModalBoxComponent,
    LocationGroupAddModalBoxComponent,
    LocationGroupDeleteModalBoxComponent,
    BaNavbarComponent,
    BaOffCanvasComponent,
    BaPlaylistLocationsComponent,
    BaRadiobuttonComponent,
    BaRuntimeComponent,
    BaAddRuntimeComponent,
    BaSearchComponent,
    BaSelectComponent,
    BaSwitchToggleComponent,
    BaTextAreaComponent,
    BaTextInputComponent,
    BaMultiselectComponent,
    BaSearchFilterComponent,
    LoginComponent,
    DashboardComponent,
    BookingsComponent,
    BookingTemplatesComponent,
    BookingAdministrationComponent,
    LocationsComponent,
    LocationsAdministrationComponent,
    PlaylistsComponent,
    PlaylistAdministrationComponent,
    PreviewsComponent,
    FullPreviewComponent,
    PreviewLocationComponent,
    UsersComponent,
    UsersAdministrationComponent,
    TablePageComponent,
    TableComponent,
    RowComponent,
    LocationsTableComponent,
    BookingsTableComponent,
    TemplateComponent,
    RowShuffleComponent,
    RolesRightComponent,
    RolesRightAdministrationComponent,
    BookingTemplatesListComponent,
    MediasComponent,
    MediasSidebarComponent,
    MediaUploadComponent,
    MediasUploadListComponent,
    MediaUploadElementComponent,
    ProgressBarComponent,
    FileSizeFormatPipe,
    ReplaceMediaComponent,
    MediaSidebarItemComponent,
    FilterPipe,
    ExternalResourcesComponent,
    ExternalResourcesAdministrationComponent,
    SettingsComponent,
    CategoriesComponent,
    CategoriesFormComponent,
    CategoryInputComponent,
    SpecialRuntimesComponent,
    SpecialRuntimesAdministrationComponent,
    StatusBadgeComponent,
    TemplatesComponent,
    TemplatesAdministrationComponent,
    PlaylistWeekdaysComponent,
  ],
  imports: [
    AppRoutingModule,
    FileUploadModule,
    NgReduxRouterModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgReduxModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ToastrModule.forRoot({positionClass: 'toast-bottom-right', newestOnTop: false}),
    AngularSvgIconModule,
    ColorPickerModule,
    ngfModule,
    DragDropModule,
    SharedModule,
    FlexLayoutModule,
  ],
  entryComponents: [
    DateAndTimeModalBoxComponent,
    LocationRuntimeModalBoxComponent,
    UploadModalBoxComponent,
    WarningDiscardModalBoxComponent,
    WarningModalBoxComponent,
    PlaylistChangeToRandomModalBoxComponent,
    PlaylistChangeToCustomModalBoxComponent,
    LocationGroupAddModalBoxComponent,
    LocationGroupDeleteModalBoxComponent,
    BaRuntimeComponent,
    RowShuffleComponent,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    SecurePipe,
    ApiService,
    FileApiService,
    OneWeekApiService,
    BookingApiService,
    UserApiService,
    RoleApiService,
    AuthApiService,
    DuplicateTrackingService,
    LocationApiService,
    LocationGroupsService,
    BookingTemplateApiService,
    RoleApiService,
    MediasApiService,
    TrackByService,
    ExternalResourceApiService,
    RolesController,
    BookingsController,
    LocationsController,
    BookingTemplatesController,
    UsersController,
    ExternalResourcesController,
    ModalService,
    QueueApiService,
    MediasController,
    SettingsController,
    CategoriesController,
    TemplatesController,
    SpecialRuntimesController,
    Runtimes,
    RolesActionsCreator,
    LocationsActionsCreator,
    BookingsProcessingActionsCreator,
    BookingsActionsCreator,
    UsersActionsCreator,
    BookingTemplatesActionsCreator,
    MediasActionsCreator,
    LocationGroupsActionsCreator,
    ExternalResourcesActionsCreator,
    LocationGroupsActionsCreator,
    AuthGuard,
    BookingRenderer,
    KeyboardRenderer,
    ComponentCreator,
    ComponentManager,
    ContentContainerManager,
    PlayerManager,
    SettingsApiService,
    SettingsActionsCreator,
    CategoriesActionsCreator,
    TemplatesActionsCreator,
    CategoryApiService,
    SpecialRuntimesApiService,
    SpecialRuntimesActionsCreator,
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],
  bootstrap: [AppRootComponent],
})
export class AppModule {
  constructor(ngRedux: NgRedux<IAppState>, devTools: DevToolsExtension, reduxRouter: NgReduxRouter) {
    const storeEnhancers = devTools.isEnabled() ? [devTools.enhancer()] : [];
    ngRedux.configureStore(rootReducer, DEFAULT_STATE, [thunk], storeEnhancers);
    reduxRouter.initialize();
  }
}
