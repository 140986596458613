import {Component, Input} from '@angular/core';
import {MediaUploadService} from '../../../services/media-upload.service';
import {ALLOWED_TYPES} from '../../../../../../common/constants';

@Component({
  selector: 'ba-replace-media',
  templateUrl: './replace-media.component.html',
  styleUrls: ['./replace-media.component.scss'],
})
export class ReplaceMediaComponent {

  @Input() id: number;

  constructor(private uploadService: MediaUploadService) {
  }

  get allowedMimeTypes(): string {
    return ALLOWED_TYPES.join(',');
  }

  public filePicked(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];

      this.uploadService.updateFile(this.id, file);
    }
  }
}
