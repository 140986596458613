import { Component } from '@angular/core';
import { PreviewLocationComponent } from './preview-location/preview-location.component';
import { PREVIEW } from '../../../../../../common/constants';

@Component({
  selector: 'app-previews',
  template: `
    <app-table-page
      [name]="name"
      [useTableMenu]="false"
      [title]="'Überprüfe die Ansicht und Inhalte je Standort.'"
      [subtitle]="'Jederzeit volle Kontrolle und alles im Blick.'"
    >
      <app-locations-table
        [useEditButtons]="false"
        [isToggleSwitched]="false"
        [rowsCheckable]="false"
        [editOrAddLinkGetter]="editOrAddLinkGetter"
      >
      </app-locations-table>
    </app-table-page>`,
})
export class PreviewsComponent {

  public static ROUTE = 'previews';
  public name = PREVIEW;

  public editOrAddLinkGetter(rowData: any) {
    return `/${PreviewsComponent.ROUTE}/${PreviewLocationComponent.ROUTE}/${rowData.id}`;
  }
}
