import {DOCUMENT} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {ModalService} from '../../../../services/modal.service';
import {AbstractModalBox} from '../abstract-modal-box/abstract-modal-box';
import {ToastrService} from 'ngx-toastr';
import {LocationGroupsService} from '../../../../services/location-groups.service';
import {Subscription} from 'rxjs';
import {LocationsSelector} from '../../../../redux/reducers/locations.reducer';
import {select} from '@angular-redux/store';


@Component({
  selector: 'app-location-group-add-modal-box',
  templateUrl: './location-group-add-modal-box.component.html',
  styleUrls: ['../abstract-modal-box/modal-box-style.scss'],
})

export class LocationGroupAddModalBoxComponent extends AbstractModalBox {

  public bodyText: string;
  @select(LocationsSelector.getCheckedIds) locationList: any;
  private _locationListSubscription: Subscription;

  constructor(@Inject(DOCUMENT) document: Document,
              modalService: ModalService,
              private toastrService: ToastrService,
              private locationGroupsService: LocationGroupsService,
  ) {
    super(document, modalService);
  }

  async submit() {
    await this.addLocation();
    await super.submit();
    await this.modalService.close();
  }

  async addLocation() {
    const nameOfLocationGroup = this.bodyText;
    let selectedLocations: number[] = [];
    this._locationListSubscription = this.locationList.subscribe(data => selectedLocations = data);
    const newObj = {group_name: nameOfLocationGroup, locations: selectedLocations};
    await this.locationGroupsService.addLocationGroup(newObj);
    await this.locationGroupsService.initLocationGroupList();
    this.locationGroupsService.removeSelectedLocationsOfTheGroup(selectedLocations);
    this.locationGroupsService.setNewLocationGroup(nameOfLocationGroup);
    this.locationGroupsService.setLocationGroupForBookings(nameOfLocationGroup);
    this._locationListSubscription.unsubscribe();
    this.toastrService.success('Die Gruppe wurde erfolgreich gespeichert.');
  }

}
