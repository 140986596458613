import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
} from '@angular/forms';

import { Subject } from 'rxjs';
import {
  tap,
  takeUntil,
} from 'rxjs/operators';

import { CategoryFormGroup } from '../categories-form/categories-form.component';

@Component({
  selector: 'ba-category-input',
  templateUrl: './category-input.component.html',
  styleUrls: ['./category-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CategoryInputComponent implements OnInit {
  private ngDestroy$ = new Subject<null>();

  @Input() formSubmitted: boolean;
  @Input() categoryForm: FormGroup & { controls: CategoryFormGroup };
  @Input() depth: Array<number> = [];
  @Input() depthLimit: number;
  @Output() add = new EventEmitter<Array<number>>();
  @Output() remove = new EventEmitter<Array<number>>();

  private initialValue = null;
  private previousValue = null;

  public ngOnInit() {
    this.initialValue = this.nameControl && this.nameControl.value;
    this.previousValue = this.initialValue;

    if (this.nameControl) {
      this.nameControl.valueChanges
        .pipe(
          tap(() => this.checkChanges()),
          takeUntil(this.ngDestroy$),
        ).subscribe();
    }
  }

  public checkChanges() {
    this.changedControl.setValue(this.previousValue !== this.nameControl.value);
    this.previousValue = this.nameControl.value;
  }

  public edit() {
    this.editControl.setValue(true);
  }

  public cancel() {
    if (!this.categoryForm.value.id && !this.initialValue) {
      this.removeItem();
    } else {
      this.nameControl.setValue(this.initialValue);
      this.editControl.setValue(false);
    }
  }

  public removeItem() {
    this.onRemove([...this.depth]);
  }

  public addCategory() {
    if (this.depth && this.depth.length >= this.depthLimit) {
      return;
    }

    this.onAdd([...this.depth]);
  }

  public onAdd(event: Array<number>) {
    this.add.emit(event);
  }

  public onRemove(event) {
    this.remove.emit(event);
  }

  public get saveTitle() {
    if (!this.nameControl.valid) {
      return 'Provide category name';
    } else {
      if (this.initialValue === this.nameControl.value) {
        return 'Save';
      } else {
        return 'Save changes';
      }
    }
  }

  public get cancelTitle() {
    if (this.initialValue === this.nameControl.value) {
      return 'Cancel';
    } else {
      return 'Discard changes';
    }
  }

  private get nameControl() {
    return this.categoryForm && this.categoryForm.get('name') as FormControl;
  }

  private get editControl() {
    return this.categoryForm && this.categoryForm.get('edit') as FormControl;
  }

  private get changedControl() {
    return this.categoryForm && this.categoryForm.get('changed') as FormControl;
  }
}
