import * as _ from 'lodash';
import {
  AbstractReducer,
  IMetaState,
} from './abstract.reducer';
import {
  ISettingsApisAction,
  SettingsActionsCreator,
} from '../actions/settings/settings.action';
import { ISettingsObject } from '../../../../../common/interfaces/settings';

export type ISettingsState = IMetaState<ISettingsObject>;

export class SettingsReducer extends AbstractReducer<ISettingsObject, ISettingsApisAction> {
  private constructor() {
    super('settings', SettingsActionsCreator.getInstance());
  }

  private static instance: SettingsReducer;

  public DEFAULT_STATE: IMetaState<ISettingsObject> =  _.assign({}, AbstractReducer.DEFAULT_STATE, {
    ...AbstractReducer.DEFAULT_STATE,
    filters: {},
    sortings: {},
  });

  public static getInstance() {
    if (!SettingsReducer.instance) {
      SettingsReducer.instance = new SettingsReducer();
    }

    return SettingsReducer.instance;
  }
}

export const SettingsSelector = SettingsReducer.getInstance().getSelectors();
