import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ba-media-sidebar-item',
  templateUrl: './media-sidebar-item.component.html',
  styleUrls: ['./media-sidebar-item.component.scss'],
})
export class MediaSidebarItemComponent {

  @Input() layout: string;
  @Input() imgSrc: string;
  @Input() name: string;
  @Input() id: number;
  @Output() mediaPick = new EventEmitter<any>();

  public onMediaPick() {
    this.mediaPick.emit(this.id);
  }

}
