import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { IStateLocation, LocationsSelector } from '../../../redux/reducers/locations.reducer';
import { IAppState } from '../../../redux/store';
import { LocationsController } from '../../../redux/actions/locations/locations.controller';
import { LocationsActionsCreator } from '../../../redux/actions/locations/locations.action';
import { IColumn } from '../table-page/table/table.component';
import { IDeleteTexts } from '../../../services/modal.service';
import { LOCATIONS } from '../../../../../../common/constants';
import * as _ from 'lodash';
import { PlaylistType } from '../../../../../../common/interfaces/playlist';
import { IApiGetLocation } from '../../../../../../common/interfaces/location';
import { getReadableLastSeen } from '../../util/location';

@Component({
  selector: 'app-locations-table',
  template: `
    <app-table
      [rowsCheckable]="rowsCheckable"
      [deleteTexts]="deleteTexts"
      [maxHeight]="maxHeight"
      [useEditButtons]="useEditButtons"
      [useDuplicate]="useDuplicate"
      [isToggleSwitched]="isToggleSwitched"
      [whenCheckboxChanged]="whenCheckboxChanged"
      [isEditable]="isEditable"
      [name]="name"
      [editOrAddLinkGetter]="editOrAddLinkGetter"
      [duplicateLinkGetter]="duplicateLinkGetter"
      [columns]="columns"
      [getAllSelector]="getAll"
      [getAllIdsSelector]="getAllIds"
      [isAllCheckedSelector]="isAllChecked"
      [getFilterByNameSelector]="getFilterByName"
      [actionFactory]="actionFactory"
      [crudController]="crudController"
      [onRowSelected]="onLocationSelected"
    >
    </app-table>`,
  styles: [':host { display: block; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsTableComponent {
  @Input() useEditButtons = true;
  @Input() onLocationSelected: Function = _.noop;

  @Input() editOrAddLinkGetter: any;
  @Input() useDuplicate: boolean;
  @Input() rowsCheckable = true;
  @Input() isToggleSwitched = false;
  @Input() whenCheckboxChanged: Function = _.noop;
  @Input() isEditable = true;
  @Input() maxHeight = false;
  @Input() standalone = false;

  public deleteTexts: IDeleteTexts = {
    delete: 'Standort löschen',
    reallyDelete: 'Möchtest du den ausgewählten Standort wirklich löschen?',
    noKeep: 'Nein, Standort behalten.',
    yesDelete: 'Ja, Standort löschen.',
  };

  private playlistTypeToDescription: { [key: string]: string } = {
    [PlaylistType.shuffle]: 'Zufällig',
    [PlaylistType.custom]: 'Benutzerdefiniert',
  };

  public columns: IColumn[] = [
    {
      name: 'Playlisttyp',
      filterName: 'playlist_type',
      cellClass: 'icon',
      placeholder: 'Alle',
      getSearchOptions: () => _.map(PlaylistType, (value: PlaylistType) => ({ id: value, name: this.playlistTypeToDescription[value] || value })),
      dataGetter: (data) => `assets/gfx/${data.playlist_type || 'shuffle'}.svg`,
    },
    { name: 'ID', filterName: 'external_id', cellClass: 'number', dataGetter: (data) => data.external_id },
    { name: 'Standortname', filterName: 'name', cellClass: 'large', dataGetter: (data) => data.name },
    { name: 'Stadt', filterName: 'city', cellClass: 'medium', dataGetter: (data) => data.city },
    { name: 'Auflösung', filterName: 'resolution', cellClass: 'medium', dataGetter: (data) => `${data.width} x ${data.height}` },
    {
      name: 'Status',
      filterName: 'status',
      cellClass: 'status',
      placeholder: 'Alle',
      getSearchOptions: () => [{ id: 'online', name: 'online' }, { id: 'offline', name: 'offline' }],
      dataGetter: this.locationStatusGetter.bind(this),
    },
  ];
  public getAll: ($state: IAppState) => IStateLocation[];
  public getAllIds: ($state: IAppState) => string[];
  public isAllChecked: ($state: IAppState) => boolean;
  public getFilterByName: (name: string) => ($state: IAppState) => string;
  public name = LOCATIONS;

  public editOrAddLinkGetterDefault(rowData: any) {
    return `/locations/location-administration/${rowData.id}`;
  }

  public duplicateLinkGetter(rowData: any) {
    return '/locations/location-administration';
  }

  private locationStatusGetter(data: IApiGetLocation) {
    if (this.standalone) {
      return {
        comment: data.comment,
        ip: data.ip,
        lastSeen: data.last_seen ? getReadableLastSeen(data.last_seen) : null,
        status: data.status,
      };
    }

    return {
      comment: data.comment,
      status: data.status,
    };
  }

  constructor(public crudController: LocationsController, public actionFactory: LocationsActionsCreator) {
    this.getAll = LocationsSelector.getAll;
    this.getAllIds = LocationsSelector.getAllIds;
    this.isAllChecked = LocationsSelector.isAllChecked;
    this.getFilterByName = LocationsSelector.getFilterByName;
    this.editOrAddLinkGetter = this.editOrAddLinkGetter || this.editOrAddLinkGetterDefault;
    this.useDuplicate = true;
  }
}
