import {Component, Input} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '../abstract-value-accessor/abstract-value-accessor';

export type BaSelectListItems = Array<{
  code: any;
  name: string;
  isDisabled?: boolean;
}>;

@Component({
  selector: 'ba-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [MakeProvider(BaSelectComponent)],
})
export class BaSelectComponent extends AbstractValueAccessor<string> {
  @Input() id: string;
  @Input() class: string;
  @Input() listItems: BaSelectListItems;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() isDark: boolean;
  @Input() isDisabled: boolean;
  @Input() isRequired: string;
  @Input() label: string;
  @Input() hasEmptyOption = false;
}
