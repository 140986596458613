import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioComponent, RadioGroupDirective } from './radio/radio.component';

export * from './checkbox/checkbox.component';
export * from './radio/radio.component';

export const Components = [
  CheckboxComponent,
  RadioComponent,
];

export const Directives = [
  RadioGroupDirective,
];
