import { Injectable } from '@angular/core';
import {
  AbstractActionsCreator,
  IAction,
} from '../abstractActionsCreator';
import { IApiSetSpecialRuntime } from '../../../../../../common/interfaces/special-runtimes';

export const namespace = '@SPECIAL_RUNTIMES/';
export type ISpecialRuntimesApisAction = IAction<IApiSetSpecialRuntime>;

@Injectable()
export class SpecialRuntimesActionsCreator extends AbstractActionsCreator<IApiSetSpecialRuntime> {
  private static instance: SpecialRuntimesActionsCreator;

  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!SpecialRuntimesActionsCreator.instance) {
      SpecialRuntimesActionsCreator.instance = new SpecialRuntimesActionsCreator();
    }

    return SpecialRuntimesActionsCreator.instance;
  }
}
