import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgRedux, select, Selector} from '@angular-redux/store';
import {IAppState} from '../../../redux/store';
import {Observable} from 'rxjs';

import {ModalService} from '../../../services/modal.service';
import {AbstractCrudController} from '../../../redux/actions/abstractCrud.controller';
import {UsersSelector} from '../../../redux/reducers/users.reducer';
import {LocationApiService} from '../../../services/api/location.api.service';
import {LocationGroupsSelector} from '../../../redux/reducers/location-groups.reducer';
import {IApiGetLocationGroup} from '../../../../../../common/interfaces/locationGroup';


@Component({
  selector: 'app-table-page',
  templateUrl: './table-page.component.html',
  styleUrls: ['./table-page.component.scss'],
})
export class TablePageComponent implements OnInit {
  isActive$: Observable<boolean>;

  @select(UsersSelector.getMe) user$: Observable<any>;

  @Input() isTimeEnabled: boolean;
  @Input() isRunTimeEnabled: boolean;
  @Input() name: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() useToggle: boolean;
  @Input() useGrouping = false;
  @Input() locationGroupListDataItems: Array<object>;
  @Input() toggleText: string;
  @Input() useActiveInactive: boolean;
  @Input() activeText: string;
  @Input() inactiveText: string;
  @Input() useTime: boolean;
  @Input() useRunTime: boolean;
  @Input() iconName: string;
  @Input() useDelete = true;
  @Input() useTableMenu = true;
  @Input() addLink: string;
  @Input() route: string;
  @Input() modalBoxComponentName: any;
  @Input() isActiveSelector: Selector<IAppState, any>;
  @Input() crudController: AbstractCrudController;
  @select(LocationGroupsSelector.getAll) locationGroupList$: Observable<IApiGetLocationGroup[]>;


  @Output() toggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor(private redux: NgRedux<IAppState>, private modalService: ModalService, private locationApiService: LocationApiService) {
  }

  toggleSwitch(event: boolean) {
    this.toggleChange.emit(event);
  }

  async ngOnInit() {
    this.isActive$ = this.redux.select<boolean>(this.isActiveSelector);
  }

  clickActive() {
    this.redux.dispatch(this.crudController.updateActive());
  }

  clickInActive() {
    this.redux.dispatch(this.crudController.updateInActive());
  }

  deleteChecked() {
    this.redux.dispatch(this.crudController.deleteChecked());
  }

  modifyChecked() {
    this.modalService.open(this.modalBoxComponentName, {isMobile: false});
  }
}
