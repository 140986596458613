import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'ba-add-runtime',
  templateUrl: './add-runtime.component.html',
  styleUrls: ['./add-runtime.component.scss'],
})
export class BaAddRuntimeComponent {

  @Output() add = new EventEmitter<void>();

  private onAddComponent(event) {
    event.stopPropagation();
    event.preventDefault();

    this.add.emit();
  }

}
