import * as _ from 'lodash';
import {
  AbstractReducer,
  IMetaState,
} from './abstract.reducer';
import {
  ICategoriesAction,
  CategoriesActionsCreator,
} from '../actions/categories/categories.action';
import { ICategoryAttributes, IApiGetCategories } from '../../../../../common/interfaces/category';
import { IAppState } from '../store';

export type ICategoriesState = IMetaState<IApiGetCategories>;

export class CategoriesReducer extends AbstractReducer<IApiGetCategories, ICategoriesAction> {
  private constructor() {
    super('categories', CategoriesActionsCreator.getInstance());
  }

  private static instance: CategoriesReducer;

  public DEFAULT_STATE: IMetaState<IApiGetCategories> =  _.assign({}, AbstractReducer.DEFAULT_STATE, {
    filters: {},
    sortings: {},
  });

  public static getInstance() {
    if (!CategoriesReducer.instance) {
      CategoriesReducer.instance = new CategoriesReducer();
    }

    return CategoriesReducer.instance;
  }

  private catPad(depth = 0, separator = '|-- ') {
    return Array(depth).fill(separator).join('');
  }

  private covertToOptions(depth: number = 0) {
    return (list: Array<IApiGetCategories>) => Object.values(list).reduce((acc, { id, name, children }) => ([
      ...acc,
      {
        code: id,
        name: `${this.catPad(depth)}${name}`,
        isDisabled: !!children.length,
      },
      ...this.covertToOptions(depth + 1)(children),
    ]), []);
  }

  public getOptionsSelector($state: IAppState): Array<{ code: any; name: string }> {
    return this.covertToOptions()($state[this.stateName].datas);
  }

  public getSelectors() {
    return {
      ...super.getSelectors(),
      getOptions: this.getOptionsSelector.bind(this),
    };
  }
}

export const CategoriesSelector = CategoriesReducer.getInstance().getSelectors();
