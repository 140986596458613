import {Injectable} from '@angular/core';

@Injectable()
export class TrackByService {

  constructor() {
  }

  trackByFn(index, data) {
    if (!data) {
      return null;
    }
    return index;
  }
}
