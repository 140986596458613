import {Injectable} from '@angular/core';

@Injectable()
export class DuplicateTrackingService {

  private isDuplicate: boolean;

  constructor() {
  }

  setDuplicate(value) {
    this.isDuplicate = value;
  }

  getDuplicate() {
    return this.isDuplicate;
  }
}
