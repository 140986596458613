import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { AbstractCrudController } from '../abstractCrud.controller';
import { ISelectors } from '../../reducers/abstract.reducer';
import { IApiSetSpecialRuntime } from '../../../../../../common/interfaces/special-runtimes';
import { SpecialRuntimesActionsCreator } from './special-runtimes.action';
import {
  IStateSpecialRuntime,
  SpecialRuntimesSelector,
} from '../../reducers/special-runtimes.reducer';
import { SpecialRuntimesApiService } from '../../../services/api/special-runtimes.api.service';
import {
  END_DATE,
  EQ,
  IS_NO_END,
  WITHOUT_END,
} from '../../../../../../common/constants';
import { fromDateToEuFormat } from '../../../../../../common/moment-util-v2';
import { IAppState } from '../../store';
import { IApiFilter } from '../../../../../../common/interfaces/api';

@Injectable()
export class SpecialRuntimesController extends AbstractCrudController<IStateSpecialRuntime, IApiSetSpecialRuntime> {

  constructor(protected apiService: SpecialRuntimesApiService, protected actionFactory: SpecialRuntimesActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IStateSpecialRuntime> = SpecialRuntimesSelector;

  protected getApiAndFilters(isActive: boolean, getState: () => IAppState): IApiFilter[] {
    const filters = super.getApiAndFilters(isActive, getState);

    const isWithWithoutEndFilter = filters.reduce((acc: boolean, filter: IApiFilter) => {
      if (filter.column === END_DATE && WITHOUT_END.includes(filter.value as string)) {
        return true;
      }

      return acc;
    }, false);
    const isWithYearlyFilter = filters.reduce((acc: boolean, filter: IApiFilter) => {
      if (filter.column === 'yearly') {
        return true;
      }

      return acc;
    }, false);

    let newFilters = isWithWithoutEndFilter ? filters.filter((filter) => filter.column !== END_DATE) : filters;
    if (isWithYearlyFilter) {
      const yearlyFilter = filters.find(filter => filter.column === 'yearly');

      newFilters = newFilters.map((filter) => {
        if (filter.column === 'yearly') {
          return {
            column: 'yearly',
            operator: 'eq',
            value: yearlyFilter.value,
          };
        }

        return filter;
      });
    }
    newFilters.push(isWithWithoutEndFilter
      ? {column: IS_NO_END, operator: EQ, value: 'true'}
      : {column: END_DATE, operator: isActive ? 'gte' : 'lt', value: fromDateToEuFormat(moment, new Date())});

    return newFilters;
  }
}
