import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

@Component({
  selector: 'ba-extended-checkbox',
  templateUrl: './extended-checkbox.component.html',
  styleUrls: ['./extended-checkbox.component.scss'],
})
export class BaExtendedCheckboxComponent implements OnInit {

  @Input() checkBoxId: string;
  @Input() isDisabled: boolean;
  @Input() isChecked: boolean;
  @Input() isType: string;
  @Input() isCheckable = true;
  @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public label: string;
  public requireLabel: string;
  public icon: string;

  constructor() {
  }



  ngOnInit() {
    if (this.isDisabled) {
      this.label = 'keine Auswahl möglich';
      this.icon = 'delete-icon.svg';
    } else if (this.isType === 'view') {
      this.label = 'Ansehen';
      this.requireLabel = '';
      this.icon = 'eye-icon.svg';
    } else if (this.isType === 'add') {
      this.label = 'Anlegen';
      this.requireLabel = 'Aktiviert Ansehen';
      this.icon = 'add-icon.svg';
    } else if (this.isType === 'edit') {
      this.label = 'Bearbeiten';
      this.requireLabel = 'Aktiviert Ansehen';
      this.icon = 'edit-icon.svg';
    } else if (this.isType === 'delete') {
      this.label = 'Löschen';
      this.requireLabel = 'Aktiviert Ansehen';
      this.icon = 'delete-icon.svg';
    }
  }

  changeChecked() {
    if (!this.isCheckable) {
      return;
    }

    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }
}
