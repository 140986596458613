import { Injectable } from '@angular/core';

import { AbstractCrudController } from '../abstractCrud.controller';
import { ISelectors } from '../../reducers/abstract.reducer';
import { ISettingsObject } from '../../../../../../common/interfaces/settings';
import { SettingsActionsCreator } from './settings.action';
import { SettingsSelector } from '../../reducers/settings.reducer';
import { SettingsApiService } from '../../../services/api/settings.api.service';

@Injectable()
export class SettingsController extends AbstractCrudController<ISettingsObject, ISettingsObject> {

  constructor(protected apiService: SettingsApiService, protected actionFactory: SettingsActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<ISettingsObject> = SettingsSelector;
}
