import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { full } from '../../../../../common/routes';
import { AbstractCrudApiService } from './abstract-crud.api.service';
import { AuthApiService } from './auth.api.service';
import { ApiService } from './api.service';

@Injectable()
export class SpecialRuntimesApiService extends AbstractCrudApiService {

  protected apiUrl: string = full.specialRuntimes;

  constructor(http: HttpClient, authApi: AuthApiService, api: ApiService) {
    super(http, authApi, api);
  }

  public getAll() {
    return this.get();
  }

}
