import {Injectable} from '@angular/core';
import {AbstractActionsCreator, IAction} from '../abstractActionsCreator';
import {ISetBookingsProcessing} from '../../../../../../common/interfaces/booking';

export const namespace = '@BOOKINGS_PROCESSING/';

export interface IBookingsProcessing extends ISetBookingsProcessing {
  isAsync: boolean;
  isUploading: boolean;
  jobId: number;
}

export type IBookingsProcessingAction = IAction<IBookingsProcessing>;

@Injectable()
export class BookingsProcessingActionsCreator extends AbstractActionsCreator<IBookingsProcessing> {
  private static instance: BookingsProcessingActionsCreator;

  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!BookingsProcessingActionsCreator.instance) {
      BookingsProcessingActionsCreator.instance = new BookingsProcessingActionsCreator();
    }

    return BookingsProcessingActionsCreator.instance;
  }
}
