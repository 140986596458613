import { Component } from '@angular/core';
import { getPlaylistAdministrationRoute, PLAYLIST_ROUTE } from './playlist-routes';
import { PLAYLIST } from '../../../../../../common/constants';

@Component({
  selector: 'app-playlists',
  template: `
    <app-table-page
      [name]="name"
      [useTableMenu]="false"
      [title]="'Wähle einen Standort um dessen Playliste zu sehen'"
      [subtitle]="'Was läuft wo, wann und in welcher Reihenfolge'"
    >
      <app-locations-table
        [useEditButtons]="false"
        [isToggleSwitched]="false"
        [rowsCheckable]="false"
        [editOrAddLinkGetter]="editOrAddLinkGetter"
      >
      </app-locations-table>
    </app-table-page>`,
})
export class PlaylistsComponent {

  public static ROUTE = PLAYLIST_ROUTE;
  public name = PLAYLIST;

  public editOrAddLinkGetter(rowData: any) {
    return getPlaylistAdministrationRoute(rowData.id);
  }

}
