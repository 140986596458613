import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {NgRedux, Selector} from '@angular-redux/store';

import {IAppState} from '../../../redux/store';
import {AbstractCrudController} from '../../../redux/actions/abstractCrud.controller';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'ba-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class BaSearchComponent implements OnInit, OnDestroy {

  filter$: Observable<string>;
  filterSubscription: Subscription;

  @Input() isScopeShown: boolean;
  @Input() isWhite: boolean;
  @Input() filterName: string;
  @Input() controller: AbstractCrudController;
  @Input() selector: Selector<IAppState, any>;
  @Input() searchOptions: {id: number, name: string}[] = [];
  @Input() placeholder: string;

  public selectionModel: string = this.placeholder || '';

  constructor(private redux: NgRedux<IAppState>) {
    this.isScopeShown = false;
  }

  private _updateFilter(id: string, instantly: boolean) {
    if (!this.controller) {
      return;
    }

    this.redux.dispatch(instantly
      ? this.controller.updateFilterInstantly(id, this.filterName)
      : this.controller.updateFilter(id, this.filterName),
    );
  }

  updateFilter(event: any, instantly: boolean = false) {
    if (!this.filterName) {
      return;
    }

    const value = event.target.value;
    this._updateFilter(value === this.placeholder ? '' : value, instantly);
  }

  clear(): void {
    this._updateFilter('', false);
  }

  ngOnInit(): void {
    this.filter$ = this.selector ? this.redux.select((<any>this.selector)(this.filterName)) : null;
    if (this.filter$) {
      this.filterSubscription = this.filter$.subscribe((filter) => {
        this.selectionModel = filter || this.placeholder;
      });
    }
  }

  ngOnDestroy(): void {
    if (this.filterSubscription) {
      this.filterSubscription.unsubscribe();
    }
  }

}
