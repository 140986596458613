import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  Validators,
} from '@angular/forms';

import { Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  takeUntil,
  tap,
  debounceTime,
} from 'rxjs/operators';

import { ModalService } from 'src/app/services/modal.service';

import { WarningModalBoxComponent } from '../modal-box/warning-modal-box/warning-modal-box.component';

import {
  CategoryId,
  CategoryInput,
  IApiGetCategories,
} from '../../../../../../common/interfaces/category';

export type CategoryFormGroup = { [K in keyof CategoryInput]: any };

@Component({
  selector: 'ba-categories-form',
  templateUrl: './categories-form.component.html',
  styleUrls: ['./categories-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesFormComponent implements OnInit, OnChanges, OnDestroy {
  private ngDestroy$ = new Subject<void>();

  @Input() data: Array<IApiGetCategories>;
  @Input() formSubmitted: boolean;
  @Input() depthLimit = 3;
  @Output() update = new EventEmitter<Array<CategoryInput>>();
  @Output() delete = new EventEmitter<CategoryId>();

  public form: FormArray;
  private newCategoriesListener$ = new Subject<void>();

  private categoryDeleteText = [
    {
      delete: 'Hauptkategorie löschen?',
      reallyDelete: 'Dadurch werden auch alle zugehörigen Kategorien und Unterkategorien gelöscht.<br>Dies kann nicht rückgängig gemacht werden!',
    },
    {
      delete: 'Kategorie löschen?',
      reallyDelete: 'Dadurch werden auch alle zugehörigen Unterkategorien gelöscht.<br>Dies kann nicht rückgängig gemacht werden!',
    },
    {
      delete: 'Unterkategorie löschen?',
      reallyDelete: 'Dies kann nicht rückgängig gemacht werden!',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private modalService: ModalService,
  ) {}

  public ngOnInit() {
    this.form = this.initFormWithData(this.data);
    this.newCategoriesListener$.pipe(
      debounceTime(300),
      tap(() => this.onSubmit()),
      takeUntil(this.ngDestroy$),
    ).subscribe();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.data && !changes.data.firstChange && this.formSubmitted) {
      this.form.patchValue(this.rawDataHydration(changes.data.currentValue));
    }
  }

  public ngOnDestroy() {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public onSubmit() {
    this.update.emit(this.form.value);
  }

  private rawDataHydration(categories: Array<IApiGetCategories & { isChecked?: boolean; index?: number; }>) {
    return categories.reduce((acc, { isChecked, bookings, parent, index, ...category }) => {
      return [
        ...acc,
        {
          ...category,
          changed: false,
          children: this.rawDataHydration(category.children),
        },
      ];
    }, []);
  }

  private initFormWithData(data: Array<IApiGetCategories>) {
    if (!data) {
      return this.fb.array([]);
    }

    return this.fb.array(data.map(({ id, name, parent_id, children }) => {
      return this.fb.group({
        id,
        name: [name, Validators.required],
        parent_id,
        changed: false,
        children: this.initFormWithData(children),
      } as CategoryFormGroup);
    }));
  }

  public getGroup(levels: Array<number>) {
    const children = levels.join('.children.');
    const group = levels && levels.length ? this.form.get(children) : this.form;

    return group as FormArray;
  }

  public getArray(levels: Array<number> = []) {
    const array = levels.length
      ? this.getGroup(levels).get('children')
      : this.getGroup([]);

    return array as FormArray;
  }

  public onAdd(depth: Array<number> = []) {
    const array = this.getArray(depth);
    const prefix = !depth.length ? 'Hauptk' : depth.length > 1 ? 'Unterk' : 'K';
    const name = `${prefix}ategorie ${array.length + 1}`;

    array.push(this.fb.group({
      id: null,
      name: [name, Validators.required],
      parent_id: null,
      changed: true,
      children: this.fb.array([]),
    } as CategoryFormGroup));

    this.newCategoriesListener$.next();
  }

  public onRemove(depth: Array<number> = []) {
    const toDelete = depth.pop();
    const formControl = this.getArray(depth);
    const { id } = formControl.value[toDelete];
    this.modalService.open(WarningModalBoxComponent, {
      onSubmit: () => {
        this.delete.emit(id);
        formControl.removeAt(toDelete);
      },
      deleteTexts: {
        ...this.categoryDeleteText[depth.length],
        noKeep: 'Nein, nicht löschen.',
        yesDelete: 'Ja, löschen.',
      },
    });
  }
}
