import {Action, AnyAction} from 'redux';

export interface IToggleAction extends AnyAction {
  id: number | string;
}

export interface ISetChecked extends AnyAction {
  id: number;
  isChecked: boolean;
}

export interface ISetFilter extends AnyAction {
  filterName: string;
  filter: string;
}

export interface IDelete extends AnyAction {
  ids: number[];
  single: boolean;
}

export interface IDeleteWithStringIds extends AnyAction {
  ids: string[];
  single: boolean;
}

export interface ISetAction<setDataType> extends AnyAction {
  data: setDataType[];
  isActive: boolean;
}

export interface IAddAction<setDataType> extends AnyAction {
  data: setDataType[];
}

export interface IUpdateAction<setDataType> extends AnyAction {
  data: setDataType;
}

export interface ISort extends AnyAction {
  column: string;
}

export interface IChangePageAction extends AnyAction {
  page: number;
}

export type IAction<setDataType> = ISetAction<setDataType> | IUpdateAction<setDataType> | IToggleAction | ISetChecked | ISetFilter | IDelete | ISort;

export interface IActionTypes {
  UPDATE: string;
  SET: string;
  TOGGLE_CHECKED: string;
  SET_CHECKED: string;
  RESET_LOCAL_CHANGES: string;
  SET_FILTER: string;
  DELETE: string;
  TOGGLE_SORT: string;
  CHANGE_PAGE: string;
  ADD: string;
  [name: string]: string;
}

const UPDATE = 'update';
const SET = 'SET';
const TOGGLE_CHECKED = 'TOGGLE_CHECKED';
const SET_CHECKED = 'SET_CHECKED';
const RESET_LOCAL_CHANGES = 'RESET_LOCAL_CHANGES';
const SET_FILTER = 'SET_FILTER';
const DELETE = 'DELETE';
const TOGGLE_SORT = 'TOGGLE_SORT';
const CHANGE_PAGE = 'CHANGE_PAGE';
const ADD = 'ADD';

export abstract class AbstractActionsCreator<setDataType> {
  protected constructor(private namespace: string) {
    this.SET = this.namespace + SET;
    this.UPDATE = this.namespace + UPDATE;
    this.TOGGLE_CHECKED = this.namespace + TOGGLE_CHECKED;
    this.SET_CHECKED = this.namespace + SET_CHECKED;
    this.RESET_LOCAL_CHANGES = this.namespace + RESET_LOCAL_CHANGES;
    this.SET_FILTER = this.namespace + SET_FILTER;
    this.DELETE = this.namespace + DELETE;
    this.TOGGLE_SORT = this.namespace + TOGGLE_SORT;
    this.CHANGE_PAGE = this.namespace + CHANGE_PAGE;
    this.ADD = this.namespace + ADD;
  }

  private readonly DELETE: string;
  private readonly SET_FILTER: string;
  private readonly SET_CHECKED: string;
  private readonly RESET_LOCAL_CHANGES: string;
  private readonly TOGGLE_CHECKED: string;
  private readonly SET: string;
  private readonly UPDATE: string;
  private readonly TOGGLE_SORT: string;
  private readonly CHANGE_PAGE: string;
  private readonly ADD: string;

  public getActionTypes(): IActionTypes {
    return {
      SET: this.namespace + SET,
      UPDATE: this.namespace + UPDATE,
      TOGGLE_CHECKED: this.namespace + TOGGLE_CHECKED,
      SET_CHECKED: this.namespace + SET_CHECKED,
      RESET_LOCAL_CHANGES: this.namespace + RESET_LOCAL_CHANGES,
      SET_FILTER: this.namespace + SET_FILTER,
      DELETE: this.namespace + DELETE,
      TOGGLE_SORT: this.namespace + TOGGLE_SORT,
      CHANGE_PAGE: this.namespace + CHANGE_PAGE,
      ADD: this.namespace + ADD,
    };
  }

  toggleSort(column: string): ISort {
    return {type: this.TOGGLE_SORT, column};
  }

  deleteData(ids: number[], isSingle: boolean): IDelete {
    return {type: this.DELETE, ids, single: isSingle};
  }

  deleteDataWithStringIds(ids: string[], isSingle: boolean): IDeleteWithStringIds {
    return {type: this.DELETE, ids, single: isSingle};
  }

  set(data: Partial<setDataType>[], isActive?: boolean): ISetAction<Partial<setDataType>> {
    return {type: this.SET, data, isActive};
  }

  add(data: Partial<setDataType>[]): IAddAction<Partial<setDataType>> {
    return {type: this.ADD, data};
  }

  update(data: Partial<setDataType>): IUpdateAction<Partial<setDataType>> {
    return {type: this.UPDATE, data};
  }

  setFilter(filter, filterName): ISetFilter {
    return {type: this.SET_FILTER, filterName, filter};
  }

  toggleChecked(id: number | string): IToggleAction {
    return {type: this.TOGGLE_CHECKED, id};
  }

  setChecked(id: number, isChecked: boolean): ISetChecked {
    return {type: this.SET_CHECKED, id, isChecked};
  }

  resetChecked(): Action<string> {
    return {type: this.RESET_LOCAL_CHANGES};
  }

  changePage(page: number): IChangePageAction {
    return {type: this.CHANGE_PAGE, page};
  }
}
