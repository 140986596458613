import {DOCUMENT} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {ModalService} from '../../../../services/modal.service';
import {AbstractModalBox} from '../abstract-modal-box/abstract-modal-box';

@Component({
  selector: 'app-warning-discard-modal-box',
  templateUrl: './warning-discard-modal-box.component.html',
  styleUrls: ['../abstract-modal-box/modal-box-style.scss'],
})

export class WarningDiscardModalBoxComponent extends AbstractModalBox {

  constructor(@Inject(DOCUMENT) document: Document, modalService: ModalService) {
    super(document, modalService);
  }
}
