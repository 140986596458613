import * as _ from 'lodash';
import {IAppState} from '../store';
import {AbstractActionsCreator, IAction, IActionTypes} from '../actions/abstractActionsCreator';
import {IStringMap} from '../../../../../common/interfaces/util';

export interface IStateDataType {
  id: number;
  index: number;
  isChecked: boolean;
}

export interface IState<getDataType> {
  [ids: number]: IStateDataType;
}

export interface IMetaState<getDataType> {
  isActive: boolean;
  datas: IState<getDataType>;
  isAllChecked: boolean;
  filters: IStringMap;
  sortings: IStringMap;
  page?: number;
}

export interface ISelectors<getDataType> {
  getById: (id: number) => ($state: IAppState) => getDataType;
  getAll: ($state: IAppState) => getDataType[];
  getAllIds: ($state: IAppState) => string[];
  isAtLeastOneChecked: ($state: IAppState) => boolean;
  getCheckedIds: ($state: IAppState) => number[];
  getChecked: ($state: IAppState) => getDataType[];
  isActive: ($state: IAppState) => boolean;
  isAllChecked: ($state: IAppState) => boolean;
  getFilterByName: (name: string) => ($state: IAppState) => any;
  getFilters: ($state: IAppState) => IStringMap;
  getSortings: ($state: IAppState) => IStringMap;
  getPage: ($state: IAppState) => number;
  getDatas: ($state: IAppState) => IStringMap;
  [name: string]: (...args: any[]) => any;
}

export abstract class AbstractReducer<getDataType, actionType> {

  protected constructor(protected stateName: string, actionsCreator: AbstractActionsCreator<any>) {
    this.actionTypes = actionsCreator.getActionTypes();
  }

  public static DEFAULT_STATE: IMetaState<any> = {
    datas: {},
    isActive: true,
    isAllChecked: false,
    filters: {id: ''},
    sortings: {id: 'ASC'},
  };

  protected actionTypes: IActionTypes;

  public abstract DEFAULT_STATE: IMetaState<getDataType>;

  public getSelectors(): ISelectors<getDataType> {
    const getChecked = ($state: IAppState): getDataType[] => _.chain($state[this.stateName].datas)
      .values()
      .filter((data: IStateDataType) => data.isChecked)
      .value() || [];

    return {
      getById: (id: number) => ($state: IAppState): getDataType => $state[this.stateName].datas[id],
      getAll: ($state: IAppState): getDataType[] => _.chain($state[this.stateName].datas).values().orderBy('index').value() || [],
      getAllIds: ($state: IAppState): string[] => _.keys($state[this.stateName].datas) || [],
      getChecked: getChecked,
      isAtLeastOneChecked: ($state: IAppState) => getChecked($state).length > 0,
      getCheckedIds: ($state: IAppState): number[] => _.map(getChecked($state), (data: IStateDataType) => data.id) as any[] as number[],
      isActive: ($state: IAppState): boolean => $state[this.stateName].isActive,
      isAllChecked: ($state: IAppState): boolean => $state[this.stateName].isAllChecked,
      getFilterByName: (name: string) => ($state: IAppState): string => (<any>$state[this.stateName].filters)[name],
      getFilters: ($state: IAppState): IStringMap => $state[this.stateName].filters,
      getSortings: ($state: IAppState): IStringMap => $state[this.stateName].sortings,
      getPage: ($state: IAppState): number => $state[this.stateName].page,
      getDatas: ($state: IAppState): IStringMap => $state[this.stateName].datas,
    };
  }

  reduce(lastState: IMetaState<getDataType> = this.DEFAULT_STATE, action: IAction<actionType>): IMetaState<getDataType> {
    switch (action.type) {
      case this.actionTypes.TOGGLE_SORT: {
        const newState = _.clone(lastState);
        newState.sortings = {[action.column]: newState.sortings[action.column] === 'ASC' ? 'DESC' : 'ASC'};
        return newState;
      }

      case this.actionTypes.UPDATE: {
        const newState = _.clone(lastState);
        newState.datas[action.data.id] = Object.assign({}, newState.datas[action.data.id], action.data);
        newState.isActive = typeof action.isActive === 'undefined' ? newState.isActive : action.isActive;
        newState.isAllChecked = false;
        return newState;
      }

      case this.actionTypes.SET: {
        const newState = _.clone(lastState);
        newState.isActive = typeof action.isActive === 'undefined' ? newState.isActive : action.isActive;
        newState.isAllChecked = false;
        newState.datas = {};
        if (Array.isArray(action.data)) {
          _.each(action.data, (data: IStateDataType, index: number) => {
            const dataPath = `datas[${data.id}]`;
            _.set(newState, dataPath, _.assign({}, _.get(lastState, dataPath, {isChecked: false}), data, {index}));
          });
        } else {
          newState.datas = action.data;
        }

        return newState;
      }

      case this.actionTypes.DELETE: {
        const newState = _.cloneDeep(lastState);
        newState.isAllChecked = action.single ? newState.isAllChecked : false;
        _.each(action.ids, (id: number) => delete newState.datas[id]);

        return newState;
      }

      case this.actionTypes.SET_FILTER: {
        const newState = _.cloneDeep(lastState);
        newState.filters[action.filterName] = action.filter;
        return newState;
      }

      case this.actionTypes.RESET_LOCAL_CHANGES: {
        const lastDatas = lastState && lastState.datas || [];
        let newDatas: any = _.map(lastDatas, (data) => Object.assign({}, data, {isChecked: false}));
        if (!Array.isArray(lastDatas)) {
          newDatas = newDatas.reduce((datas, dataItem) => ({
            ...datas,
            [dataItem.id]: dataItem,
          }), {});
        }
        return Object.assign({}, lastState, {
          datas: newDatas,
          filters: this.DEFAULT_STATE.filters,
        });
      }

      case this.actionTypes.SET_CHECKED: {
        if (!lastState.datas[action.id]) {
          return lastState;
        }

        const newState = _.cloneDeep(lastState);

        newState.datas[action.id].isChecked = action.isChecked;
        return newState;
      }

      case this.actionTypes.TOGGLE_CHECKED: {
        const isAll = action.id === 'all';

        if (!isAll && !lastState.datas[action.id]) {
          return lastState;
        }

        const newState = _.cloneDeep(lastState);

        const path = isAll ? 'isAllChecked' : `datas.${action.id}.isChecked`;
        _.set(newState, path, !_.get(newState, path));

        return newState;
      }

      case this.actionTypes.CHANGE_PAGE: {
        const newState = _.cloneDeep(lastState);

        newState.page = action.page;
        return newState;
      }

      case this.actionTypes.ADD: {
        const newState = _.clone(lastState);
        const listLength = Object.keys(newState.datas).length;
        _.each(action.data, (data: IStateDataType, index: number) => {
          const dataPath = `datas[${data.id}]`;
          const dataOfLastState = _.get(lastState, dataPath, {isChecked: false});
          _.set(newState, dataPath, _.assign({}, dataOfLastState, data, {index: index + listLength}));
        });

        return newState;
      }
    }

    return lastState;
  }
}
