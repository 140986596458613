import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {full} from '../../../../../common/routes';
import {AbstractCrudApiService} from './abstract-crud.api.service';
import {AuthApiService} from './auth.api.service';
import {ApiService} from './api.service';

@Injectable()
export class FileApiService extends AbstractCrudApiService {

  protected apiUrl: string = full.files;

  private localCache = {};

  constructor(http: HttpClient, authApi: AuthApiService, api: ApiService) {
    super(http, authApi, api);
  }

  getAuthorizedFileUrl(name: string) {
    return super.getUrl(encodeURIComponent(name)) + '?' + this.authApi.getAuthTokenQueryString();
  }

  async getFile(name: string, responseType = 'blob', token?: string): Promise<any> {
    if (!this.localCache[name]) {
      this.localCache[name] = await super.get(encodeURIComponent(name) + '?' + this.authApi.getAuthTokenQueryString(token), {responseType}, true);
    }

    return this.localCache[name];
  }

}
