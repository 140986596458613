import {Injectable} from '@angular/core';

import { AbstractCrudController } from '../abstractCrud.controller';
import { ISelectors } from '../../reducers/abstract.reducer';
import { IApiGetCategories, IApiSetCategories } from '../../../../../../common/interfaces/category';
import { CategoriesSelector } from '../../reducers/categories.reducer';
import { CategoriesActionsCreator } from './categories.action';
import { CategoryApiService } from '../../../services/api/category.api.service';

@Injectable()
export class CategoriesController extends AbstractCrudController<IApiGetCategories, IApiSetCategories> {

  constructor(apiService: CategoryApiService, actionFactory: CategoriesActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IApiGetCategories> = CategoriesSelector;

}
