import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {AuthApiService} from './auth.api.service';
import {AbstractCrudApiService} from './abstract-crud.api.service';
import {full} from '../../../../../common/routes';
import {ApiService} from './api.service';

@Injectable()
export class BookingTemplateApiService extends AbstractCrudApiService {

  protected apiUrl: string = full.bookingTemplate;

  constructor(http: HttpClient, authApi: AuthApiService, api: ApiService) {
    super(http, authApi, api);
  }

}
