import {Component, Injectable} from '@angular/core';
import {IDeleteTexts} from '../../../services/modal.service';
import {IColumn} from '../table-page/table/table.component';
import {IStateRole, RolesSelector} from '../../../redux/reducers/roles.reducer';
import {IAppState} from '../../../redux/store';
import {RolesController} from '../../../redux/actions/roles/roles.controller';
import {RolesActionsCreator} from '../../../redux/actions/roles/roles.action';
import {Resolve} from '@angular/router';
import {NgRedux} from '@angular-redux/store';
import {ROLES} from '../../../../../../common/constants';

@Component({
  selector: 'app-roles-right',
  template: `
    <app-table-page
      [name]="name"
      [title]="'Verwalte die Rollen und Rechte Deiner Nutzer.'"
      [subtitle]="'Erstelle oder bearbeite Nutzerrollen nach Bedarf.'"
      [useToggle]="false"
      [useActiveInactive]="false"
      [useTime]="false"
      [useRunTime]="false"
      [addLink]="'/roles-and-rights/roles-and-rights-administration'"
      [route]="route">
      <app-table
        [deleteTexts]="deleteTexts"
        [isToggleSwitched]="false"
        [name]="name"
        [editOrAddLinkGetter]="editOrAddLinkGetter"
        [columns]="columns"
        [getAllSelector]="getAll"
        [getAllIdsSelector]="getAllIds"
        [isAllCheckedSelector]="isAllChecked"
        [getFilterByNameSelector]="getFilterByName"
        [actionFactory]="actionFactory"
        [crudController]="crudController"
      >
      </app-table>
    </app-table-page>`,
})
export class RolesRightComponent {

  public static ROUTE = 'roles-and-rights';
  public route: string;
  public getAll: ($state: IAppState) => IStateRole[];
  public getAllIds: ($state: IAppState) => string[];
  public isAllChecked: ($state: IAppState) => boolean;
  public getFilterByName: (name: string) => ($state: IAppState) => any;
  public name = ROLES;

  constructor(public crudController: RolesController, public actionFactory: RolesActionsCreator) {
    this.getAll = RolesSelector.getAll;
    this.getAllIds = RolesSelector.getAllIds;
    this.isAllChecked = RolesSelector.isAllChecked;
    this.getFilterByName = RolesSelector.getFilterByName;
    this.route = RolesRightComponent.ROUTE;
  }

  public deleteTexts: IDeleteTexts = {
    delete: 'Rolle & Recht löschen',
    reallyDelete: 'Möchtest du die ausgewählte Rolle & Recht wirklich löschen?',
    noKeep: 'Nein, Rolle & Recht behalten.',
    yesDelete: 'Ja, Rolle & Recht löschen.',
  };

  public columns: IColumn[] = [
    {
      name: 'Name',
      filterName: 'name',
      cellClass: 'third',
      dataGetter: (data) => data.name,
    },
    {
      name: 'Beschreibung',
      filterName: 'description',
      cellClass: 'large',
      dataGetter: (data) => data.description,
    },
  ];

  public editOrAddLinkGetter(rowData: any) {
    return `/roles-and-rights/roles-and-rights-administration/${rowData.id}`;
  }

}

@Injectable()
export class RolesDataResolver implements Resolve<any> {
  constructor(private redux: NgRedux<IAppState>, private rolesController: RolesController) {
  }

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.rolesController.updateActive());
  }
}
