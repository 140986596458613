import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'ba-switch-toggle',
  templateUrl: './switch-toggle.component.html',
  styleUrls: ['./switch-toggle.component.scss'],
})
export class BaSwitchToggleComponent implements OnInit {

  @Input() switchToggleId: string;
  @Input() isDisabled = false;
  @Input() isSwitched = false;
  @Output() isSwitchedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit() {
  }

  toggleSwitch() {
    this.isSwitched = !this.isSwitched;
    this.isSwitchedChange.emit(this.isSwitched);
  }
}
