import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {full} from '../../../../../common/routes';
import {AUTH_TOKEN, REFRESH_TOKEN, REMEMBER} from '../../../../../common/constants';

// TODO: test

@Injectable({providedIn: 'root'})
export class AuthApiService {

  private authToken: string | null = null;
  private refreshToken: string | null = null;
  private remember: boolean | null = null;

  constructor(private http: HttpClient) {
  }

  public getAuthorizationHeader = () => `Bearer ${this.getAuthToken()}`;

  public getAuthTokenQueryString = (token?: string) => 'token=' + (token ? token : this.getAuthToken());
  public getAuthToken = () => this.authToken || window.localStorage.getItem(AUTH_TOKEN);
  public getRefreshToken = () => this.refreshToken || window.localStorage.getItem(REFRESH_TOKEN);

  public setAuthToken = (authToken: string): void => {
    if (this.getRemeber()) {
      return window.localStorage.setItem(AUTH_TOKEN, authToken);
    }

    this.authToken = authToken;
  }

  private setRefreshToken = (refreshToken: string): void => {
    if (this.getRemeber()) {
      return window.localStorage.setItem(REFRESH_TOKEN, refreshToken);
    }

    this.refreshToken = refreshToken;
  }

  private setRemember = (remember: boolean): void => {
    if (remember) {
      window.localStorage.setItem(REMEMBER, 'true');
    } else {
      window.localStorage.removeItem(REMEMBER);
    }

    this.remember = remember;
  }

  private getRemeber = () => {
    return this.remember || window.localStorage.getItem(REMEMBER);
  }

  public reset = (): void => {
    this.authToken = null;
    this.refreshToken = null;
    this.remember = null;

    window.localStorage.removeItem(AUTH_TOKEN);
    window.localStorage.removeItem(REFRESH_TOKEN);
    window.localStorage.removeItem(REMEMBER);
  }

  public getAuthHeader() {
    return {'Authorization': this.getAuthorizationHeader()};
  }

  public getDefaultHttpOptions = (headers: any = {'Content-Type': 'application/json'}, params?: any, additionalOptions?: any) => ({
    ...additionalOptions,
    params,
    headers: _.assign({}, headers, this.getAuthHeader(), { 'Timezone-Offset': String(new Date().getTimezoneOffset()) }),
  })

  public isLoggedIn = () => !!this.getAuthToken() || !!this.getRefreshToken();

  private getRefreshTokenFromApi = async () => {
    const result: any = await this.http.get(full.authRefresh, this.getDefaultHttpOptions()).toPromise();
    return result.refreshToken;
  }

  public async refreshAuthToken() {
    try {
      const result: any = await this.http.post(full.authRefresh, {token: this.getRefreshToken()}).toPromise();
      this.setAuthToken(result.authToken);
    } catch (err) {
      this.reset();
    }
  }

  public login = async (email: string, password: string, remember: boolean) => {
    const result: any = await this.http.post(full.authLogin, {email, password}).toPromise();
    this.reset();

    this.setRemember(remember);

    this.setAuthToken(result.authToken);

    const refreshToken = await this.getRefreshTokenFromApi();
    this.setRefreshToken(refreshToken);
  }

  public logout = async () => {
    try {
      await this.http.put(full.authLogout, null, this.getDefaultHttpOptions()).toPromise();
    } catch (err) {
    } finally {
      this.reset();
    }
  }
}
