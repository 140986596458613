import * as fromForms from './forms';

export * from './forms';

export const Components = [
  ...fromForms.Components,
];

export const Directives = [
  ...fromForms.Directives,
];
