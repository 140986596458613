import {Injectable} from '@angular/core';
import {AbstractActionsCreator, IAction} from '../abstractActionsCreator';
import {IApiGetLocation} from '../../../../../../common/interfaces/location';

export const namespace = '@LOCATIONS/';

export type ILocationsAction = IAction<IApiGetLocation>;

@Injectable()
export class LocationsActionsCreator extends AbstractActionsCreator<IApiGetLocation> {
  private static instance: LocationsActionsCreator;
  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!LocationsActionsCreator.instance) {
      LocationsActionsCreator.instance = new LocationsActionsCreator();
    }

    return LocationsActionsCreator.instance;
  }
}
