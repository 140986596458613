import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';

import {AuthApiService} from '../../../../services/api/auth.api.service';

@Component({
  selector: 'app-booking-templates',
  templateUrl: './booking-templates.component.html',
  styleUrls: ['./booking-templates.component.scss'],
})
export class BookingTemplatesComponent implements OnInit, OnDestroy {

  public static ROUTE = 'templates';

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, public authApiService: AuthApiService) {
  }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'overlay');
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'overlay');
  }
}
