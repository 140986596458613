import * as _ from 'lodash';
import {
  AbstractReducer,
  IMetaState,
  IStateDataType,
} from './abstract.reducer';
import {
  SpecialRuntimesActionsCreator,
  ISpecialRuntimesApisAction,
} from '../actions/special-runtimes/special-runtimes.action';
import { IApiGetSpecialRuntime } from '../../../../../common/interfaces/special-runtimes';
import { IStringMap } from '../../../../../common/interfaces/util';

export interface IStateSpecialRuntime extends IApiGetSpecialRuntime, IStateDataType {}
export type ISpecialRuntimesState = IMetaState<IStateSpecialRuntime>;
export interface ISpecialRuntimesFilter extends IStringMap {
  name: string;
  description: string;
  protocol: string;
}

export class SpecialRuntimesReducer extends AbstractReducer<IStateSpecialRuntime, ISpecialRuntimesApisAction> {
  private constructor() {
    super('specialRuntimes', SpecialRuntimesActionsCreator.getInstance());
  }

  private static instance: SpecialRuntimesReducer;

  public DEFAULT_STATE: IMetaState<IStateSpecialRuntime> =  {
    ...AbstractReducer.DEFAULT_STATE,
    filters: {
      name: '',
      start_date: '',
      end_date: '',
      time: '',
      'locations.name': '',
      yearly: '',
    },
  };

  public static getInstance() {
    if (!SpecialRuntimesReducer.instance) {
      SpecialRuntimesReducer.instance = new SpecialRuntimesReducer();
    }

    return SpecialRuntimesReducer.instance;
  }
}

export const SpecialRuntimesSelector = SpecialRuntimesReducer.getInstance().getSelectors();
