import {Component} from '@angular/core';
import {MediaUploadService} from '../../../services/media-upload.service';
import { ALLOWED_TYPES } from '../../../../../../common/constants';

@Component({
  selector: 'ba-media-upload',
  templateUrl: './media-upload.component.html',
  styleUrls: ['./media-upload.component.scss'],
})
export class MediaUploadComponent {

  constructor(private uploadService: MediaUploadService) { }

  get allowedMimeTypes(): string {
    return ALLOWED_TYPES.join(',');
  }

  public filesPicked(event) {
    if (event.target.files && event.target.files.length) {
      const files = event.target.files;

      this.uploadService.uploadFiles(files);
    }
  }
}
