import {
  IBookingLogItem,
  ILocation,
  ILogItem,
  LogsLevel,
} from './interfaces';

export class LogsService {
  private static instance: LogsService;
  private logsToPush: Array<ILogItem> = [];
  private location: Partial<ILocation>;
  private logsLevel: LogsLevel;

  constructor(logsLevel: LogsLevel = LogsLevel.SUCCINCT) {
    this.logsLevel = logsLevel;
  }

  static getInstance(logsLevel?: LogsLevel) {
    if (!LogsService.instance) {
      LogsService.instance = new LogsService(logsLevel);
    }

    return LogsService.instance;
  }

  public setLocation(location: ILocation) {
    const { additional_data, ...basicData } = location;

    this.location = basicData;
  }

  public addLog(bookingLog: IBookingLogItem) {
    const {
      type: log_type,
      timestamp,
      bookingId: booking_id,
      bookingTemplate,
    } = bookingLog;

    const defaultBookingData = {
      name: bookingLog.bookingName,
      duration: bookingLog.bookingDuration,
    };

    const defaultBookingTemplate = {
      name: bookingTemplate.name,
      file: {
        id: bookingTemplate.template.id,
      },
    };

    const bookingTemplateFile = {
      id: bookingTemplate.template.id,
      name: bookingTemplate.template.name,
      encoding: bookingTemplate.template.encoding,
      fieldname: bookingTemplate.template.fieldname,
      scope: (bookingTemplate.template as any).scope, // TODO: fix the all models
      meta: (bookingTemplate.template as any).meta, // TODO: fix the all models
    };

    const dataStructure = {
      [LogsLevel.SUCCINCT]: {
        booking: defaultBookingData,
        template: defaultBookingTemplate,
      },
      [LogsLevel.VERBOSE]: {
        booking: {
          ...defaultBookingData,
          files: bookingLog.bookingFiles,
          inputs: bookingLog.bookingInputs && (bookingLog.bookingInputs as any[]).map(({ name, value }) => ({ name, value })),
          name: bookingLog.bookingName,
        },
        template: {
          ...defaultBookingTemplate,
          duration_calculator: bookingTemplate.duration_calculator,
          inputs: bookingTemplate.inputs && bookingTemplate.inputs
            .map(({ name, type, isRequired, items, mimetypes }: any) => ({ name,  type, isRequired, items, mimetypes })),
          file: bookingTemplateFile,
          thumbnail: bookingTemplate.thumbnail,
        },
      },
    };

    const data = JSON.stringify(dataStructure[this.logsLevel]);

    this.logsToPush.push({
      type: log_type,
      timestamp,
      booking_id,
      data,
      location_id: this.location.id,
      booking_template_id: bookingTemplate.id,
    });
  }

  public getLogsToSave() {
    const currentLogs = this.logsToPush;
    this.logsToPush = this.logsToPush.slice(currentLogs.length);

    return currentLogs;
  }

  public clearLogsList() {
    this.logsToPush = [];
  }
}
