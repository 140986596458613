import { Injectable } from '@angular/core';

import { AbstractCrudController } from '../abstractCrud.controller';
import { ISelectors } from '../../reducers/abstract.reducer';
import { IApiSetExternalResource } from '../../../../../../common/interfaces/external-resource';
import { ExternalResourcesActionsCreator } from './external-resources.action';
import {
  IStateExternalResource,
  ExternalResourcesSelector,
} from '../../reducers/external-resources.reducer';
import { ExternalResourceApiService } from '../../../services/api/external-resource.api.service';

@Injectable()
export class ExternalResourcesController extends AbstractCrudController<IStateExternalResource, IApiSetExternalResource> {

  constructor(protected apiService: ExternalResourceApiService, protected actionFactory: ExternalResourcesActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IStateExternalResource> = ExternalResourcesSelector;
}
