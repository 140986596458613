import {
  Component,
  Injectable,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import {BookingsController} from '../../../redux/actions/bookings/bookings.controller';
import {BookingsActionsCreator} from '../../../redux/actions/bookings/bookings.action';
import {BookingsSelector} from '../../../redux/reducers/bookings.reducer';
import {
  Resolve,
  Router,
  NavigationEnd,
} from '@angular/router';
import {NgRedux, select} from '@angular-redux/store';
import { filter } from 'rxjs/operators';
import {IAppState} from '../../../redux/store';
import {BookingTemplatesController} from '../../../redux/actions/booking-templates/booking-templates.controller';
import {BOOKINGS} from '../../../../../../common/constants';
import {DateAndTimeModalBoxComponent} from '../../ui-elements/modal-box/date-and-time-modal-box/date-and-time-modal-box.component';


@Component({
  selector: 'app-bookings',
  template: `
    <app-table-page
      [name]="name"
      [title]="'Alle Buchungen auf einen Blick'"
      [useToggle]="false"
      [useActiveInactive]="true"
      [activeText]="'Aktuelle Buchungen'"
      [inactiveText]="'Vergangene Buchungen'"
      [useTime]="true"
      [useRunTime]="false"
      [iconName]="'cal-edit-icon.svg'"
      [isTimeEnabled]="isAtLeastOneChecked$ | async"
      [addLink]="'/booking-overview/templates'"
      [route]="route"
      [isActiveSelector]="isActiveSelector"
      [crudController]="crudController"
      [modalBoxComponentName]="DateAndTimeModalBoxComponent"
    >
      <app-bookings-table
        *ngIf="isListRoute"
        [onBookingSelected]="onBookingSelected"
      ></app-bookings-table>
    </app-table-page>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingsComponent implements OnInit {


  constructor(
    public crudController: BookingsController,
    public actionFactory: BookingsActionsCreator,
    private router: Router,
    private cd: ChangeDetectorRef,
  ) {
    this.isActiveSelector = BookingsSelector.isActive;
    this.route = BookingsComponent.ROUTE;
  }

  public static ROUTE = 'booking-overview';

  @select(BookingsSelector.isAtLeastOneChecked) isAtLeastOneChecked$;
  public isRowChecked = false;
  public name = BOOKINGS;
  public route: string;
  public isActiveSelector: any;
  public DateAndTimeModalBoxComponent = DateAndTimeModalBoxComponent;
  public isListRoute = true;

  public ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    ).subscribe((event: NavigationEnd) => {
      this.isListRoute = event.url === `/${this.route}`;
      this.cd.markForCheck();
    });
  }

  public onBookingSelected(isChecked: boolean) {
    this.isRowChecked = isChecked;
  }
}

@Injectable()
export class BookingsDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private bookingsController: BookingsController,
    private bookingTemplatesController: BookingTemplatesController,
  ) {
  }

  async resolve(): Promise<any> {
    await Promise.all([
      this.redux.dispatch(this.bookingsController.updateActive()),
      this.redux.dispatch(this.bookingTemplatesController.updateNames()),
    ]);
  }
}
