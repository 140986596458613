import {Injectable} from '@angular/core';
import {AbstractActionsCreator, IAction, IActionTypes} from '../abstractActionsCreator';
import {IApiSetUser} from '../../../../../../common/interfaces/user';
import {AnyAction} from 'redux';

export const namespace = '@USERS/';

export type IUsersAction = IAction<IApiSetUser>;

const SET_ME = 'SET_ME';

@Injectable()
export class UsersActionsCreator extends AbstractActionsCreator<IApiSetUser> {

  constructor() {
    super(namespace);

    this.SET_ME = namespace + SET_ME;
  }

  private static instance: UsersActionsCreator;
  private readonly SET_ME: string;

  public static getInstance() {
    if (!UsersActionsCreator.instance) {
      UsersActionsCreator.instance = new UsersActionsCreator();
    }

    return UsersActionsCreator.instance;
  }

  public getActionTypes(): IActionTypes {
    return Object.assign(super.getActionTypes(), {SET_ME: this.SET_ME});
  }

  setMe(data: any): AnyAction {
    return {type: this.SET_ME, data};
  }

}
