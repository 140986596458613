import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IMediaUploadListItem } from '../../../../../../common/interfaces/medias';

@Component({
  selector: 'ba-media-upload-element',
  templateUrl: './media-upload-element.component.html',
  styleUrls: ['./media-upload-element.component.scss'],
})
export class MediaUploadElementComponent {

  @Input() item: IMediaUploadListItem;
  @Output() cancelUpload: EventEmitter<string> = new EventEmitter<string>();
  @Output() removeUploaded: EventEmitter<string> = new EventEmitter<string>();

  public cancelRequest(id) {
    this.cancelUpload.emit(id);
  }

  public removeFinished(id) {
    this.removeUploaded.emit(id);
  }

}
