type Moment = any;

export const TIME_FORMAT = 'HH:mm:ss';
export const HOUR_MINUTE = 'HH:mm';

export const EU_FORMAT = 'DD.MM.YYYY';
export const MYSQL_DATE_FORMAT = 'YYYY-MM-DD';
export const MYSQL_DATE_TIME_FORMAT = `${MYSQL_DATE_FORMAT} ${TIME_FORMAT}`;

function fromFormatToDateCreator(from: string) {
  return (moment: Moment, date: string) => moment(date, from).toDate();
}

function fromDateToFormatCreator(to: string) {
  return (moment: Moment, date: Date | string) => moment(date).format(to);
}

function fromToFormatCreator(from: string, to: string, strict = true) {
  return (moment: Moment, date: string) => {
    const formattedDate = moment(date, from, strict).format(to);
    return formattedDate === 'Invalid date' ? null : formattedDate;
  };
}

export function formatSeconds(moment: Moment, seconds: number) {
  return moment.utc(seconds * 1000).format(TIME_FORMAT);
}

export function isAfterToday(moment: Moment, date: string): boolean {
  if (!date) {
    return true;
  }

  const dateObj = moment(date, EU_FORMAT);
  const now = moment();

  return now < dateObj;
}

export const fromDateToEuFormat = fromDateToFormatCreator(EU_FORMAT);
export const fromDateToHoursMinutes = fromDateToFormatCreator(HOUR_MINUTE);

export const formatTime = fromToFormatCreator(TIME_FORMAT, HOUR_MINUTE, false);

export const dateStringToDate = fromFormatToDateCreator(EU_FORMAT);
export const timeStringToDate = fromFormatToDateCreator(TIME_FORMAT);
