import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {IApiFilter, IApiSorting} from '../../../../../common/interfaces/api';
import {AuthApiService} from './auth.api.service';
import {ApiService} from './api.service';
import {NAME} from '../../../../../common/constants';

@Injectable()
export abstract class AbstractCrudApiService {

  protected abstract apiUrl: string;

  protected constructor(protected http: HttpClient, protected authApi: AuthApiService, protected api: ApiService) {
  }

  public getUrl(path: string = '') {
    return this.apiUrl + path;
  }

  protected insertId(route: string, value: string | number) {
    return _.replace(route, ':id', _.toString(value));
  }

  protected async get(path: string = '', options?: any, isQueryAuth?: boolean): Promise<any> {
    return await this.api.get(this.apiUrl + path, options, isQueryAuth);
  }

  protected async post(path: string = '', props: any, options?: any): Promise<any> {
    return await this.api.post(this.apiUrl + path, props, options);
  }

  protected async patch(path: string = '', props: any, options?: any): Promise<any> {
    return await this.api.patch(this.apiUrl + path, props, options);
  }

  protected async delete(path: string = '', options?: any): Promise<any> {
    return await this.api.delete(this.apiUrl + path, options);
  }

  protected postObservable(path: string = '', props: any, options?: any) {
    return this.api.postObservable(this.apiUrl + path, props, options);
  }

  protected patchObservable(path: string = '', props: any, options?: any) {
    return this.api.patchObservable(this.apiUrl + path, props, options);
  }

  protected fetchPatch(path: string = '', options: any): Promise<any> {
    return this.api.fetchPatch(this.apiUrl + path, options);
  }

  protected fetchPost(path: string = '', options: any): Promise<any> {
    return this.api.fetchPost(this.apiUrl + path, options);
  }

  protected propsToFormData(props: any) {
    return _.reduce(props, (acc, value, key) => {
      if (value || value === 0 || value === '') {
        if (_.get(value, 'constructor.name') === 'Blob') {
          acc.append(key, value, _.get(value, NAME));
        } else {
          acc.append(key, value);
        }

      }
      return acc;
    }, new FormData());
  }

  public add(props: any, isFormData = false) {
    return isFormData ? this.fetchPost('', {body: this.propsToFormData(props)}) : this.post('', props);
  }

  public update(id: number | string, props: any, isFormData = false) {
    return isFormData ? this.fetchPatch(`/${id}`, {body: this.propsToFormData(props)}) : this.patch(`/${id}`, props);
  }

  public deleteData(ids: number[]) {
    return this.delete('', this.authApi.getDefaultHttpOptions(undefined, ids));
  }

  public getIdsAndNames(): Promise<Array<any>> {
    return this.get('names');
  }

  public getByFiltersAndSortings(andFilters: IApiFilter[] = [], orFilters: IApiFilter[] = [], sortings: IApiSorting[] = []): Promise<Array<any>> {
    const preparedAndFilters = _.map(andFilters, (filter) => `filter[${filter.column}][${filter.operator}]=${filter.value}`);
    const preparedOrFilters = _.map(orFilters, (filter, index) => {
      const prefixQuery = index > 0 ? `filter[${filter.column}][logical]=or&` : '';
      return `${prefixQuery}filter[${filter.column}][${filter.operator}]=${filter.value}`;
    });

    const sortingQueries = sortings.length > 0
      ? 'sort=' + _.join(_.map(sortings, (sorting) => `${sorting.sorting === 'DESC' ? '-' : ''}${sorting.column}`), ',')
      : null;

    const filterQueries = _.concat(preparedOrFilters, preparedAndFilters);
    const queries = sortingQueries ? _.concat<string>(filterQueries, sortingQueries) : filterQueries;

    const queriesString = _.reduce(queries, (acc, query) => `${acc}&${query}`, '');
    return this.get(`${queriesString ? '?' : ''}${queriesString}`) as Promise<Array<any>>;
  }

  public getByFiltersAndSortingsWithPagination(
    andFilters: IApiFilter[] = [],
    orFilters: IApiFilter[] = [],
    sortings: IApiSorting[] = [],
    paginationPage: number,
  ): Promise<Array<any>> {
    const preparedAndFilters = _.map(andFilters, (filter) => `filter[${filter.column}][${filter.operator}]=${filter.value}`);
    const preparedOrFilters = _.map(orFilters, (filter, index) => {
      const prefixQuery = index > 0 ? `filter[${filter.column}][logical]=or&` : '';
      return `${prefixQuery}filter[${filter.column}][${filter.operator}]=${filter.value}`;
    });

    const sortingQueries = sortings.length > 0
      ? 'sort=' + _.join(_.map(sortings, (sorting) => `${sorting.sorting === 'DESC' ? '-' : ''}${sorting.column}`), ',')
      : null;

    const filterQueries = _.concat(preparedOrFilters, preparedAndFilters);
    const paginationQuery = paginationPage ? [`page=${paginationPage}`] : null;
    let queries = sortingQueries ? _.concat<string>(filterQueries, sortingQueries) : filterQueries;
    if (paginationPage) {
      queries = _.concat<string>(queries, paginationQuery);
    }

    const queriesString = _.reduce(queries, (acc, query) => `${acc}&${query}`, '');
    return this.get(`${queriesString ? '?' : ''}${queriesString}`) as Promise<Array<any>>;
  }
}
