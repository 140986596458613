import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {BookingTemplateApiService} from '../../../services/api/booking-template.api.service';
import {BookingTemplatesSelector, IStateBookingTemplate} from '../../reducers/booking-templates.reducer';
import {AbstractCrudController} from '../abstractCrud.controller';
import {IApiGetBookingTemplate} from '../../../../../../common/interfaces/booking';
import {ISelectors} from '../../reducers/abstract.reducer';
import {BookingTemplatesActionsCreator} from './booking-templates.action';
import {IAppState} from '../../store';
import {IApiFilter} from '../../../../../../common/interfaces/api';
import {LIKE} from '../../../../../../common/constants';

@Injectable()
export class BookingTemplatesController extends AbstractCrudController<IStateBookingTemplate, IApiGetBookingTemplate> {

  constructor(apiService: BookingTemplateApiService, actionFactory: BookingTemplatesActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IStateBookingTemplate> = BookingTemplatesSelector;

  protected getApiAndFilters(isActive: boolean, getState: () => IAppState): IApiFilter[] {
    return [];
  }

  protected getApiOrFilters(isActive: boolean, getState: () => IAppState) {
    const filters = super.getApiAndFilters(isActive, getState);

    const firstFilterValue = _.get(filters, '[0].value');
    if (firstFilterValue) {
      filters.push({column: 'category', operator: LIKE, value: firstFilterValue} as IApiFilter);
    }

    return filters;
  }

}
