import {Injectable} from '@angular/core';
import * as _ from 'lodash';

import {BookingTemplateApiService} from '../../../services/api/booking-template.api.service';
import {AbstractCrudController} from '../abstractCrud.controller';
import {IApiGetBookingTemplate} from '../../../../../../common/interfaces/booking';
import {ISelectors} from '../../reducers/abstract.reducer';
import {TemplatesActionsCreator} from './templates.action';
import {
  IStateTemplate,
  TemplatesSelector,
} from '../../reducers/templates.reducer';
import { IAppState } from '../../store';
import { IApiFilter } from '../../../../../../common/interfaces/api';

@Injectable()
export class TemplatesController extends AbstractCrudController<IStateTemplate, IApiGetBookingTemplate> {

  constructor(apiService: BookingTemplateApiService, actionFactory: TemplatesActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IStateTemplate> = TemplatesSelector;

  protected getApiAndFilters(isActive: boolean, getState: () => IAppState): IApiFilter[] {
    const filters = super.getApiAndFilters(isActive, getState);

    return filters.map((filter) => ({
      ...filter,
      operator: filter.column === 'id' ? 'eq' : filter.operator,
    }));
  }
}
