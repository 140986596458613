import { DOCUMENT } from '@angular/common';
import { ApplicationRef, Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import { FileUploader } from 'ng2-file-upload';
import * as pdfjs from 'pdfjs-dist';
import { nanoid } from 'nanoid';
import { PDFPageProxy } from 'pdfjs-dist';
import { ALLOWED_TYPES, BYTETOMB, CANVAS, IMAGE, NAME, VIDEO } from '../../../../../../common/constants';
import { FileApiService } from '../../../services/api/file.api.service';
import { convertPdfFirstPageToImage } from '../../util/pdf';
import { sanitizeString } from '../../util/helpers';

@Component({
  selector: 'ba-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class BaFileUploadComponent implements OnInit, OnChanges {

  @Input() id: string;
  @Input() allowedTypes: string[];
  @Input() label: string;
  @Input() file: any;
  @Input() data: any;
  @Input() isRequired: string;
  @Input() permissions: object;
  @Output() fileChange: EventEmitter<any> = new EventEmitter();
  @Output() dataChange: EventEmitter<any> = new EventEmitter();
  @Output() displayMediasLibrary: EventEmitter<any> = new EventEmitter();

  public uploader: FileUploader;
  public hasBaseDropZoneOver: boolean;
  public isTypeAllowed: boolean;
  public isMultiPagePdf: boolean;
  public fileDuration: number;
  public fileSize: number;
  public isFileSet: boolean;
  public imgSrc: SafeUrl;
  public sanitizeName = sanitizeString;

  public firstMimeTypeMessageString: string;
  public secondMimeTypeMessageString: string;
  private allowedMimeTypesForMessage: string[];

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    private sanitizer: DomSanitizer,
    private fileApiService: FileApiService,
    private ref: ApplicationRef,
  ) {
    this.uploader = new FileUploader({ url: '' });
    this.hasBaseDropZoneOver = false;
    this.isTypeAllowed = false;
    this.fileDuration = null;
    this.isFileSet = false;
    this.data = {};
  }

  hasFileType(type: string) {
    return !!_.find(this.allowedMimeTypesForMessage, (allowedMimeTypeForMessage: string) => _.includes(allowedMimeTypeForMessage, type));
  }

  ngOnInit() {
    this.id = this.id || `file-upload-${nanoid()}`;
    this.allowedTypes = this.allowedTypes || ALLOWED_TYPES;

    this.allowedMimeTypesForMessage = _.map(this.allowedTypes, (allowedMimeType: string) => {
      const mimeTypeEnding = _.last(_.split(allowedMimeType, '/'));
      return _.head(_.split(mimeTypeEnding, '+'));
    });

    this.firstMimeTypeMessageString = _.join(_.initial(this.allowedMimeTypesForMessage), ', ');
    this.secondMimeTypeMessageString = _.last(this.allowedMimeTypesForMessage);

    if (this.file) {
      this.isTypeAllowed = true;
      this.fileDuration = this.data.value;

      const thumbSrc = this.file.thumbnail_url;
      this.imgSrc = this.fileApiService.getAuthorizedFileUrl(thumbSrc);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const file: SimpleChange = changes.file;
    if (file) {
      const currentFile = file.currentValue;
      const previousFile = file.previousValue;
      if (currentFile && currentFile.id) {
        this.isTypeAllowed = true;
        const thumbFileName = `thumb_${currentFile.key}.png`;
        const imgSrc = this.fileApiService.getAuthorizedFileUrl(thumbFileName);
        this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(imgSrc);
        const fileMeta = currentFile.meta || {};
        this.fileDuration = fileMeta.duration || null;
        if (typeof fileMeta.duration !== 'undefined') {
          this.dataChange.emit(this.fileDuration);
        }
      } else if (!currentFile && previousFile) {
        this.onFileDelete();
      }
    }
  }

  public onFileOverBase(event: any): void {
    this.hasBaseDropZoneOver = !!event;
  }

  public async onFileUpload(event: FileList) {
    this.isFileSet = true;
    let file = event[0];

    this.verifyType(file);
    file = await this.convertPdfFirstPageToImage(file);

    this.file = file;

    if (this.isTypeAllowed) {
      this.processVideoOrImage(file);
    }
  }

  private async convertPdfFirstPageToImage(file: File) {
    if (file.type !== 'application/pdf') {
      return file;
    }

    return await convertPdfFirstPageToImage(file);
  }

  private async createSvgBlobFromPage(page: PDFPageProxy | any, viewport, fileName: string) {
    const operatorList = await page.getOperatorList();

    const svgGraphics = new (<any>pdfjs).SVGGraphics(page.commonObjs, page.objs);
    svgGraphics.embedFonts = true;

    const svg: SVGSVGElement = await svgGraphics.getSVG(operatorList, viewport);

    const svgRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    svgRect.setAttribute('width', '100%');
    svgRect.setAttribute('height', '100%');
    svgRect.setAttribute('fill', 'white');
    svg.insertBefore(svgRect, svg.firstChild);

    const svgImages = svg.getElementsByTagName('svg:image');
    await Promise.all(_.map(svgImages, async (svgImage) => {
      const svgImageBlobUrl = svgImage.getAttribute('xlink:href');
      const svgImageBase64Data = await this.getBase64DataFromBlobUrl(svgImageBlobUrl);

      svgImage.setAttribute('xlink:href', svgImageBase64Data as string);
    }));

    const svgStyles = svg.getElementsByTagName('svg:style');
    await Promise.all(_.map(svgStyles, async (svgStyle) => {
      let textContent = svgStyle.textContent;

      const uuidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';
      const hostUrl = _.replace(window.location.origin, '/', '\\/');
      const regex = new RegExp('blob:' + hostUrl + '/' + uuidRegex, 'g');

      const regexMatches = textContent.match(regex);
      await Promise.all(_.map(regexMatches, async (svgStyleBlobUrl: string) => {
        const svgStyleBase64Data = await this.getBase64DataFromBlobUrl(svgStyleBlobUrl);
        textContent = textContent.replace(svgStyleBlobUrl, svgStyleBase64Data as string);
      }));

      svgStyle.textContent = textContent;
    }));

    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);

    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    _.set(blob, NAME, `${fileName}.svg`);

    return blob as File;
  }

  private async getBase64DataFromBlobUrl(blobUrl) {
    const response = await fetch(blobUrl);
    const responseBlob = await response.blob();

    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(responseBlob);
    });
  }

  private verifyType(file: File) {
    const mimeType = file.type;
    const fileSize = file.size;
    this.isTypeAllowed = _.includes(this.allowedTypes, mimeType);
    this.fileSize = Number(Number(fileSize / BYTETOMB).toFixed(2));
  }

  private processVideoOrImage(file) {
    if (_.includes(file.type, VIDEO)) {
      this.processVideo(file);
    }

    if (_.includes(file.type, IMAGE)) {
      this.processImage(file);
    }

    this.fileChange.emit(this.file);
  }

  private processImage(file) {
    const img = document.createElement('img');
    if (file.id) {
      img.src = this.fileApiService.getAuthorizedFileUrl(file.key);
    } else {
      img.src = URL.createObjectURL(file);
    }
    this.imgSrc = this.sanitizer.bypassSecurityTrustUrl(img.src);

    this.dataChange.emit();
  }

  private processVideo(file) {
    const video = document.createElement(VIDEO);
    video.preload = 'metadata';
    video.width = 960;
    video.height = 540;
    video.src = URL.createObjectURL(file);

    video.onloadedmetadata = () => {
      this.fileDuration = video.duration;
      video.currentTime = video.duration / 2;
      this.dataChange.emit(this.fileDuration);
    };

    video.onseeked = () => this.drawImageFromVideo(video);
  }

  private drawImageFromVideo(video) {
    const canvas = document.createElement(CANVAS);
    canvas.width = video.width;
    canvas.height = video.height;

    const canvasCtx = canvas.getContext('2d');
    canvasCtx.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob((blob) => this.imgSrc = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
  }

  public onFileDelete() {
    this.imgSrc = null;
    this.fileDuration = null;
    this.file = null;

    this.fileChange.emit(this.file);
  }

  public showMedias() {
    this.displayMediasLibrary.emit(true);
  }
}
