import {Component, Directive, Provider, forwardRef, Type} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export abstract class AbstractValueAccessor<T> implements ControlValueAccessor {

    private _value: T;

    private onChangeCallback = new Array<(value: T) => void>();
    private onTouchedCallback = new Array<() => void>();

    get value(): T {
      return this._value;
    }
    set value(v: T) {
      if (v !== this._value) {
        this._value = v;
        this.onChangeCallback.forEach(f => f(v));
        this.onTouchedCallback.forEach(f => f());
      }
    }

    writeValue(value: T) {
      this._value = value;
    }

    registerOnChange(fn: (value: T) => void) {
      this.onChangeCallback.push(fn);
    }

    registerOnTouched(fn: () => void) {
      this.onTouchedCallback.push(fn);
    }
}

export function MakeProvider(type: any) {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => type),
    multi: true,
  };
}
