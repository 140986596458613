import {Component, Input} from '@angular/core';
import {AbstractValueAccessor, MakeProvider} from '../abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'ba-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
  providers: [MakeProvider(BaTextAreaComponent)],
})
export class BaTextAreaComponent extends AbstractValueAccessor<string> {

  @Input() id: string | number | number[];
  @Input() class: string;
  @Input() placeholder: string;
  @Input() maxlenght: number;
  @Input() name: string;
  @Input() isDisabled: boolean;
  @Input() isReadonly: boolean;
  @Input() isRequired: string;
  @Input() isDeletable: boolean;
  @Input() rows: number;
  @Input() label: string;

  constructor() {
    super();
    this.value = undefined;
  }

  clearContent() {
      this.value = undefined;
  }

}
