import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {NgRedux} from '@angular-redux/store';
import * as _ from 'lodash';

import {IAppState} from '../../../redux/store';
import {AbstractActionsCreator} from '../../../redux/actions/abstractActionsCreator';

@Component({
  selector: 'ba-smart-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxSmartComponent<dataType> implements OnChanges {
  isMulti: boolean;
  idSuffix = '';

  @Input() id: string | number | number[];
  @Input() isDisabled: boolean;
  @Input() isChecked: boolean;
  @Input() isSmall: boolean;
  @Input() actionFactory: AbstractActionsCreator<dataType>;
  @Input() name: string;
  @Input() toggled: Function = _.noop;
  @Input() whenCheckboxChanged: Function = _.noop;
  @Input() isRowSelected: Function = _.noop;


  constructor(private ngRedux: NgRedux<IAppState>) {
  }

  toggleCheck() {
    if (_.isArray(this.id)) {
      this.ngRedux.dispatch(this.actionFactory.toggleChecked('all'));
      return _.each(this.id, (currentId) => this.ngRedux.dispatch(this.actionFactory.setChecked(currentId, !this.isChecked)));
    }

    this.isRowSelected(!this.isChecked);
    this.whenCheckboxChanged();
    return this.ngRedux.dispatch(this.actionFactory.toggleChecked(this.id));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.isMulti = _.isArray(this.id);
    this.idSuffix = this.isMulti ? '_multi' : '';
  }
}
