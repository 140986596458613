import {IRuntime} from './interfaces/location';

const defaultRunTimes: IRuntime[] = [
  {startDay: 'mon', endDay: 'mon', startTime: '00:00', endTime: '23:59' },
  {startDay: 'tue', endDay: 'tue', startTime: '00:00', endTime: '23:59' },
  {startDay: 'wed', endDay: 'wed', startTime: '00:00', endTime: '23:59' },
  {startDay: 'thu', endDay: 'thu', startTime: '00:00', endTime: '23:59' },
  {startDay: 'fri', endDay: 'fri', startTime: '00:00', endTime: '23:59' },
  {startDay: 'sat', endDay: 'sat', startTime: '00:00', endTime: '23:59' },
  {startDay: 'sun', endDay: 'sun', startTime: '00:00', endTime: '23:59' },
];

export default defaultRunTimes;
