import { Injectable } from '@angular/core';
import {HttpClient, HttpEventType, HttpResponse} from '@angular/common/http';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { nanoid } from 'nanoid';
import * as _ from 'lodash';

import { AbstractCrudApiService } from './api/abstract-crud.api.service';
import { AuthApiService } from './api/auth.api.service';
import { ApiService } from './api/api.service';
import { full } from '../../../../common/routes';
import { IMediaUploadList } from '../../../../common/interfaces/medias';
import { MediasActionsCreator } from '../redux/actions/medias/medias.action';
import { IAppState } from '../redux/store';
import { convertPdfFirstPageToImage } from '../components/util/pdf';
import { MediasController } from '../redux/actions/medias/medias.controller';
import { sanitizeString } from '../components/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class MediaUploadService extends AbstractCrudApiService {

  protected apiUrl: string = full.medias;

  public filesList: IMediaUploadList = {};
  private cancellable: { [key: string]: Subject<boolean> } = {};

  constructor(
    http: HttpClient,
    authApi: AuthApiService,
    api: ApiService,
    private redux: NgRedux<IAppState>,
    private actionCreator: MediasActionsCreator,
    private mediasCrudController: MediasController,
  ) {
    super(http, authApi, api);

    this.updateReduxList = _.throttle(this.updateReduxList, 5000).bind(this);
  }

  private updateReduxList() {
    this.redux.dispatch(this.actionCreator.changePage(1));
    this.redux.dispatch(this.mediasCrudController.updateCurrent());
  }

  public cancelRequest(uuid: string) {
    this.cancellable[uuid].next();
    this.cancellable[uuid].complete();

    this.removeFinished(uuid);
  }

  public removeFinished(uuid: string) {
    const filesList = { ...this.filesList };
    delete filesList[uuid];
    this.filesList = filesList;
  }

  public removeAl() {
    const requestsToCancel = Object.keys(this.cancellable).reduce((prev, curUuid) => {
      return [
        ...prev,
        curUuid,
      ];
    }, []);
    requestsToCancel.forEach(uuid => {
      this.cancelRequest(uuid);
    });

    this.filesList = {};
  }

  private updateProgress(event: any, data: any) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        const progress = Math.round(100 * event.loaded / event.total);
        this.filesList = {
          ...this.filesList,
          [data.uuid]: {
            id: data.uuid,
            progress,
            name: data.fileName,
            loaded: event.loaded,
            totalSize: event.total,
            finished: false,
          },
        };
        return { status: 'progress', message: progress };
      case HttpEventType.Response:
        return event.body;
      default:
        return `Unhandled event: ${event.type}`;
    }
  }

  private uploadFinished(res: any, uuid: string, updateInStore: boolean = false) {
    if ((Array.isArray(res) && res[0].id) || res.id) {
      const item = res.length ? res[0] : res;

      this.filesList = {
        ...this.filesList,
        [uuid]: {
          ...this.filesList[uuid],
          finished: true,
        },
      };

      if (updateInStore) {
        this.redux.dispatch(this.actionCreator.update(item));
      } else {
        this.updateReduxList();
      }
    }

    return res;
  }

  public async uploadFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      const sanitizedName = sanitizeString(file.name);
      const formData = new FormData();
      if (file.type !== 'application/pdf') {
        formData.append('file', file);
      } else {
        file = await convertPdfFirstPageToImage(file);
        formData.append('file', file, sanitizedName);
      }
      const uuid = nanoid();

      this.cancellable = {
        ...this.cancellable,
        [uuid]: new Subject(),
      };
      super.postObservable('', formData, {
        reportProgress: true,
        observe: 'events',
        headers: {},
      }).pipe(
        map(e => this.updateProgress(e, { uuid, fileName: sanitizedName })),
        takeUntil(this.cancellable[uuid]),
      )
      .subscribe(res => this.uploadFinished(res, uuid));
    }
  }

  public async updateFile(id: number, file: File) {
    const uuid = nanoid();
    const sanitizedName = sanitizeString(file.name);
    const formData = new FormData();
    if (file.type !== 'application/pdf') {
      formData.append('file', file);
    } else {
      file = await convertPdfFirstPageToImage(file);
      formData.append('file', file, sanitizedName);
    }

    this.cancellable = {
      ...this.cancellable,
      [uuid]: new Subject(),
    };
    super.patchObservable(`${id}`, formData, {
      reportProgress: true,
      observe: 'events',
      headers: {},
    }).pipe(
      map(e => this.updateProgress(e, { uuid, fileName: sanitizedName })),
      takeUntil(this.cancellable[uuid]),
    )
    .subscribe(res => this.uploadFinished(res, uuid, true));
  }
}
