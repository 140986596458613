import { Injectable } from '@angular/core';
import {
  AbstractActionsCreator,
  IAction,
} from '../abstractActionsCreator';
import { IApiSetExternalResource } from '../../../../../../common/interfaces/external-resource';

export const namespace = '@EXTERNAL_RESOURCES/';
export type IExternalApisAction = IAction<IApiSetExternalResource>;

@Injectable()
export class ExternalResourcesActionsCreator extends AbstractActionsCreator<IApiSetExternalResource> {
  private static instance: ExternalResourcesActionsCreator;

  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!ExternalResourcesActionsCreator.instance) {
      ExternalResourcesActionsCreator.instance = new ExternalResourcesActionsCreator();
    }

    return ExternalResourcesActionsCreator.instance;
  }
}
