const GLOBAL_STATE = 'GLOBAL_STATE';

window.getGlobalState = () => JSON.parse(window.localStorage.getItem(GLOBAL_STATE) || '{}');
window.addToGlobalState = (newData) => {
  const globalState = window.getGlobalState();
  Object.assign(globalState, newData);

  window.localStorage.setItem(GLOBAL_STATE, JSON.stringify(globalState));
};

window.resetGlobalState = () => window.localStorage.removeItem(GLOBAL_STATE)
