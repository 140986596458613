import * as _ from 'lodash';
import {Component, ElementRef, OnInit} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../../../redux/store';
import {LocationsController} from '../../../redux/actions/locations/locations.controller';
import {BookingTemplatesController} from '../../../redux/actions/booking-templates/booking-templates.controller';
import {AuthApiService} from '../../../services/api/auth.api.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class AppRootComponent implements OnInit {

  private isOpen = false;

  constructor(
    public authApiService: AuthApiService,
    private redux: NgRedux<IAppState>,
    private locationsController: LocationsController,
    private bookingTemplatesController: BookingTemplatesController,
    private modalService: ModalService,
    private elementRef: ElementRef,
  ) {
  }

  ngOnInit() {
    this.modalService.setElementRef(this.elementRef);
    this.modalService.setOnOpen(() => this.isOpen = true);
    this.modalService.setOnClose(() => this.isOpen = false);
  }

  removeModal() {
    this.modalService.closeByOverlay();
  }

  isFullPreview() {
    return _.includes(window.location.href, '/full-preview');
  }
}
