import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,

} from '@angular/forms';

import { nanoid } from 'nanoid';

import { coerceBooleanProperty } from '../utils';

export class CheckboxChange {
  source: CheckboxComponent;
  checked: boolean;
}

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true,
  }],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() inputId = `app-checkbox-id-${nanoid()}`;
  @Input() name: string;
  @Input() showLabel = true;
  @Input() isSmall = false;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) { this._required = coerceBooleanProperty(value); }
  private _required: boolean;

  @Input()
  get checked(): boolean { return this._checked; }
  set checked(value: boolean) {
    if (value !== this.checked) {
      this._checked = value;
      this._changeDetector.markForCheck();
    }
  }
  private _checked = false;

  @Input()
  get disabled() { return this._disabled; }
  set disabled(value: any) {
    const newValue = coerceBooleanProperty(value);

    if (newValue !== this.disabled) {
      this._disabled = newValue;
      this._changeDetector.markForCheck();
    }
  }
  private _disabled = false;


  @Output() readonly change = new EventEmitter<CheckboxChange>();

  @HostBinding('attr.role') role = 'button';
  @HostBinding('attr.class') className = 'app-checkbox';
  @HostBinding('class.app-checkbox--disabled') get isDisabled() { return this.disabled; }
  @HostBinding('class.app-checkbox--required') get isRequired() { return this.required; }
  @HostBinding('class.app-checkbox--checked') get isChecked() { return this.checked; }
  @HostBinding('class.app-checkbox--small') get small() { return this.isSmall; }

  public _onTouched: () => any = () => {};
  private _controlValueAccessorChangeFn: (value: any) => void = () => {};

  constructor(
    private _changeDetector: ChangeDetectorRef,
  ) {}

  public writeValue(value: any) {
    this.checked = !!value;
  }

  public registerOnChange(fn: (value: any) => void) {
    this._controlValueAccessorChangeFn = fn;
  }

  public registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  private _emitChangeEvent() {
    const event = new CheckboxChange();
    event.source = this;
    event.checked = this.checked;

    this._controlValueAccessorChangeFn(this.checked);
    this.change.emit(event);
  }

  private toggle(): void {
    this.checked = !this.checked;
  }

  public onInputClick(event: Event) {
    event.stopPropagation();

    if (!this.disabled) {
      this.toggle();
      this._emitChangeEvent();
    }
  }

  public onInputChange(event: Event) {
    event.stopPropagation();
  }
}
