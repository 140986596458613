export const orderWeekDays = (days: Array<string>): Array<string> => {
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

  return weekDays
    .map(weekDay => days.find(day => weekDay === day))
    .filter(Boolean);
};

export const getWeekDaysDe = (days: Array<string>): Array<string> => {
  return days.map((day) => {
    switch (day) {
      case 'monday':
        return 'Mo';
      case 'tuesday':
        return 'Di';
      case 'wednesday':
        return 'Mi';
      case 'thursday':
        return 'Do';
      case 'friday':
        return 'Fr';
      case 'saturday':
        return 'Sa';
      case 'sunday':
        return 'So';
    }
  });
};

export const sanitizeString = (value: string) => value
  .normalize('NFD') // separate the accents from the letters and convert into Unicode format
  .replace(/[\u0300-\u036f]/g, '') // Remove accents
  .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
  .replace(/\-\-+/g, '-')	// Replaces multiple hyphens by one hyphen
  .replace(/(^-+|-+$)/, ''); // Remove extra hyphens from beginning or end of the string
