import {Injectable} from '@angular/core';
import {AbstractActionsCreator, IAction} from '../abstractActionsCreator';
import {IApiGetLocationGroup} from '../../../../../../common/interfaces/locationGroup';

export const namespace = '@LOCATION-GROUPS/';

export interface ILocationGroupsAction extends IApiGetLocationGroup {
  isDeleted: boolean;
  isDone: boolean;
}

@Injectable()
export class LocationGroupsActionsCreator extends AbstractActionsCreator<IApiGetLocationGroup> {
  private static instance: LocationGroupsActionsCreator;
  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!LocationGroupsActionsCreator.instance) {
      LocationGroupsActionsCreator.instance = new LocationGroupsActionsCreator();
    }

    return LocationGroupsActionsCreator.instance;
  }
}
