import * as _ from 'lodash';
import {AbstractReducer, IMetaState, ISelectors, IStateDataType} from './abstract.reducer';
import {IUsersAction, UsersActionsCreator} from '../actions/users/users.action';
import {IApiGetUser} from '../../../../../common/interfaces/user';
import {IStringMap} from '../../../../../common/interfaces/util';
import {IAction} from '../actions/abstractActionsCreator';
import {IAppState} from '../store';

export type IUsersState = IMetaState<IStateUser>;

export interface IStateUser extends IApiGetUser, IStateDataType {
}

export interface IUsersFilter extends IStringMap {
  name: string;
  email: string;
  role: string;
}

export class UsersReducer extends AbstractReducer<IStateUser, IUsersAction> {
  private constructor() {
    super('users', UsersActionsCreator.getInstance());
  }

  private static instance: UsersReducer;

  public DEFAULT_STATE: IMetaState<IStateUser> = _.assign({}, AbstractReducer.DEFAULT_STATE, {
    filters: {name: '', email: '', role: ''} as IUsersFilter,
    sortings: {name: 'ASC'},
    me: null,
  });

  public static getInstance() {
    if (!UsersReducer.instance) {
      UsersReducer.instance = new UsersReducer();
    }

    return UsersReducer.instance;
  }

  reduce(lastState: IMetaState<IStateUser> = this.DEFAULT_STATE, action: IAction<IUsersAction>): IMetaState<IStateUser> {
    switch (action.type) {
      case this.actionTypes.SET_ME: {
        return Object.assign({}, lastState, {me: action.data});
      }
    }

    return super.reduce(lastState, action);
  }

  public getSelectors(): ISelectors<IStateUser> {
    return Object.assign(super.getSelectors(), {getMe: ($state: IAppState): any => {
        return $state[this.stateName].me;
      }});
  }
}

export const UsersSelector = UsersReducer.getInstance().getSelectors();
