import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import {
  Components,
  Directives,
} from './components';

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...MODULES,
  ],
  declarations: [
    ...Components,
    ...Directives,
  ],
  exports: [
    ...MODULES,
    ...Components,
    ...Directives,
  ],
})
export class SharedModule {}
