import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

import {IStateBooking} from '../../../../redux/reducers/bookings.reducer';
import {FileApiService} from '../../../../services/api/file.api.service';
import {AuthApiService} from '../../../../services/api/auth.api.service';
import BookingRenderer from '../../../../renderer/booking-renderer';
import { KeyboardRenderer } from '../../../../renderer/keyboard-renderer';
import {OneWeekApiService} from '../../../../services/api/one-week-api.service';
import {ApiService} from '../../../../services/api/api.service';
import {full} from '../../../../../../../common/routes';

@Component({
  selector: 'app-full-preview',
  templateUrl: './full-preview.component.html',
  styleUrls: ['./full-preview.component.scss'],
})
export class FullPreviewComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private fileApiService: FileApiService,
    private oneWeekApiService: OneWeekApiService,
    private authApiService: AuthApiService,
    private bookingRenderer: BookingRenderer,
    private keyboardRenderer: KeyboardRenderer,
    private apiService: ApiService,
  ) {
  }

  public static ROUTE = 'full-preview';
  private bookingsPromise: Promise<any>;
  private token: string;
  private bookings: IStateBooking[];

  @ViewChild('contentContainer', {read: ViewContainerRef}) contentContainer: ViewContainerRef;
  @ViewChild('scaleableContent') scaleableContent: any;

  private getBookingTemplateSource(moduleName: string) {
    return this.fileApiService.getFile(moduleName, 'text');
  }

  async ngAfterViewInit() {
    // @ts-ignore
    window.resetGlobalState();

    this.bookings = [await this.bookingsPromise];

    this.authApiService.setAuthToken(this.token);

    this.bookingRenderer.setGetAuthTokenCallback(() => this.token);
    this.bookingRenderer.setBookingsAlwaysVisible(true);

    // Looking for locations with additional_data for specific booking templates like weather-simple that is using additional data
    const bookingWithAddidtionalData = this.bookings.find(({ locations }) => !!locations.find(item => !!item.additional_data));
    const additional_data = bookingWithAddidtionalData && bookingWithAddidtionalData.locations[0].additional_data;

    const location = {width: 1920, height: 1080, additional_data} as any;
    this.scaleableContent.nativeElement.style.width = `${location.width}px`;
    this.scaleableContent.nativeElement.style.height = `${location.height}px`;

    console.log(this.bookings);
    console.log(this.token);

    const getFileCallback = async (key: string) => window.URL.createObjectURL(await this.fileApiService.getFile(key));
    await this.bookingRenderer.init(this.contentContainer, this.bookings, location, this.getBookingTemplateSource.bind(this), getFileCallback);
    this.bookingRenderer.stop();
    this.bookingRenderer.pauseOrPlay();

    await this.apiService.get(full.health);
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.token;
    this.bookingsPromise = this.oneWeekApiService.getObject(this.route.snapshot.queryParams.key, this.token);
  }
}

