import {
  ChangeDetectionStrategy,
  Component,
  Injectable,
} from '@angular/core';
import { Resolve } from '@angular/router';

import {
  Observable,
  Subject,
} from 'rxjs';
import {
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import {
  NgRedux,
  select,
} from '@angular-redux/store';
import { ToastrService } from 'ngx-toastr';

import { IAppState } from '../../../redux/store';
import { CategoriesSelector } from '../../../redux/reducers/categories.reducer';
import { CategoriesActionsCreator } from '../../../redux/actions/categories/categories.action';
import { CategoriesController } from '../../../redux/actions/categories/categories.controller';
import { CategoryApiService } from '../../../services/api/category.api.service';

import {
  CategoryId,
  CategoryInput,
  IApiGetCategories,
  IApiSetCategories,
} from '../../../../../../common/interfaces/category';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesComponent {
  public static ROUTE = 'categories';
  public formSubmitted = false;
  private formValid: string;
  private model: IApiSetCategories = {
    data: [],
    toDelete: [],
  };
  private formUpdate$ = new Subject<IApiSetCategories>();
  @select(CategoriesSelector.getAll) readonly categories$: Observable<IApiGetCategories[]>;

  constructor(
    private apiService: CategoryApiService,
    private categoriesActionsCreator: CategoriesActionsCreator,
    private redux: NgRedux<IAppState>,
    private toastrService: ToastrService,
  ) {
    this.formUpdate$.pipe(
      distinctUntilChanged(),
      tap(() => this.onSubmit()),
    ).subscribe();
  }

  public onModelUpdate(payload: Array<CategoryInput>) {
    this.model = {
      ...this.model,
      data: payload,
    };

    this.formUpdate$.next(this.model);
  }

  public onControlDelete(id: CategoryId): void {
    this.model = {
      ...this.model,
      toDelete: [
        ...this.model.toDelete,
        id,
      ],
    };

    this.formUpdate$.next(this.model);
  }

  public get hasChanged() {
    return this.findChanges(this.model.data) || this.model.toDelete.length;
  }

  private findChanges(data: Array<CategoryInput>) {
    return data.some(input => {
      return input.changed || this.findChanges(input.children);
    });
  }

  private responseDehydration(categories: Array<IApiGetCategories & { isChecked?: boolean; index?: number; }>) {
    return categories.reduce((acc, { isChecked, bookings, parent, index, ...category }) => {
      return [
        ...acc,
        {
          ...category,
          changed: false,
          children: this.responseDehydration(category.children),
        },
      ];
    }, []);
  }

  public async onSubmit() {
    this.formSubmitted = true;
    if (this.formValid === 'INVALID') {
      this.toastrService.error('', 'The form is invalid.');
      return;
    }
    let response = null;
    try {
      response = await this.apiService.add(this.model);
      this.toastrService.success('', 'Änderungen gespeichert');
      this.model = {
        data: this.responseDehydration(response),
        toDelete: [],
      };
      this.redux.dispatch(this.categoriesActionsCreator.set(response));
    } catch (error) {
      this.toastrService.error('', 'Etwas ist schief gelaufen');
      console.error('error when saving/updating', error);
    }
  }
}

@Injectable()
export class CategoriesDataResolver implements Resolve<any> {
  constructor(
    private redux: NgRedux<IAppState>,
    private categoriesController: CategoriesController,
  ) {}

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.categoriesController.updateActive());
  }
}
