import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import {
  NgRedux,
  select,
} from '@angular-redux/store';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import {
  flatMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

import { IAppState } from '../../../../redux/store';
import {
  IStateSpecialRuntime,
  SpecialRuntimesSelector,
} from '../../../../redux/reducers/special-runtimes.reducer';
import { LocationsSelector } from '../../../../redux/reducers/locations.reducer';
import { LocationGroupsSelector } from '../../../../redux/reducers/location-groups.reducer';

import { SpecialRuntimesController } from '../../../../redux/actions/special-runtimes/special-runtimes.controller';
import { LocationsActionsCreator } from '../../../../redux/actions/locations/locations.action';

import { DuplicateTrackingService } from '../../../../services/duplicate-tracking-service';
import { ModalService } from '../../../../services/modal.service';
import { SpecialRuntimesApiService } from '../../../../services/api/special-runtimes.api.service';

import { IApiGetLocationGroup } from '../../../../../../../common/interfaces/locationGroup';
import { IApiSetSpecialRuntime } from '../../../../../../../common/interfaces/special-runtimes';
import { LocationId } from '../../../../../../../common/interfaces/location';
import { Runtimes } from '../../../../components/util/runtimes';

import { AdministrationPageComponent } from '../../administration-page/administration-page.component';
import { SpecialRuntimesComponent } from '../special-runtimes.component';

type Model = IApiSetSpecialRuntime;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-special-runtimes-administration',
  templateUrl: './special-runtimes-administration.component.html',
  styleUrls: ['./special-runtimes-administration.component.scss'],
})
export class SpecialRuntimesAdministrationComponent extends AdministrationPageComponent implements OnInit, OnDestroy, AfterViewChecked {
  public static ROUTE = 'special-runtime-administration';
  private ngDestroy$ = new Subject<void>();
  private viewChecked$ = new Subject<void>();
  private resourcesInitialized$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) protected document: Document,
    protected renderer: Renderer2,
    protected redux: NgRedux<IAppState>,
    private locationsActionsCreator: LocationsActionsCreator,
    private route: ActivatedRoute,
    protected router: Router,
    protected controller: SpecialRuntimesController,
    protected apiService: SpecialRuntimesApiService,
    protected toastrService: ToastrService,
    protected modalService: ModalService,
    protected duplicateTrackingService: DuplicateTrackingService,
    private runtimesUtil: Runtimes,
  ) {
    super();
  }

  protected successMessage = 'Die Sonderlaufzeiten wurden erfolgreich gespeichert.';
  protected errorMessage = 'Speichern fehlgeschlagen, bitte versuche es erneut.';
  protected onCloseRoute = SpecialRuntimesComponent.ROUTE;

  @select(LocationsSelector.getCheckedIds) public selectedLocations$: Observable<Array<LocationId>>;
  @select(LocationGroupsSelector.getAll) locationGroupList$: Observable<Array<IApiGetLocationGroup>>;

  // @ts-ignore
  public model: Model = {
    runtimes: [{
      startDay: null,
      startTime: null,
      endDay: null,
      endTime: null,
    }],
    name: '',
    yearly: false,
    locations: [],
  };

  public formModel: Model = { ...this.model };

  private rawModel: any = { ...this.model };

  protected validators = [
    {
      validate: () => LocationsSelector.getCheckedIds(this.redux.getState()).length > 0,
      errorMessage: 'Bitte wählen Sie mindestens 1 Standort aus.',
    },
  ];

  protected isModelValid() {
    const hasName = !!this.formModel.name;
    const hasLocations = !!this.formModel.locations && this.formModel.locations.length > 0;
    const hasRuntimesValid = this.formModel.runtimes.every(
      ({ startDay, startTime, endDay, endTime }) => !!(startDay && startTime && endDay && endTime));

    return hasName && hasLocations && hasRuntimesValid;
  }

  private dateToString(date: Date): string {
    if (date instanceof Date) {
      date.setHours(date.getTimezoneOffset() / -60);
      return date.toISOString().slice(0, 10);
    }

    return date;
  }

  protected async prepareModel() {
    this.model = { ...this.formModel };
    this.model.runtimes = this.runtimesUtil.convertRunTimes(this.model.runtimes);
    this.model.runtimes = this.model.runtimes.map((item: any) => ({
      ...item,
      startDay: this.dateToString(item.startDay),
      endDay: this.dateToString(item.endDay),
    }));

    return Promise.resolve();
  }

  public ngOnInit() {
    super.ngOnInit();

    this.isInvalidForm = false;

    this.viewChecked$.pipe(
      take(1),
      tap(() => this.checkSelectedLocations()),
      takeUntil(this.ngDestroy$),
    ).subscribe();
  }

  public ngAfterViewChecked() {
    this.viewChecked$.next();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    this.redux.dispatch(this.locationsActionsCreator.resetChecked());
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public onRuntimeAdd(): void {
    this.formModel.runtimes.push({
      startDay: null,
      startTime: null,
      endDay: null,
      endTime: null,
    });
  }

  public onRuntimeRemove(index: number): void {
    super.onFormChange();
    this.formModel.runtimes.splice(index, 1);
  }

  private checkSelectedLocations() {
    setTimeout(() => {
      this.rawModel.locations.forEach((locationId: LocationId) => {
        this.redux.dispatch(this.locationsActionsCreator.setChecked(locationId, true));
      });
    });
  }

  private onLocationsChange(locationsIds: Array<LocationId>) {
    this.formModel.locations = [ ...locationsIds ];
  }

  private initResource(resource?: IStateSpecialRuntime) {
    if (resource) {
      const {
        id,
        index,
        isChecked,
        locations,
        disabled,
        ...resourceData } = resource;

      this.model = {
        ...resourceData,
        locations: locations.map(location => location.id),
      };

      this.formModel = {
        ...resourceData,
        locations: locations.map(location => location.id),
        runtimes: resourceData.runtimes.map(runtime => this.runtimesUtil.fixRunTime(runtime as any)),
      };

      this.rawModel = {
        ...resourceData,
        locations: locations.map(location => location.id),
      };

      this.resourcesInitialized$.next();
      this.resourcesInitialized$.complete();

      this.model.locations.forEach((locationId: LocationId) => {
        this.redux.dispatch(this.locationsActionsCreator.setChecked(locationId, true));
      });
    }

    this.selectedLocations$.pipe(
      tap(this.onLocationsChange.bind(this)),
      takeUntil(this.ngDestroy$),
    ).subscribe();
  }

  protected getParamsSub() {
    return this.route.params.pipe(
      flatMap(({ id }) => {
        this.id = id;
        return this.redux.select(SpecialRuntimesSelector.getById(id));
      }),
      tap(this.initResource.bind(this)),
    ).subscribe();
  }
}
