import { ApplicationRef, Injectable, ViewContainerRef } from '@angular/core';

import * as _ from 'lodash';

import ComponentManager from './component-manager';
import { LogsService } from './logs-service';
import { UserTrackerService } from './services/user-tracker.service';
import { IBookingLogItem, PlaybackTrackType } from './interfaces';

/**
 * This class is responsible for interacting with angular and the dom
 */
@Injectable()
export default class ContentContainerManager {

  private contentContainer: ViewContainerRef;

  constructor(
    private appRef: ApplicationRef,
    private componentManager: ComponentManager,
    private userTrackerService: UserTrackerService,
  ) {}

  public destroy() {
    if (!this.contentContainer) {
      return;
    }

    this.contentContainer.clear();
  }

  public setContentContainer(contentContainer: ViewContainerRef) {
    this.contentContainer = contentContainer;
  }

  public removeComponent() {
    console.debug(`content-container-manager - removing component ${this.componentManager.getBookingId()}`);

    const allVideos = document.querySelectorAll('video');
    const globalState: any = window.getGlobalState();
    const resetCategory = globalState.reset ? globalState.reset.category : true;
    if (globalState.booking && this.componentManager.getBookingId() === globalState.booking.id) {
      window.addToGlobalState({
        booking: null,
      });
    }
    if (globalState.category && resetCategory && this.componentManager.getBookingCategoryId() === globalState.category.id) {
      window.addToGlobalState({
        category: null,
      });
    }

    this.contentContainer.detach();
    // use tick here to tell angular that things changed
    this.appRef.tick();

    // contentContainer.detach() does not stop videos, but pauses them, so we have to "stop" them ourselves
    _.each(allVideos, (video) => {
      video.pause();
      video.currentTime = 0;
    });

    LogsService.getInstance().addLog(this.getTrackObject(PlaybackTrackType.PLAYBACK_STOP));
  }

  public insertComponent() {
    console.debug(`content-container-manager - inserting component ${this.componentManager.getBookingId()}`);

    this.contentContainer.insert(this.componentManager.getHostView());

    // use tick here to tell angular that things changed
    this.appRef.tick();

    const trackObject = this.getTrackObject(PlaybackTrackType.PLAYBACK_START);
    LogsService.getInstance().addLog(trackObject);
    this.userTrackerService.updateBooking({
      id: trackObject.bookingId,
      name: trackObject.bookingName,
      templateId: trackObject.bookingTemplate.id,
    });
  }

  private getTrackObject(type: PlaybackTrackType): IBookingLogItem {
    return {
      type,
      timestamp: new Date().getTime(),
      bookingId: this.componentManager.getBookingId(),
      bookingDuration: this.componentManager.getBookingDuration(),
      bookingFiles: this.componentManager.getBookingFiles(),
      bookingInputs: this.componentManager.getBookingInputs(),
      bookingName: this.componentManager.getBookingName(),
      bookingTemplate: this.componentManager.getBookingBookingTemplate(),
    };
  }
}
