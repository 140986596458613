import {ICode, IName} from './interfaces/util';

export interface IWeekDayEnumItem extends ICode, IName {
}

const weekDayEnumItems: IWeekDayEnumItem[] = [
  {code: 'mon', name: 'Montag'},
  {code: 'tue', name: 'Dienstag'},
  {code: 'wed', name: 'Mittwoch'},
  {code: 'thu', name: 'Donnerstag'},
  {code: 'fri', name: 'Freitag'},
  {code: 'sat', name: 'Samstag'},
  {code: 'sun', name: 'Sonntag'},
];

export default weekDayEnumItems;
