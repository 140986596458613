import {Component, Injectable} from '@angular/core';
import {IDeleteTexts} from '../../../services/modal.service';
import {IColumn} from '../table-page/table/table.component';
import {UsersController} from '../../../redux/actions/users/users.controller';
import {UsersActionsCreator} from '../../../redux/actions/users/users.action';
import {IStateUser, UsersSelector} from '../../../redux/reducers/users.reducer';
import {IAppState} from '../../../redux/store';
import {Resolve} from '@angular/router';
import {NgRedux} from '@angular-redux/store';
import {BOOKINGS, EMAIL, ROLE, USERS} from '../../../../../../common/constants';

@Component({
  selector: 'app-users',
  template: `
    <app-table-page
      [name]="name"
      [title]="'Benutzer anlegen und verwalten.'"
      [useToggle]="false"
      [useActiveInactive]="false"
      [useTime]="false"
      [useRunTime]="false"
      [addLink]="'/users/user-administration'"
      [route]="route">
      <app-table
        [deleteTexts]="deleteTexts"
        [isToggleSwitched]="false"
        [name]="name"
        [editOrAddLinkGetter]="editOrAddLinkGetter"
        [columns]="columns"
        [getAllSelector]="getAll"
        [getAllIdsSelector]="getAllIds"
        [isAllCheckedSelector]="isAllChecked"
        [getFilterByNameSelector]="getFilterByName"
        [actionFactory]="actionFactory"
        [crudController]="crudController"
      >
      </app-table>
    </app-table-page>`,
})
export class UsersComponent {

  public static ROUTE = USERS;
  public name = USERS;
  public route: string;
  public getAll: ($state: IAppState) => IStateUser[];
  public getAllIds: ($state: IAppState) => string[];
  public isAllChecked: ($state: IAppState) => boolean;
  public getFilterByName: (name: string) => ($state: IAppState) => any;

  constructor(public crudController: UsersController, public actionFactory: UsersActionsCreator) {
    this.route = UsersComponent.ROUTE;

    this.getAll = UsersSelector.getAll;
    this.getAllIds = UsersSelector.getAllIds;
    this.isAllChecked = UsersSelector.isAllChecked;
    this.getFilterByName = UsersSelector.getFilterByName;
  }

  public deleteTexts: IDeleteTexts = {
    delete: 'Benutzer löschen',
    reallyDelete: 'Möchtest du den ausgewählten Benutzer wirklich löschen?',
    noKeep: 'Nein, Benutzer behalten.',
    yesDelete: 'Ja, Benutzer löschen.',
  };

  public columns: IColumn[] = [
    {
      name: 'Name',
      filterName: 'name',
      cellClass: 'large',
      dataGetter: (data) => data.name,
    },

    {
      name: 'Benutzername',
      filterName: EMAIL,
      cellClass: 'large',
      dataGetter: (data) => data.email,
    },
    {
      name: 'Benutzerrolle',
      filterName: ROLE,
      cellClass: 'medium',
      dataGetter: (data) => data.role,
    },
  ];

  public editOrAddLinkGetter(rowData: any) {
    return `/users/user-administration/${rowData.id}`;
  }

}

@Injectable()
export class UsersDataResolver implements Resolve<any> {
  constructor(private redux: NgRedux<IAppState>, private usersController: UsersController) {
  }

  async resolve(): Promise<any> {
    await this.redux.dispatch(this.usersController.updateActive());
  }
}

