import * as _ from 'lodash';
import {
  AbstractReducer,
  IMetaState,
  IStateDataType,
} from './abstract.reducer';
import {
  ExternalResourcesActionsCreator,
  IExternalApisAction,
} from '../actions/external-resources/external-resources.action';
import { IApiGetExternalResource } from '../../../../../common/interfaces/external-resource';
import { IStringMap } from '../../../../../common/interfaces/util';

export interface IStateExternalResource extends IApiGetExternalResource, IStateDataType {}
export type IExternalResourcesState = IMetaState<IStateExternalResource>;
export interface IExternalResourcesFilter extends IStringMap {
  name: string;
  description: string;
  protocol: string;
  start_time: string;
  interval: string;
}

export class ExternalResourcesReducer extends AbstractReducer<IStateExternalResource, IExternalApisAction> {
  private constructor() {
    super('externalResources', ExternalResourcesActionsCreator.getInstance());
  }

  private static instance: ExternalResourcesReducer;

  public DEFAULT_STATE: IMetaState<IStateExternalResource> =  _.assign({}, AbstractReducer.DEFAULT_STATE, {
    ...AbstractReducer.DEFAULT_STATE,
    filters: <IExternalResourcesFilter>{
      name: '',
      description: '',
      protocol: '',
      start_time: '',
      interval: '',
    },
    sortings: { name: 'ASC' },
  });

  public static getInstance() {
    if (!ExternalResourcesReducer.instance) {
      ExternalResourcesReducer.instance = new ExternalResourcesReducer();
    }

    return ExternalResourcesReducer.instance;
  }
}

export const ExternalResourcesSelector = ExternalResourcesReducer.getInstance().getSelectors();
