import {Component, OnInit} from '@angular/core';
import {AuthApiService} from '../../../services/api/auth.api.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public isInvalidForm = false;

  loginForm: FormGroup;
  password: FormControl;
  email: FormControl;

  remember = true;
  loginFailed = false;

  constructor(private apiAuth: AuthApiService, private router: Router) {
  }

  ngOnInit(): void {
    this.email = new FormControl('', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]);
    this.password = new FormControl('', [Validators.required]);

    this.loginForm = new FormGroup({email: this.email, password: this.password});
  }

  async onSubmit() {
    this.loginFailed = false;

    if (!this.loginForm.valid) {
      this.isInvalidForm = true;
      return;
    }

    try {
      await this.apiAuth.login(this.loginForm.value.email, this.loginForm.value.password, this.remember);
      await this.router.navigate(['']);
    } catch (err) {
      this.loginFailed = true;
    }
  }

}
