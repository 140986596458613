import {AbstractReducer, IMetaState} from './abstract.reducer';
import {IBookingsAction} from '../actions/bookings/bookings.action';
import {BookingsProcessingActionsCreator} from '../actions/bookings/bookings-processing.action';
import {IStateBooking} from './bookings.reducer';

export class BookingsProcessingReducer extends AbstractReducer<IStateBooking, IBookingsAction> {
  protected constructor() {
    super('bookingsProcessing', BookingsProcessingActionsCreator.getInstance());
  }

  private static instance: BookingsProcessingReducer;

  public DEFAULT_STATE: IMetaState<IStateBooking> = AbstractReducer.DEFAULT_STATE;

  public static getInstance() {
    if (!BookingsProcessingReducer.instance) {
      BookingsProcessingReducer.instance = new BookingsProcessingReducer();
    }

    return BookingsProcessingReducer.instance;
  }
}

export const BookingsProcessingSelector = BookingsProcessingReducer.getInstance().getSelectors();
