import {Injectable} from '@angular/core';

import {AbstractCrudController} from '../abstractCrud.controller';
import {ISelectors} from '../../reducers/abstract.reducer';
import {IApiSetUser} from '../../../../../../common/interfaces/user';
import {UsersActionsCreator} from './users.action';
import {IStateUser, UsersSelector} from '../../reducers/users.reducer';
import {UserApiService} from '../../../services/api/user.api.service';
import {AnyAction, Dispatch} from 'redux';

@Injectable()
export class UsersController extends AbstractCrudController<IStateUser, IApiSetUser> {

  constructor(protected apiService: UserApiService, protected actionFactory: UsersActionsCreator) {
    super(apiService, actionFactory);
  }

  protected selectors: ISelectors<IStateUser> = UsersSelector;

  public updateMe(): any {
    return async (dispatch: Dispatch): Promise<any> => {
      const data = await this.apiService.getMe();
      const action: AnyAction = this.actionFactory.setMe(data);
      dispatch(action);
    };
  }

}
