/**
 * This class is a simple timer implementation needed to track weather something is paused or not
 */
export default class Timer {
  constructor(private onDone: Function, ms: number) {
    this.remainingTime = ms;
  }

  private isDone: boolean;
  private timeoutId: any;
  private remainingTime: number;
  private startTime: number;

  cancel() {
    this.isDone = true;
    clearTimeout(this.timeoutId);
  }

  pause() {
    if (this.isDone) {
      return;
    }

    clearTimeout(this.timeoutId);
    this.remainingTime -= new Date().getTime() - this.startTime;
  }

  start() {
    if (this.isDone) {
      return;
    }

    this.startTime = new Date().getTime();
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      this.isDone = true;
      this.onDone();
    }, this.remainingTime);
  }

}
