import { Component, Input } from '@angular/core';
import { AbstractValueAccessor, MakeProvider } from '../abstract-value-accessor/abstract-value-accessor';

@Component({
  selector: 'ba-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [MakeProvider(BaMultiselectComponent)],
})
export class BaMultiselectComponent extends AbstractValueAccessor<string> {

  @Input() id: string | number | number[];
  @Input() class: string;
  @Input() listItems: Array<any>;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;
  @Input() isRequired: string;
  @Input() label: string;

}
