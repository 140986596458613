import { Component, Input, OnInit } from '@angular/core';
import { NgRedux, Selector } from '@angular-redux/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

import { IAppState } from '../../../../redux/store';
import { AbstractCrudController } from '../../../../redux/actions/abstractCrud.controller';
import { AbstractActionsCreator } from '../../../../redux/actions/abstractActionsCreator';
import { IDeleteTexts } from '../../../../services/modal.service';
import { TrackByService } from '../../../../services/track-by.service';

export type SingleDataGetter = (data: any) => string;
export type DataGetter = (data: any) => string | string[] | object;
export type SearchOptionsGetter = () => { id: string | number, name: string }[];

export interface IColumn {
  name: string;
  filterName: string;
  cellClass: string;
  placeholder?: string;
  getSearchOptions?: SearchOptionsGetter;
  dataGetter: DataGetter;
  multiText?: string;
  dataHeaders?: string[];
  singleDataGetters?: SingleDataGetter[];
  defaultData?: string;
  type?: string;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<dataType> implements OnInit {

  public datas$: Observable<any[]>;
  public ids$: Observable<string[]>;
  public isAllChecked$: Observable<boolean>;

  @Input() useEditButtons = true;
  @Input() editButtonReplaceComponent = null;
  @Input() editButtonReplaceComponentInputs = {};

  @Input() rowsCheckable = true;
  @Input() deleteTexts: IDeleteTexts = {
    delete: '',
    reallyDelete: '',
    noKeep: '',
    yesDelete: '',
  };
  @Input() isToggleSwitched = false;
  @Input() isEditable = true;
  @Input() maxHeight = false;
  @Input() name: string;
  @Input() editOrAddLinkGetter: (rowData: any) => string;
  @Input() duplicateLinkGetter: (rowData: any) => string;
  @Input() useDuplicate: boolean;
  @Input() columns: IColumn[] = [];
  @Input() getAllSelector: Selector<IAppState, any>;
  @Input() getAllIdsSelector: Selector<IAppState, any>;
  @Input() isAllCheckedSelector: Selector<IAppState, any>;
  @Input() actionFactory: AbstractActionsCreator<dataType>;
  @Input() crudController: AbstractCrudController;
  @Input() getFilterByNameSelector: Selector<IAppState, any>;
  @Input() rowEndInformation: SingleDataGetter;
  @Input() onRowSelected: Function = _.noop;
  @Input() whenCheckboxChanged: Function = _.noop;


  constructor(private redux: NgRedux<IAppState>, public trackByService: TrackByService) {
  }

  public toggleSort(column: string) {
    if (this.actionFactory) {
      this.redux.dispatch(this.actionFactory.toggleSort(column));
    }

    if (this.crudController) {
      this.redux.dispatch(this.crudController.updateCurrent());
    }
  }

  public ngOnInit() {
    this.datas$ = this.getAllSelector ? this.redux.select<any[]>(this.getAllSelector) : null;
    this.ids$ = this.getAllIdsSelector ? this.redux.select<string[]>(this.getAllIdsSelector) : null;
    this.isAllChecked$ = this.isAllCheckedSelector ? this.redux.select<boolean>(this.isAllCheckedSelector) : null;
  }

  trackByFn(index, data) {
    if (!data) {
      return null;
    }
    return index;
  }

}
