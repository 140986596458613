import {Injectable} from '@angular/core';
import {AbstractActionsCreator, IAction} from '../abstractActionsCreator';
import {IRole} from '../../../../../../common/interfaces/role';

export const namespace = '@ROLES/';

export type IRolesAction = IAction<IRole>;

@Injectable()
export class RolesActionsCreator extends AbstractActionsCreator<IRole> {
  private static instance: RolesActionsCreator;
  constructor() {
    super(namespace);
  }

  public static getInstance() {
    if (!RolesActionsCreator.instance) {
      RolesActionsCreator.instance = new RolesActionsCreator();
    }

    return RolesActionsCreator.instance;
  }
}
