import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {AuthApiService} from '../services/api/auth.api.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authApiService: AuthApiService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newRequest = request.clone({setHeaders: {Authorization: this.authApiService.getAuthorizationHeader()}});
    return next.handle(newRequest);
  }
}
